import React from 'react';
import { LoggedInUserStatus, useLoggedInUser, useUpdateSessionToken } from './SessionProvider';
import history from '../../history';

export const WithSucceededAuth = ({ view, urlRedirect, userRole: requiredUserRole }) => {
  const loggedInUser = useLoggedInUser();
  const updateSessionToken = useUpdateSessionToken();

  console.log('loggedInUser', JSON.stringify(loggedInUser));

  const isLoggedIn = LoggedInUserStatus.LOGGED_IN === loggedInUser.status;
  const userHaveRequiredRole =
    !requiredUserRole ||
    (loggedInUser.status === LoggedInUserStatus.LOGGED_IN && requiredUserRole == loggedInUser.data.role);

  const sendToLogin = React.useCallback(() => {
    const queryParams = new URLSearchParams(window.location.search);

    if (requiredUserRole) {
      queryParams.append('rol', requiredUserRole);
    }

    queryParams.append('referrer', window.location.pathname);

    history.push(urlRedirect || `/ingreso?${queryParams.toString()}`);
  }, []);

  React.useEffect(() => {
    console.log('Executing auth effect');
    const notLoggedIn = LoggedInUserStatus.NOT_LOGGED_IN === loggedInUser.status;

    if (notLoggedIn) {
      console.log('Sending back to login');
      sendToLogin();
    } else if (!userHaveRequiredRole && LoggedInUserStatus.LOGGED_IN === loggedInUser.status) {
      console.log('Required role not met');
      updateSessionToken && updateSessionToken({ kind: 'DROP' });
    }
  }, [loggedInUser, userHaveRequiredRole]);

  if (isLoggedIn && userHaveRequiredRole) return <>{view}</>;

  return <>Cargando ...</>;
};
