import React, { useEffect, useReducer, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import {
  CustomInput,
  CustomInputTel,
  errorMessage,
  errorMessages,
  FlagSelector,
} from '../../../components/FormComponents';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { DO_USER_EDIT } from '../../../hooks/useUserQuery';
import { GET_USER_DATA } from '../../../hooks/useAuthQuery';
import { userReducerConf } from '../../../reducers/userReducer';
import { mapToUserFront } from '../../../helpers/network-helpers';
import { AptunoButton } from '@aptuno/aptuno-ui';
import { useAnalyticsTracker } from '../../../services/analytics';
import { UserContactInfoCompleted } from '../../../Context/User/events/user-contact-info-completed.event';

export const ProfileForm = ({ responsive, phoneNbr, onProfileUpdated, nameSource }) => {
  const [getAuthenticatedUser, { data: dataAuthUser }] = useLazyQuery(GET_USER_DATA);
  const [doUserEdit, { data: dataUserEdit, loading: loadingUserEdit }] = useMutation(DO_USER_EDIT);
  const { control, handleSubmit, errors } = useForm();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('CO');
  const [phoneNumber, setPhoneNumber] = useState('');
  const eventTracker = useAnalyticsTracker();
  const [userState, userDispatch] = useReducer(userReducerConf, {
    status: '',
    data: null,
  });

  useEffect(() => {
    setPhoneNumber(phoneNbr);
  }, [phoneNbr]);

  const onSubmitForm = (data) => {
    doUserEdit({ variables: { firstName, lastName, email, phoneNumber } }).then((response) => {
      if (response.data) {
        const userDataLocalStorage = JSON.parse(localStorage.getItem('user-data'))?.data || undefined;

        eventTracker
          .trackIdentity({
            userId: userDataLocalStorage?.id,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            role: userDataLocalStorage && userDataLocalStorage.role,
            nameSource: 'LoginForm',
          })
          .then(() => eventTracker.trackEvent(new UserContactInfoCompleted(userDataLocalStorage?.id)));
      }
    });
  };

  useEffect(() => {
    let timeout = null;

    if (dataUserEdit) {
      onProfileUpdated();
      timeout = setTimeout(() => {
        updateNavbar();
      }, 500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [dataUserEdit]);

  const updateNavbar = () => {
    const token = localStorage.getItem('token');
    if (token) getAuthenticatedUser();
  };

  useEffect(() => {
    if (dataAuthUser && dataAuthUser.getAuthenticatedUser) {
      userDispatch({
        type: 'USER_FETCH_DATA_UPDATE',
        payload: {
          data: mapToUserFront(dataAuthUser.getAuthenticatedUser),
          status: 'fetch',
          token: dataAuthUser.getAuthenticatedUser.token,
        },
      });
    }
  }, [dataAuthUser]);

  return (
    <form className="calculatorBucket" onSubmit={handleSubmit(onSubmitForm)}>
      <Row className="pb-2">
        <Col xs="12">
          <p className="firstText text-center f-14 lh-21">
            Necesitamos tu nombre completo y correo electrónico para finalizar la creación de tu cuenta
          </p>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col xs="6" className="pt-1m-auto">
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ onChange }) => (
              <CustomInput
                className={`md-input ml-auto mr-auto font-16 lh-22 ${
                  responsive && responsive.isMobile ? 'size-40' : 'size-64'
                }`}
                value={firstName}
                type="string"
                label="Nombre"
                disabled={loadingUserEdit}
                changeFn={(value) => {
                  setFirstName(value);
                  onChange(value);
                }}
              />
            )}
            rules={{ required: true }}
          />
          {errors.firstName && errors.firstName.type === 'required' && errorMessage(`Nombre ${errorMessages.required}`)}
        </Col>
        <Col xs="6" className="pt-1m-auto">
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ onChange }) => (
              <CustomInput
                className={`md-input ml-auto mr-auto font-16 lh-22 ${
                  responsive && responsive.isMobile ? 'size-40' : 'size-64'
                }`}
                value={lastName}
                type="string"
                label="Apellido"
                disabled={loadingUserEdit}
                changeFn={(value) => {
                  setLastName(value);
                  onChange(value);
                }}
              />
            )}
            rules={{ required: true }}
          />
          {errors.lastName && errors.lastName.type === 'required' && errorMessage(`Apellido ${errorMessages.required}`)}
        </Col>
      </Row>
      <Row className="pb-2">
        <Col xs="12" className="pt-1m-auto">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ onChange }) => (
              <CustomInput
                disabled={loadingUserEdit}
                className={`md-input ml-auto mr-auto font-16 lh-22 ${
                  responsive && responsive.isMobile ? 'size-40' : 'size-64'
                }`}
                value={email}
                type="string"
                label="Email"
                changeFn={(value) => {
                  setEmail(value);
                  onChange(value);
                }}
              />
            )}
            rules={{ required: true, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ }}
          />
          {errors.email && errors.email.type === 'required' && errorMessage(`Email ${errorMessages.required}`)}
          {errors.email && errors.email.type === 'pattern' && errorMessage(`Email ${errorMessages.pattern}`)}
        </Col>
      </Row>
      <Row className="pb-2">
        <Col xs="12" className="phoneForm d-flex jc-space-between">
          <FlagSelector disabled={true} onChange={(c) => setCountry(c)} />
          <Col className="pr-0">
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue={phoneNbr}
              render={({ onChange, defaultValue }) => (
                <CustomInputTel
                  disabled={true}
                  className="w-100"
                  country={country}
                  icon="icon-wa_icon"
                  placeHolder={' Ingresa tu teléfono'}
                  label={'Teléfono'}
                  defaultVal={`${phoneNbr}`}
                  changeFn={(value) => {
                    setPhoneNumber(value);
                    onChange(value);
                  }}
                />
              )}
            />
          </Col>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col>
          <AptunoButton size={'big'} type={'submit'} isLoading={loadingUserEdit}>
            Guardar
          </AptunoButton>
        </Col>
      </Row>
    </form>
  );
};
