export const environmentProd = {
  dev: false,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_APPLICATION_CREDENTIALS,
  segmentAnalyticsApiKey: 'GZTUfKWFDhLvXBwf7x51nvf8YAT78HGa',
  sentryDns: 'https://6b37576a24d5434a83caee1a1e14caea@o491496.ingest.sentry.io/5560479',
  countryCode: 'CO',
  countryName: 'Colombia',
  countryPhoneCode: '+57',
  showStickyBar: true,
  gcpStorage: {
    baseUrl: 'https://storage.googleapis.com/aptuno-assets',
    cdnBaseUrl: 'https://cdn.aptuno.com',
  },
  riskStudyUrl: 'https://qa-risk-study.aptuno.com/graphql',
  defaultSlugLocation: 'bogota-cundinamarca',
  principalCity: 'Bogotá',
  whatsAppNumber: '573112955504',
  urlAptuno: 'aptuno.com',
  tagManagerKey: 'GTM-TK9KSNM',
  defaultUrlLocation: 'bogota-colombia',
  fbPixelKey: '603195810516371',
  mapsDefaultCoors: {
    lat: 4.6548,
    lng: -74.0658,
  },
  estateInfoForm: {
    price: {
      required: true,
      min: 1_000_000,
      max: 100000000,
    },
    adminPrice: {
      required: false,
      min: 10000,
      max: 10000000,
    },
  },
  characteristics: {
    complementary: [
      { name: 'Balcón', slug: 'hasBalcony' },
      { name: 'Depósito', slug: 'deposit' },
      { name: 'Patio', slug: 'hasYard' },
      { name: 'Terraza', slug: 'hasTerrace' },
    ],
    security: [
      { name: 'Alarma', slug: 'hasAlarm' },
      { name: 'Circuito Cerrado de TV', slug: 'hasTvSurveillance' },
      { name: 'Puerta de Seguridad', slug: 'hasSafetyDoor' },
    ],
    interior: [
      { name: 'Calefacción', slug: 'hasHeating' },
      { name: 'Chimenea', slug: 'hasFirePlace' },
      { name: 'Cuarto de Servicio', slug: 'hasServiceRoom' },
      { name: 'Estudio-Biblioteca', slug: 'hasStudy' },
      { name: 'Instalación de Gas', slug: 'hasNaturalGas' },
      { name: 'Jacuzzi', slug: 'hasJacuzzi' },
    ],
    exterior: [
      { name: 'Ascensor', slug: 'hasElevator' },
      { name: 'Cancha de Baloncesto', slug: 'hasBasketField' },
      { name: 'Cancha de Fútbol', slug: 'hasSoccerField' },
      { name: 'Cancha de Squash', slug: 'hasSquashField' },
      { name: 'Cancha de Tenis', slug: 'hasTennisField' },
      { name: 'Gimnasio', slug: 'hasGym' },
      { name: 'Piscina', slug: 'hasSwimmingPool' },
      { name: 'Salón Comunal', slug: 'hasComunalLiving' },
      { name: 'Sauna', slug: 'hasSauna' },
      { name: 'Zona de BBQ', slug: 'hasBbqZones' },
      { name: 'Zona de Niños', slug: 'hasChildrenZones' },
      { name: 'Zonas Verdes', slug: 'hasGreenZones' },
    ],
  },
  relevantLinks: [
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/bogota-colombia',
      description: 'Apartamentos en arriendo Bogotá',
    },
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/bogota-colombia?priceMax=1000000&rooms=2',
      description: 'Apartamentos en arriendo Bogotá 2 habitaciones y hasta 1.000.000',
    },
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/bogota-colombia?priceMax=1000000',
      description: 'Apartamentos en arriendo Bogotá hasta 1.000.000',
    },
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/bogota-colombia?priceMax=1500000&rooms=2',
      description: 'Apartamentos en arriendo Bogotá 2 habitaciones y hasta 1.500.000 ',
    },
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/bogota-colombia?rooms=2',
      description: 'Apartamentos en arriendo Bogotá 2 habitaciones',
    },
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/bogota-colombia?priceMax=1000000&rooms=3',
      description: 'Apartamentos en arriendo Bogotá 3 habitaciones y hasta 1.000.000',
    },
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/bogota-colombia?priceMax=1500000&rooms=3',
      description: 'Apartamentos en arriendo Bogotá 3 habitaciones y hasta 1.500.000',
    },
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/bogota-colombia?priceMax=2000000&rooms=3',
      description: 'Apartamentos en arriendo Bogotá 3 habitaciones y hasta 2.000.000',
    },
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/medellin-antioquia-colombia',
      description: 'Apartamentos en arriendo Medellín',
    },
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/localidad-chapinero-bogota',
      description: 'Apartamentos en arriendo Chapinero',
    },
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/localidad-usaquen-bogota',
      description: 'Apartamentos en arriendo Usaquén',
    },
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/localidad-engativa-bogota',
      description: 'Apartamentos en arriendo Engativá',
    },
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/localidad-suba-bogota',
      description: 'Apartamentos en arriendo Suba',
    },
    {
      urlLink: 'https://www.aptuno.com/inmuebles-arriendo/localidad-teusaquillo-bogota',
      description: 'Apartamentos en arriendo Teusaquillo',
    },
  ],
  documentTypes: [
    { name: 'CC', value: 'CC' },
    { name: 'PA', value: 'PA' },
    { name: 'CE', value: 'CE' },
    { name: 'NIT', value: 'NIT' },
  ],
  logos: [
    { name: 'fincaraiz', alt: 'fincaraiz logo', path: '/images/portal-logos/fincaraiz.png' },
    { name: 'properati', alt: 'properati logo', path: '/images/portal-logos/properati.png' },
    { name: 'ciencuadras', alt: 'ciencuadras logo', path: '/images/portal-logos/ciencuadras.png' },
    { name: 'metrocuadrado', alt: 'metrocuadrado logo', path: '/images/portal-logos/metrocuadrado.png' },
  ],
  contentful: {
    legal: {
      contentType: 'legal',
      privacyId: '72wLIN27E0reulwXlI3RYl',
      termsAndConditionsId: '7J6XH4atdpPIJaadebOaby',
    },
    stickybar: 'stickybar',
    localGuides: 'localGuides',
    faq: 'faq',
    blog: 'blog',
  },
  leaseRequirements: [
    'Tus extractos bancarios (últimos 3 meses)',
    'Declaración de renta del año anterior (Solo si aplica)',
    'Fotocopia de tu documento de identidad',
    'Certificado laboral con salario (si eres empleado)',
    'RUT y Cámara de comercio (si eres independiente, cámara de comercio solo si aplica)',
  ],
  urlRealStateCompanies: 'https://inmobiliaria.aptuno.com/',
  urlReferAndWin: 'https://arrienda.aptuno.com/propietarios/refiere-gana/index.html',
  urlReferForAgents: 'https://www.agentes.aptuno.com/',
  decimalSeparator: ',',
  groupSeparator: '.',
  estateTypesArray: ['Casa', 'Apartamento'],
  landlordVideoUrl: 'https://www.youtube.com/watch?v=8Nb_XRrId3Y',
  linkRelevantsError404: [
    {
      title: 'Descubre inmuebles para alquilar',
      href: 'https://www.aptuno.com/inmuebles-arriendo',
    },
    {
      title: 'Publica tu inmueble',
      href: 'https://www.aptuno.com/publicar-inmueble-en-arriendo/',
    },
    {
      title: 'Refiere propiedades y gana dinero',
      href: 'https://www.refiereygana.aptuno.com/',
    },
    {
      title: 'Conoce Aptuno, su equipo y misión',
      href: 'https://www.aptuno.com/quienes-somos',
    },
  ],
  defaultGeneralEstatePublicationQualityIndex: {
    amountOfPictures: {
      value: 14,
      allowedDeviation: 2,
    },
    descriptionLength: {
      value: 400,
      allowedDeviation: 20,
    },
  },
  plansForConsignedEstate: {
    aptunoBrokerage: {
      friendlyName: 'Corretaje Aptuno',
      includes: [
        {
          text: '<strong>Publicación</strong> en Aptuno y en portales aliados',
          icon: 'plus',
        },
        {
          text: '<strong>Búsqueda del inquilino ideal</strong> para tu inmueble en tiempo récord',
          icon: 'plus',
        },
        {
          text: '<strong>Acompañamiento en visitas</strong> con agentes certificados',
          icon: 'plus',
        },
        {
          text: '<strong>Elaboración de contrato digital</strong> 100% seguro y fácil de llenar',
          icon: 'plus',
        },
      ],
      price: [
        {
          text: '<strong>100% del canon mensual</strong> en el segundo mes de contrato', // FIXME: brokerage to agents
          icon: 'currency',
        },
      ],
    },
    aptunoWarranty: {
      friendlyName: 'Garantía Aptuno',
      includes: [
        {
          text: 'Todo lo que incluye el plan',
          referencePlanText: 'Corretaje Aptuno',
          referencePlanColor: '#F0AE00',
          icon: 'check',
        },
        {
          text: '<strong>Garantía de pago puntual</strong> de renta y administración. hasta 12 meses',
          icon: 'plus',
        },
        {
          text: '<strong>Verificación de perfil</strong> y estudio de crédito para inquilinos',
          icon: 'plus',
        },
        {
          text: '<strong>Asesoría jurídica</strong> en todo lo relacionado al contrato de arrendamiento',
          icon: 'plus',
        },
      ],
      price: [
        {
          text: '<strong>50% del valor del canon mensual + administración</strong> + IVA. Descontado del pago del primer mes de contrato',
          icon: 'currency',
        },
      ],
    },
    blueAptuno: {
      friendlyName: 'Aptuno Azul',
      includes: [
        {
          text: 'Todo lo que incluye el plan',
          referencePlanText: 'Garantía Aptuno',
          referencePlanColor: '#D250E6',
          icon: 'check',
        },
        {
          text: '<strong>Gestión de reparaciones</strong> y mantenimiento del inmueble',
          icon: 'plus',
        },
      ],
      price: [
        {
          text: '<strong>67% del valor del canon mensual + administración</strong> + IVA. Descontado del pago del primer mes de contrato',
          icon: 'currency',
        },
      ],
    },
    aptunoPlus: {
      friendlyName: 'Aptuno Plus',
      includes: [
        {
          text: 'Todo lo que incluye el plan',
          referencePlanText: 'Aptuno Azul',
          referencePlanColor: '#0055FB',
          icon: 'check',
        },
        {
          text: '<strong>Garantía de pago puntual</strong> de renta y admon. hasta 36 meses',
          icon: 'plus',
        },
        {
          text: '<strong>Posición privilegiada</strong> de tu inmueble en aptuno y portales aliados',
          icon: 'plus',
        },
      ],
      price: [
        {
          text: '<strong>83% del valor del canon mensual + administración</strong> + IVA. Descontado del pago del primer mes de contrato',
          icon: 'currency',
        },
      ],
    },
  },
  blackListEndpoint: 'https://core.aptuno.com/blacklist',
  price: {
    min: 550000,
    max: 5000000,
    step: 100000,
  },
  area: {
    min: 20,
    max: 500,
    step: 1,
  },
  landlordsSupportPhone: '(601) 404 9078',
  landingPages: {
    aptunoWarranty: 'https://www.garantia.aptuno.com',
  },
};
