import { useLazyQuery } from '@apollo/client';
import { EstateId } from '../../../../../share/estate/estate';
import { Phone } from '../../../../../share/user/user';
import { schema as GetEstateReferralPhoneSchema } from '../schemas/get-estate-referral-phone.schema';
import {
  GqlGetEstateReferralPhoneQuery,
  GqlGetEstateReferralPhoneQueryVariables,
} from '../../../../Shared/integrations/gql/core/generated-types';

export const useGetEstateReferralPhone = () => {
  const [call] = useLazyQuery<GqlGetEstateReferralPhoneQuery, GqlGetEstateReferralPhoneQueryVariables>(
    GetEstateReferralPhoneSchema
  );

  return (estateId: EstateId): Promise<Phone> =>
    call({ variables: { estateId: estateId.value } }).then(({ data }) =>
      Phone.of(data!.getEstateReferral!.phoneNumber!)
    );
};
