import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { getLocalStorageItem, setLocalStorageItem } from '../helpers/localStorage';

export const UTM_INFO = 'utm_info';

export interface TrackingData {
  source: string | null;
  campaign: string | null;
  medium: string | null;
}

export function useUtmTrackingData(): TrackingData {
  const { search } = useLocation();
  return React.useMemo(() => {
    const params = new URLSearchParams(search);

    return {
      campaign: params.get('utm_campaign'),
      medium: params.get('utm_medium'),
      source: params.get('utm_source'),
    };
  }, [search]);
}

type LegacyUtmData = {
  utmInfo: TrackingData;
};

export function useUtmInfo(): LegacyUtmData {
  const trackingData = useUtmTrackingData();

  React.useEffect(() => {
    const utmDataIsNotStored = !getLocalStorageItem(UTM_INFO);
    if (utmDataIsNotStored) {
      const { medium, source, campaign } = trackingData;
      if (source && medium && campaign) {
        setLocalStorageItem(UTM_INFO, JSON.stringify(trackingData));
      }
    }
  }, [trackingData]);

  return {
    utmInfo: trackingData,
  };
}
