import queryString from 'query-string';
import TagManager from 'react-gtm-module';
import { countries, currencies } from '../data/currencies';
import { env } from '../environment/environment';
import history from './../history';
// TODO - Validate this dependency
import { getParametersFromUrl, PAGE_CATEGORY } from './../services/url';
import currency from './currency';
import { isColombia } from './regionUtils';

export const defaultImageNotFound =
  'https://cdn.aptuno.com/properties-images/928/e715acf1-86dc-499a-9cbf-8517d0f91217.jpg';

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const THOUSANDS_SEPARATOR = isColombia() ? '.' : ',';

export const formatMoney = (amount, decimalCount = 2, decimal = '.', thousands = ',') => {
  return currency(amount, { formatWithSymbol: false, precision: 0, separator: THOUSANDS_SEPARATOR }).format();
};

export const numberShortener = (num) => {
  if (num > 999 && num < 1000000) return (num / 1000).toFixed(1) + 'K';
  if (num > 1000000) return (num / 1000000).toFixed(1) + 'M';
  if (num < 900) return num;
};

export const chunk_inefficient = (array, chunkSize) => {
  return [].concat.apply(
    [],
    array.map(function (elem, i) {
      return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
    })
  );
};

export class Responsive {
  constructor(setResponsiveMethod) {
    this.setResponsiveMethod = setResponsiveMethod || (() => {});
    this.values = this.checkSizes();
    this.setResponsiveMethod(this.values);

    const self = this;

    window.addEventListener('resize', () => {
      self.values = this.checkSizes();
      self.setResponsiveMethod(self.values);
    });
  }

  checkSizes() {
    return {
      isMobile: window.innerWidth < 600,
      isMediumSize: window.innerWidth > 599 && window.innerWidth < 992,
      isDesktop: window.innerWidth > 991,
    };
  }
}

export const toggleBodyScroll = (val) => {
  const body = document.querySelector('body');
  if (val !== null && val !== undefined) {
    if (val) body.classList.remove('no-scroll');
    else body.classList.add('no-scroll');
  } else body.classList.toggle('no-scroll');
};

export const parseImages = (image, size = 'main', type = 'jpg') => {
  const basePre = isLocalhost ? 'https://storage.googleapis.com/dev-assets' : env.gcpStorage.baseUrl;
  const basePost = isLocalhost ? 'https://dev-cdn.aptuno.com' : env.gcpStorage.cdnBaseUrl;
  const imagePath = image?.uri || image;
  if (imagePath) {
    const cdnImageLink = imagePath.replace(basePre, basePost);
    const resourceUriWithOutExtension = cdnImageLink.replace(/\.jpg/i, '');

    return size === 'thumb' ? `${resourceUriWithOutExtension}_thumb.${type}` : cdnImageLink;
  } else {
    return defaultImageNotFound;
  }
};

export const getMainImage = (images) => {
  return images.find((image) => image.main) || images[0];
};

export const parseObjectCharacteristics = (cObj) => {
  const cs = {
    generals: [],
    propertyDetails: [],
    features: [],
    others: [],
  };
  const emptyObj = { value: null, withValue: null, name: '', slug: '' };
  if (cObj.newProperty)
    cs.generals.push(Object.assign({}, emptyObj, { slug: 'propiedad-nueva', value: cObj.newProperty }));
  if (cObj.highDemand) cs.generals.push(Object.assign({}, emptyObj, { slug: 'alta-demanda', value: cObj.highDemand }));
  if (cObj.area) cs.features.push(Object.assign({}, emptyObj, { slug: 'area', value: cObj.area }));
  if (cObj.bathrooms) cs.features.push(Object.assign({}, emptyObj, { slug: 'nro-banos', value: cObj.bathrooms }));
  if (cObj.amountOfBathrooms)
    cs.features.push(Object.assign({}, emptyObj, { slug: 'nro-banos', value: cObj.amountOfBathrooms }));
  if (cObj.rooms) cs.features.push(Object.assign({}, emptyObj, { slug: 'nro-cuartos', value: cObj.rooms }));
  if (cObj.amountOfBedrooms)
    cs.features.push(Object.assign({}, emptyObj, { slug: 'nro-cuartos', value: cObj.amountOfBedrooms }));
  if (cObj.isFurnished) {
    cs.generals.push(Object.assign({}, emptyObj, { slug: 'amoblada', value: cObj.isFurnished }));
    cs.generals.push(Object.assign({}, emptyObj, { slug: 'muebles', value: cObj.isFurnished }));
  }
  if (cObj.isPriceDropped)
    cs.generals.push(Object.assign({}, emptyObj, { slug: 'bajo-precio', value: cObj.isPriceDropped }));

  return cs;
};

export const parseCharacteristics = (c) => {
  const cs = {
    generals: [],
    propertyDetails: [],
    features: [],
    others: [],
  };
  for (let index = 0; index < c.length; index++) {
    const ele = c[index];
    const value = c[index].value;
    switch (true) {
      case ['area-construida', 'area', 'se-permiten-mascotas', 'nro-banos', 'nro-cuartos', 'nro-garajes'].indexOf(
        ele.slug
      ) !== -1:
        cs.features.push(Object.assign({}, ele, { value }));
        break;
      case [
        'propiedad-nueva',
        'alta-demanda',
        'precio-negociable',
        'link-calendly',
        'video-url',
        'matterport-url',
        'tour-3d-url',
      ].indexOf(ele.slug) !== -1:
        cs.generals.push(Object.assign({}, ele, { value }));
        break;
      default:
        cs.others.push(Object.assign({}, ele, { value }));
        break;
    }
  }
  return cs;
};

export const emailValidation = (elementValue) => {
  var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(elementValue);
};

export const getFromDictionarty = (key) => {
  const datos = {
    APARTMENT: isColombia() ? 'apartamento' : 'departamento',
    HOUSE: 'casa',
  };

  return datos[key] || 'inmueble';
};

export const getValuesFromUrl = () => {
  const values = {};
  const parsed = queryString.parse(history.location.search);
  for (const key in parsed) {
    const element = parsed[key];
    if (key !== '' && element != null) {
      values[key] = element;
    }
  }

  // Values from url path
  const { propertyType, location } = getParametersFromUrl(PAGE_CATEGORY.RESULT_PAGE);
  if (propertyType) {
    values.propertyType = propertyType;
  }
  if (location) {
    values.location = location;
  }

  return values;
};

export const buildFilterQuery = (filter, sort) => {
  const location = filter.locations ? `${filter.locations.value}` : '';
  const t = filter.type ? `type=${filter.type}&` : '';
  const pmax =
    filter.pricing && Number(filter.pricing.value.priceMax) !== 0 ? `priceMax=${filter.pricing.value.priceMax}&` : '';
  const r = filter.rooms && Number(filter.rooms.value) !== 0 ? `rooms=${filter.rooms.value}&` : '';
  const b = filter.baths ? `baths=${filter.baths.value}&` : '';

  const g = filter.garageNr ? `garages=${filter.garageNr}&` : '';
  const a = filter.propertyAge ? `propertyAge=${filter.propertyAge}&` : '';
  const pt = filter.propertyType ? `propertyType=${filter.propertyType}&` : '';

  const s = sort ? `sort=${sort.key}&` : '';
  const st = sort && sort.topOrBot ? `sortType=${sort.topOrBot}&` : '';
  return `${location !== '' ? '/' + location : ''}?${t}${r}${b}${g}${a}${pt}${s}${st}${pmax}`; // /s/
};

export const gaEvent = (
  category = 'Web',
  label = 'noLabel',
  action = 'clickBtn',
  event = 'event',
  nonInteraction = false
) => {
  const data = {
    dataLayer: {
      event: 'GAevent',
      GAevent: {
        category,
        action,
        label,
        nonInteraction,
      },
    },
  };

  TagManager.dataLayer(data);
};

export const inBounds = (point, bounds) => {
  var eastBound = point.lng < bounds.ne.lng;
  var westBound = point.lng > bounds.sw.lng;
  var inLng;

  if (bounds.ne.lng < bounds.sw.lng) {
    inLng = eastBound || westBound;
  } else {
    inLng = eastBound && westBound;
  }

  var inLat = point.lat > bounds.sw.lat && point.lat < bounds.ne.lat;
  return inLat && inLng;
};

// TODO(Jefferson): Pending refactor
let loadedScripts = new Map();
export const loadScript = (url, scriptId, callback) => {
  const callbacks = loadedScripts.get(scriptId);
  if (callbacks) {
    const map = document.getElementById(scriptId);
    if (map.getAttribute('data-loaded')) callback();
    else loadedScripts.get(scriptId).push(callback);
  } else {
    loadedScripts.set(scriptId, [callback]);
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = scriptId;

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          script.setAttribute('data-loaded', 'true');
          loadedScripts.get(scriptId).forEach((element) => element());
        }
      };
    } else {
      script.onload = () => {
        script.setAttribute('data-loaded', 'true');
        loadedScripts.get(scriptId).forEach((element) => element());
      };
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
};

export const groupByKey = (list, key) =>
  list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
};

export const sortByPropertyName = (key) => {
  return function (a, b) {
    if (a[key] > b[key]) {
      return 1;
    } else if (a[key] < b[key]) {
      return -1;
    }
    return 0;
  };
};

export const sortBySpecificOrder = (sortList, propertyKey) => {
  return (a, b) => {
    return sortList.indexOf(a[propertyKey]) - sortList.indexOf(b[propertyKey]);
  };
};

export const sortByName = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const scrollToRef = (ref, offsetTopAdjust = 16) =>
  window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - offsetTopAdjust });

export const getEstatesMapper = (args) => {
  return {
    variables: {
      locationSlug: args.location,
      offset: args.offset,
      limit: args.limit,
      orderBy: args.orderBy,
      minPrice: Number(args.priceMin),
      maxPrice: Number(args.priceMax),
      rooms: Number(args.rooms),
      minGarajes: Number(args.garages),
      minBaths: Number(args.baths),
      propertyType: args.propertyType,
      propertyAge: args.propertyAge,
      minArea: args.area && args.area.split(',').length > 0 ? Number(args.area.split(',')[0]) : null,
      maxArea: args.area && args.area.split(',').length > 1 ? Number(args.area.split(',')[1]) : null,
      pets: args.pets ? 'true' : null,
      stratum: args.stratum ? args.stratum.split(',').map((i) => Number(i)) : null,
      remodeled: args.remodeled,
      characteristics: args.characteristics,
    },
  };
};

export const characteristicsMapper = (args) => {
  const characteristics = [];
  if (args.pets) characteristics.push({ slug: 'mascotas', value: 'true' });
  if (args.remodeled) characteristics.push({ slug: 'remodelado', value: args.remodeled });
  if (args.stratum) characteristics.push({ slug: 'estrato', value: args.stratum });

  if (args.generals.indexOf('enConjuntoCerrado') !== -1)
    characteristics.push({ slug: 'conjunto-cerrado', value: 'true' });
  if (args.generals.indexOf('conDeposito') !== -1) characteristics.push({ slug: 'deposito', value: 'true' });
  if (args.generals.indexOf('isFurnished') !== -1) characteristics.push({ slug: 'isFurnished', value: 'true' });
  if (args.generals.indexOf('hasTerrace') !== -1) characteristics.push({ slug: 'hasTerrace', value: 'true' });
  if (args.generals.indexOf('hasBalcony') !== -1) characteristics.push({ slug: 'hasBalcony', value: 'true' });

  if (args.inners.indexOf('conAlarma') !== -1) characteristics.push({ slug: 'alarma', value: 'true' });
  if (args.inners.indexOf('conPuertasDeSeguridad') !== -1)
    characteristics.push({ slug: 'puerta-de-seguridad', value: 'true' });
  if (args.inners.indexOf('conJacuzzi') !== -1) characteristics.push({ slug: 'jacuzzi', value: 'true' });
  if (args.inners.indexOf('conZonaLavanderia') !== -1)
    characteristics.push({ slug: 'zona-de-lavanderia', value: 'true' });
  if (args.inners.indexOf('conCuartoServicio') !== -1)
    characteristics.push({ slug: 'cuarto-de-servicio', value: 'true' });
  if (args.inners.indexOf('conEstudioBiblioteca') !== -1)
    characteristics.push({ slug: 'estudio-biblioteca', value: 'true' });
  if (args.inners.indexOf('conChimenea') !== -1) characteristics.push({ slug: 'chimenea', value: 'true' });
  if (args.inners.indexOf('conCalefaccion') !== -1) characteristics.push({ slug: 'calefaccion', value: 'true' });
  if (args.inners.indexOf('conCortinas') !== -1) characteristics.push({ slug: 'cortinas', value: 'true' });
  if (args.inners.indexOf('conInstalacionGas') !== -1)
    characteristics.push({ slug: 'instalacion-de-gas', value: 'true' });

  if (args.outters.indexOf('conCircuitoCerradoTv') !== -1)
    characteristics.push({ slug: 'circuito-cerrado-de-tv', value: 'true' });
  if (args.outters.indexOf('conJardin') !== -1) characteristics.push({ slug: 'jardin', value: 'true' });
  if (args.outters.indexOf('conSenderopeatonal') !== -1)
    characteristics.push({ slug: 'sendero-peatonal', value: 'true' });
  if (args.outters.indexOf('conZonaVerdes') !== -1) characteristics.push({ slug: 'zonas-verdes', value: 'true' });
  if (args.outters.indexOf('conSauna') !== -1) characteristics.push({ slug: 'sauna', value: 'true' });
  if (args.outters.indexOf('conGimnasio') !== -1) characteristics.push({ slug: 'gimnasio', value: 'true' });
  if (args.outters.indexOf('conPiscina') !== -1) characteristics.push({ slug: 'piscina', value: 'true' });
  if (args.outters.indexOf('conCanchaSquash') !== -1) characteristics.push({ slug: 'cancha-de-squash', value: 'true' });
  if (args.outters.indexOf('conCanchaTenis') !== -1) characteristics.push({ slug: 'cancha-de-tenis', value: 'true' });
  if (args.outters.indexOf('conCanchaFutbol') !== -1) characteristics.push({ slug: 'cancha-de-futbol', value: 'true' });
  if (args.outters.indexOf('conCanchaBasket') !== -1)
    characteristics.push({ slug: 'cancha-de-baloncesto', value: 'true' });
  if (args.outters.indexOf('conZonaNinos') !== -1) characteristics.push({ slug: 'zona-de-ninos', value: 'true' });
  if (args.outters.indexOf('conSalonComunal') !== -1) characteristics.push({ slug: 'salon-comunal', value: 'true' });
  if (args.outters.indexOf('ascensor') !== -1) characteristics.push({ slug: 'ascensor', value: 'true' });
  if (args.outters.indexOf('conGarajeCubierto') !== -1)
    characteristics.push({ slug: 'garaje-cubierto', value: 'true' });
  if (args.outters.indexOf('conZonaBbq') !== -1) characteristics.push({ slug: 'zona-de-bbq', value: 'true' });

  return characteristics;
};
export const fromEntries = (iterable) => {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
};

export const excludeVoidAndNulls = (obj) => {
  return fromEntries(
    Object.entries(obj).filter((el) => (!Array.isArray(el[1]) && el[1]) || (Array.isArray(el[1]) && el[1].length > 0))
  );
};

export const scrollToItem = (id) => {
  const element = document.getElementById(id);
  if (element) {
    window.scrollTo({ behavior: 'smooth', top: element.offsetParent.offsetTop + element.offsetTop - 80 });
    if (element.firstChild && element.firstChild.click) element.firstChild.click();
  }
};

export const loadThirdPartyScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = resolve;
    script.onerror = reject;
    script.src = src;
    document.head.append(script);
  });
};

export const parseImageUrl = (url) => {
  const pattern = /^\/+/;

  return url.replace(pattern, 'https://');
};

export const getCountryByCurrency = (currencyIso) => {
  switch (currencyIso) {
    case currencies.MXN:
      return countries.MEXICO;
    case currencies.COP:
    default:
      return countries.COLOMBIA;
  }
};

export const getPositionInString = (string, subString, index) => {
  return string?.split(subString, index).join(subString).length;
};

export function navigateToSsrUrl(url) {
  window.location.href = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${url}`;
}
