import gql from 'graphql-tag';

export const GET_ESTATE_AVAILABILITY = gql`
  query EstateVisitAvailability($estateId: Int!, $date: Float!) {
    estateVisitAvailability(estateId: $estateId, date: $date) {
      estateId
      availability {
        date
        weekDay
        slots {
          date
          enabled
          confirmed
        }
      }
      recommended {
        date
        weekDay
        slots {
          date
          enabled
          confirmed
        }
      }
    }
  }
`;

export const GET_APPOINTMENT_AVAILABILITY = gql`
  query GetAppointmentsAvailability($estateId: Int!, $userId: Int) {
    getAppointmentsAvailability(estateId: $estateId, userId: $userId) {
      visitDate
      type
      status
      enabled
      isConfirmed
    }
  }
`;

export const CREATE_ESTATE_APPOINTMENT = gql`
  mutation CreateAppointment($appointment: CreateAppointmentInputType!) {
    createAppointment(appointment: $appointment) {
      id
      estate {
        id
        legalId
        address
        pricing {
          quote
          admonPrice
          currencyISO
        }
      }
      status
      visitDate
      type
      tenant {
        userId
        firstName
        lastName
        phoneNumber
        phoneCode
        email
        identityDocumentType
        identityDocumentCode
      }
      visitAssistant {
        userId
        firstName
        lastName
        phoneNumber
        phoneCode
        email
        identityDocumentType
        identityDocumentCode
      }
    }
  }
`;

export const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment($cancelationReason: AppointmentCancelationReasonInputType!, $appointmentId: Int!) {
    cancelAppointment(cancelationReason: $cancelationReason, appointmentId: $appointmentId) {
      id
      estate {
        id
        legalId
        address
        pricing {
          quote
          admonPrice
          currencyISO
        }
      }
      visitDate
      type
      status
      tenant {
        userId
        firstName
        lastName
        phoneNumber
        phoneCode
        email
        identityDocumentType
        identityDocumentCode
      }
      visitAssistant {
        userId
        firstName
        lastName
        phoneNumber
        phoneCode
        email
        identityDocumentType
        identityDocumentCode
      }
      visitCompanion {
        userId
        firstName
        lastName
        phoneNumber
        phoneCode
        email
        identityDocumentType
        identityDocumentCode
      }
    }
  }
`;

export const GET_APPOINTMENTS_BY_TENANT = gql`
  query GetAppointmentByTenant($tenantAppointmentsFilter: TenantAppointmentsFilterInputType!) {
    getAppointmentsByTenant(TenantAppointmentsFilter: $tenantAppointmentsFilter) {
      id
      estate {
        id
        legalId
        address
        pricing {
          quote
          admonPrice
          currencyISO
        }
      }
      visitDate
      type
      status
      tenant {
        userId
        firstName
        lastName
        phoneCode
        phoneNumber
        identityDocumentType
        identityDocumentCode
        email
      }
      visitAssistant {
        userId
        firstName
        lastName
        phoneCode
        phoneNumber
        identityDocumentType
        identityDocumentCode
        email
      }
      visitCompanion {
        userId
        firstName
        lastName
        phoneCode
        phoneNumber
        identityDocumentType
        identityDocumentCode
      }
    }
  }
`;

export const RESCHEDULE_APPOINTMENT = gql`
  mutation RescheduleAppointment(
    $newVisitAssistant: AppointmentUserInputType
    $newVisitDate: Float!
    $appointmentType: AppointmentType!
    $appointmentId: Int!
  ) {
    rescheduleAppointment(
      newVisitAssistant: $newVisitAssistant
      newVisitDate: $newVisitDate
      appointmentType: $appointmentType
      appointmentId: $appointmentId
    ) {
      id
      estate {
        id
        legalId
        address
        pricing {
          quote
          admonPrice
          currencyISO
        }
      }
      status
      visitDate
      type
      visitCompanion {
        userId
        firstName
        lastName
        phoneNumber
        phoneCode
        email
        identityDocumentType
        identityDocumentCode
      }
      visitAssistant {
        userId
        firstName
        lastName
        phoneNumber
        phoneCode
        email
        identityDocumentType
        identityDocumentCode
      }
    }
  }
`;

export const GET_APPOINTMENTS_BY_ESTATE = gql`
  query GetAppointmentsByEstate($estateLegalId: String!) {
    getAppointmentsByEstate(estateLegalId: $estateLegalId) {
      id
      estate {
        id
        legalId
        address
        pricing {
          quote
          admonPrice
          currencyISO
        }
      }
      visitDate
      type
      status
      tenant {
        userId
        firstName
        lastName
      }
      visitAssistant {
        userId
        firstName
        lastName
      }
      visitCompanion {
        userId
        firstName
        lastName
        phoneCode
        phoneNumber
      }
      instructions
      suggestedInstructions
    }
  }
`;

export const LANDLORD_CONFIRM_APPOINTMENT = gql`
  mutation LandlordConfirmAppointment($instructions: String, $appointmentId: Int!) {
    landlordConfirmAppointment(instructions: $instructions, appointmentId: $appointmentId) {
      id
      estate {
        id
        legalId
        address
        pricing {
          quote
          admonPrice
          currencyISO
        }
      }
      visitDate
      type
      status
      tenant {
        userId
        firstName
        lastName
        phoneNumber
        phoneCode
        email
        identityDocumentType
        identityDocumentCode
      }
      visitAssistant {
        userId
        firstName
        lastName
        phoneNumber
        phoneCode
        email
        identityDocumentType
        identityDocumentCode
      }
      visitCompanion {
        userId
        firstName
        lastName
        phoneNumber
        phoneCode
        email
        identityDocumentType
        identityDocumentCode
      }
    }
  }
`;

export const GET_AMOUNT_OF_OPEN_APPOINTMENTS_ON_DIFFERENT_ESTATES = gql`
  query {
    getAmountOfOpenAppointmentsOnDifferentEstates
  }
`;

export const GET_AMOUNT_OF_EFFECTIVE_APPOINTMENTS_ON_DIFFERENT_ESTATES_FOR_CHAIN_SCHEDULING = gql`
  query {
    getAmountOfEffectiveAppointmentsOnDifferentEstatesForChainScheduling
  }
`;

export const GET_RECOMMENDATIONS_FOR_LATEST_SCHEDULED_ESTATE = gql`
  query {
    getRecommendationsForLatestScheduledEstate {
      administrationPrice
      area
      bathrooms
      currencyIso
      newProperty
      isFurnished
      highDemand
      isPriceDropped
      propertyLegalId
      price
      propertyType
      rooms
      status
      title
      images {
        id
        uri
        main
      }
    }
  }
`;

export const GET_COMPLETED_APPOINTMENTS_BY_TENANT = gql`
  query GetCompletedAppointmentsByTenant($page: Int, $pageSize: Int) {
    getCompletedAppointmentsByTenant(page: $page, pageSize: $pageSize) {
      id
      estate {
        id
        legalId
        address
        pricing {
          quote
          admonPrice
          currencyISO
        }
      }
      visitDate
      type
      tenantUserId
      visitCompanionAgent
    }
  }
`;

export const GET_APPOINTMENTS_BY_LANDLORD = gql`
  query GetAppointmentsByLandlord {
    getAppointmentsByLandlord {
      id
      visitDate
      status
      type
      instructions
      suggestedInstructions
      estate {
        id
        legalId
        address
        pricing {
          quote
          currencyISO
          admonPrice
        }
      }
      visitCompanion {
        phoneNumber
        phoneCode
        lastName
        firstName
      }
      tenant {
        firstName
        lastName
        phoneNumber
        phoneCode
      }
    }
  }
`;
