import { capitalize } from '../../helpers/coolFunctions';

export class UserId {
  public readonly _tag = 'UserId';

  constructor(public readonly value: number) {}

  static of = (value: number): UserId => new UserId(value);

  static unsafe = (value: number | null | undefined): UserId | undefined => (value ? new UserId(value) : undefined);
}

export class Phone {
  public readonly _tag = 'Phone';

  constructor(public readonly value: string) {}

  onlyDigits = (): Phone => Phone.of(this.value.replace(/\D+/g, ''));

  static unsafe = (value: string | null | undefined): Phone | undefined => (value ? new Phone(value) : undefined);
  static of = (value: string): Phone => new Phone(value);
}

export class Email {
  public readonly _tag = 'Email';

  constructor(public readonly value: string) {}

  static of = (value: string): Email => new Email(value);
}

export class PersonName {
  public readonly _tag = 'PersonName';
  public readonly value: string;

  constructor(value: string) {
    this.value = !!value
      ? value
          .trim()
          .split(' ')
          .filter((word) => word !== '')
          .map((word) => capitalize(word))
          .join(' ')
      : '';
  }

  static of = (unsafe: string): PersonName => new PersonName(unsafe);
}
