export const currencies = {
  ARS: 'ARS',
  COP: 'COP',
  USD: 'USD',
  EUR: 'EUR',
  MXN: 'MXN'
};

export const countries = {
  MEXICO: 'MEXICO',
  COLOMBIA: 'COLOMBIA',
  EEUU: 'ESTADOS UNIDOS',
};
