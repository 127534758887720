import React from 'react';
import { GqlUserOutputType } from '../Shared/integrations/gql/core/generated-types';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_DATA } from '../../hooks/useAuthQuery';
import { mapToUserFront } from '../../helpers/network-helpers';

const getLocallyStoredToken = () => window && localStorage.getItem('token');
const removeLocallyStoredToken = () => window && localStorage.removeItem('token');
const setLocallyStoredToken = (token: string) => window && localStorage.setItem('token', token);

const initialToken = getLocallyStoredToken() || undefined;

type TokenAction = {
  kind: 'SET' | 'DROP' | 'GET';
  payload?: string;
};

const tokenReducer = (state: string | undefined, action: TokenAction): string | undefined => {
  switch (action.kind) {
    case 'SET':
      action.payload && setLocallyStoredToken(action.payload);

      return action.payload;
    case 'DROP':
      removeLocallyStoredToken();

      return undefined;
    default:
      return state;
  }
};

const SessionTokenUpdaterContext = React.createContext<React.Dispatch<TokenAction> | null>(null);

export enum LoggedInUserStatus {
  UNKNOWN = 'UNKNOWN',
  LOGGED_IN = 'LOGGED_IN',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
}

type LoggedInUser =
  | {
      status: LoggedInUserStatus.LOGGED_IN;
      data: GqlUserOutputType;
    }
  | {
      status: LoggedInUserStatus.UNKNOWN;
    }
  | {
      status: LoggedInUserStatus.NOT_LOGGED_IN;
    };

type LoggedInUserAction = {
  kind: 'SET' | 'GET';
  payload?: LoggedInUser;
};

const token = getLocallyStoredToken();
const initialLoggedInUser: LoggedInUser = token
  ? { status: LoggedInUserStatus.UNKNOWN }
  : { status: LoggedInUserStatus.NOT_LOGGED_IN };

const loggedInUserReducer = (state: LoggedInUser, action: LoggedInUserAction): LoggedInUser => {
  switch (action.kind) {
    case 'SET':
      if (action.payload) {
        return Object.assign({}, state, action.payload);
      } else {
        return state;
      }
    default:
      return state;
  }
};

const LoggedInUserContext = React.createContext<LoggedInUser>(initialLoggedInUser);
const LoggedInUserUpdaterContext = React.createContext<React.Dispatch<LoggedInUserAction> | null>(null);

const useSession = () => {
  const [sessionToken, updateSessionToken] = React.useReducer(tokenReducer, initialToken);
  const [loggedInUser, updateLoggedInUser] = React.useReducer(loggedInUserReducer, initialLoggedInUser);

  const [getAuthenticatedUser] = useLazyQuery<{
    getAuthenticatedUser: GqlUserOutputType;
  }>(GET_USER_DATA, {
    errorPolicy: 'all',
    context: { clientName: 'core' },
    fetchPolicy: 'cache-first',
    onError: (error) => {
      console.error('useSession.getAuthenticatedUserError', JSON.stringify(error));

      updateSessionToken({ kind: 'DROP' });
    },
    onCompleted: (data) => {
      const hasUserSessionFailed = data && !data.getAuthenticatedUser;

      if (hasUserSessionFailed) {
        console.error('useSession.getAuthenticatedUserCompleted');

        updateSessionToken({ kind: 'DROP' });
      } else {
        updateLoggedInUser({
          kind: 'SET',
          payload: {
            status: LoggedInUserStatus.LOGGED_IN,
            data: mapToUserFront(data.getAuthenticatedUser),
          },
        });
      }
    },
  });

  React.useEffect(() => {
    if (sessionToken && LoggedInUserStatus.LOGGED_IN !== loggedInUser.status) {
      getAuthenticatedUser().then(() => {
        console.log('fetched user data');
      });
    } else if (!sessionToken && LoggedInUserStatus.NOT_LOGGED_IN !== loggedInUser.status) {
      updateLoggedInUser({ kind: 'SET', payload: { status: LoggedInUserStatus.NOT_LOGGED_IN } });
    }
  }, [loggedInUser, sessionToken]);

  return {
    loggedInUser,
    updateLoggedInUser,
    updateSessionToken,
  };
};

export const useLoggedInUser = () => React.useContext(LoggedInUserContext);
export const useUpdateLoggedInUser = () => React.useContext(LoggedInUserUpdaterContext);
export const useUpdateSessionToken = () => React.useContext(SessionTokenUpdaterContext);

export const SessionProvider = ({ children }) => {
  const { loggedInUser, updateLoggedInUser, updateSessionToken } = useSession();

  return (
    <>
      <SessionTokenUpdaterContext.Provider value={updateSessionToken}>
        <LoggedInUserContext.Provider value={loggedInUser}>
          <LoggedInUserUpdaterContext.Provider value={updateLoggedInUser}>
            {children}
          </LoggedInUserUpdaterContext.Provider>
        </LoggedInUserContext.Provider>
      </SessionTokenUpdaterContext.Provider>
    </>
  );
};
