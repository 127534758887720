import { gql } from '@apollo/client';

export const schema = gql`
  mutation CreateAppointment($appointment: CreateAppointmentInputType!) {
    createAppointment(appointment: $appointment) {
      id
      estate {
        id
      }
      visitDate
      tenant {
        userId
      }
    }
  }
`;
