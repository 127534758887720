import styled from 'styled-components';

const ActionBarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -10px 25px #6c757d0f;
  border-radius: 24px 24px 0px 0px;
  padding: 16px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  height: 80px;
  max-width: 592px;
  z-index: 100;
  gap: 1rem;
  
  @media (min-width: 600px) {
    grid-template-columns: 2fr 4fr;
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  flex: 2 2 70%;
`;

const ContainerLeft = styled(Container)`
  width: 100%;
  justify-content: flex-start;
  flex: 1 1 30%;
`;

const Text = styled.p`
  margin: 0px 2% 0px 0px;
`;

const Icon = styled.i`
  font-size: 35px;
  font-style: normal;
  font-weight: 1000;
  width: 20%;
`;

export { ActionBarContainer, Container, ContainerLeft, Text, Icon };
