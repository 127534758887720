import gql from 'graphql-tag';

export const DO_USER_EDIT = gql`
  mutation userEdit($firstName: String, $lastName: String, $email: String, $phoneNumber: String, $identityDocumentType: String, $identityDocumentCode: String) {
    userEdit(firstName: $firstName, lastName: $lastName, email: $email, phoneNumber: $phoneNumber, identityDocumentType: $identityDocumentType, identityDocumentCode: $identityDocumentCode)
  }
`;

export const GET_TENANT_FOR_AUTHENTICATED_USER = gql`
  query {
    getTenantForAuthenticatedUser {
      id
      userId
      firstName
      lastName
      email
      phoneNumber
      status
      identityDocumentCode
      identityDocumentType
      estimatedMoveDate
    }
  }
`;

export const UPDATE_TENANT = gql`
  mutation UpdateTenant($tenant: TenantInputType!) {
    updateTenant(tenant: $tenant) {
      id
      userId
      firstName
      lastName
      email
      phoneNumber
      status
      identityDocumentCode
      identityDocumentType
    }
  }
`;
