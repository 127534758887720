import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { sendToGoogleAnalytics } from './helpers/analiticsUtils';

import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { env } from './environment/environment';
import App from './App';
import { FirebaseProvider } from './containers/FirebaseProvider';
import { SessionProvider } from './Context/Auth/SessionProvider';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, extensions, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${extensions.code}, Path: ${path}`)
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const coreLink = errorLink.concat(authLink.concat(createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_SERVER_URI })));

const creditStudyLink = errorLink.concat(authLink.concat(createUploadLink({ uri: env.riskStudyUrl })));

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.split((operation) => operation.getContext().clientName === 'second', creditStudyLink, coreLink),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <SessionProvider>
      <FirebaseProvider>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </FirebaseProvider>
    </SessionProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorkerRegistration.unregister();
reportWebVitals(sendToGoogleAnalytics);
