import React from 'react';
import { MagicDots } from '../components/MagicDots';
import { useTranslation } from 'react-i18next';
import { getSeoForPage } from '../services/seo';
import Seo from '../components/Seo';
import { PAGE_CATEGORY } from './../services/url';
import { CookiesBox } from '../components/CookiesBox/CookiesBox';

const ContentFullProvider = props => {
  const { t } = useTranslation();
  const [seo] = React.useState(() => getSeoForPage(t, PAGE_CATEGORY.HOME));

  return (
    <div className="Blog">
      <Seo
        title={seo.title}
        description={seo.description}
        image={seo.image}
        url={seo.canonical || window.location.href}
      />
      <CookiesBox urlLink={'/politica-de-privacidad'} bgColor="#F8F8F8" textColor="#000000" />

      <header />
      {!props.withoutDots && <MagicDots type="only-grey-3" />}
      <props.view {...props} />
    </div>
  );
};

export default ContentFullProvider;
