export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  LocalDateTime: any;
  Upload: any;
};

export type GqlQuery = {
  __typename?: 'Query';
  buildSignedUrlForFileUploading: Scalars['String'];
  countVisitedAppointmentsByEstateForLastMonth?: Maybe<Scalars['Float']>;
  estateVisitAvailability: GqlEstateVisitAvailabilityOutputType;
  exposedDocumentation?: Maybe<GqlExposedDocumentationOutputType>;
  exposedEstate?: Maybe<GqlExposedEstateOutputType>;
  filters: GqlFilters;
  getAmountOfEffectiveAppointmentsOnDifferentEstatesForChainScheduling: Scalars['Int'];
  getAmountOfOpenAppointmentsOnDifferentEstates: Scalars['Int'];
  getAppointmentsAvailability: Array<GqlAppointmentAvalilabilityOutputType>;
  getAppointmentsByEstate: Array<GqlAppointmentOutputType>;
  getAppointmentsByEstateForLastMoth: Array<GqlAppointmentOutputType>;
  getAppointmentsByLandlord: Array<GqlAppointmentOutputType>;
  getAppointmentsByTenant: Array<GqlAppointmentOutputType>;
  getAppointmentsByVisitCompanionAgent: Array<GqlAppointmentOutputType>;
  getAuthenticatedUser?: Maybe<GqlUserOutputType>;
  getCapturingManagers: Array<GqlCapturingManagerOutputType>;
  getCompletedAppointmentsByTenant: Array<GqlCompletedAppointmentOutputType>;
  getEstate: GqlProperty;
  getEstateReferral: GqlUserOutputType;
  getGeneralEstatePublicationQualityIndex: GqlGeneralEstatePublicationQualityOutputType;
  getRecommendationsForLatestScheduledEstate: Array<GqlPropertyBrief>;
  getSearchableEstateLocation: Array<GqlSearchableEstateLocationOutputType>;
  getTenantByPhone?: Maybe<GqlTenantOutputType>;
  getTenantForAuthenticatedUser?: Maybe<GqlTenantOutputType>;
  getUserEstates: Array<GqlFullEstateType>;
  getVisitCompanions: Array<GqlVisitCompanionOutputType>;
  getVisitFeedbacksByEstate: Array<GqlVisitFeedbackOutputType>;
  internalDocumentation?: Maybe<GqlDocumentationOutputType>;
  listAgentsAvailableCoverageRegions: GqlListAgentsAvailableCoverageRegionsOutput;
  listPendingAppointmentsForAgent: GqlPendingAppointmentsPage;
  listPendingAppointmentUpdatesForAgent: GqlPendingAppointmentUpdatesForAgentOutput;
  properties: GqlPropertiesSearchResult;
  propertiesDiscards: GqlDiscardsResponse;
  property: GqlProperty;
  propetiesFavs: GqlFavsResponse;
  publicationCoverage: PublicationCoverage;
  userInformation?: Maybe<GqlUserOutputType>;
  userInformationByDocument?: Maybe<GqlUserOutputType>;
  userRecommendations?: Maybe<GqlUserRecommendationOutputType>;
};

export type GqlQueryBuildSignedUrlForFileUploadingArgs = {
  bucket: Scalars['String'];
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};

export type GqlQueryCountVisitedAppointmentsByEstateForLastMonthArgs = {
  estateId: Scalars['Int'];
};

export type GqlQueryEstateVisitAvailabilityArgs = {
  date?: InputMaybe<Scalars['Float']>;
  estateId: Scalars['Int'];
};

export type GqlQueryExposedDocumentationArgs = {
  creditStudyId: Scalars['String'];
};

export type GqlQueryExposedEstateArgs = {
  estateId: Scalars['Float'];
};

export type GqlQueryFiltersArgs = {
  filters: GqlEstatesSearchFilterType;
};

export type GqlQueryGetAppointmentsAvailabilityArgs = {
  estateId: Scalars['Int'];
  userId?: InputMaybe<Scalars['Int']>;
};

export type GqlQueryGetAppointmentsByEstateArgs = {
  estateLegalId: Scalars['String'];
};

export type GqlQueryGetAppointmentsByEstateForLastMothArgs = {
  estateLegalId: Scalars['String'];
};

export type GqlQueryGetAppointmentsByTenantArgs = {
  TenantAppointmentsFilter: GqlTenantAppointmentsFilterInputType;
};

export type GqlQueryGetCompletedAppointmentsByTenantArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type GqlQueryGetEstateArgs = {
  estateId: Scalars['Float'];
};

export type GqlQueryGetEstateReferralArgs = {
  estateId: Scalars['Float'];
};

export type GqlQueryGetSearchableEstateLocationArgs = {
  typedLocationName: Scalars['String'];
};

export type GqlQueryGetTenantByPhoneArgs = {
  phoneNumber: Scalars['String'];
};

export type GqlQueryGetVisitFeedbacksByEstateArgs = {
  estateLegalId: Scalars['String'];
};

export type GqlQueryInternalDocumentationArgs = {
  creditStudyId: Scalars['String'];
  userId: Scalars['Float'];
};

export type GqlQueryListPendingAppointmentsForAgentArgs = {
  input: GqlListPendingAppointmentsForAgentInput;
};

export type GqlQueryListPendingAppointmentUpdatesForAgentArgs = {
  input: GqlPendingAppointmentUpdatesForAgentInput;
};

export type GqlQueryPropertiesArgs = {
  filters: GqlEstatesSearchFilterType;
  from?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PropertiesOrderByInput>;
  size?: InputMaybe<Scalars['Int']>;
};

export type GqlQueryPropertiesDiscardsArgs = {
  params?: InputMaybe<Scalars['String']>;
};

export type GqlQueryPropertyArgs = {
  propertyId: Scalars['String'];
};

export type GqlQueryPropetiesFavsArgs = {
  params?: InputMaybe<Scalars['String']>;
};

export type GqlQueryPublicationCoverageArgs = {
  estateId: Scalars['Float'];
};

export type GqlQueryUserInformationArgs = {
  userId: Scalars['Float'];
};

export type GqlQueryUserInformationByDocumentArgs = {
  documentCode: Scalars['String'];
};

export type GqlEstateVisitAvailabilityOutputType = {
  __typename?: 'EstateVisitAvailabilityOutputType';
  availability: Array<GqlDayAvailabilityOutputType>;
  estateId: Scalars['Int'];
  recommended: Array<GqlDayAvailabilityOutputType>;
};

export type GqlDayAvailabilityOutputType = {
  __typename?: 'DayAvailabilityOutputType';
  date: Scalars['Float'];
  slots: Array<GqlSlotAvailabilityOutputType>;
  weekDay: Scalars['String'];
};

export type GqlSlotAvailabilityOutputType = {
  __typename?: 'SlotAvailabilityOutputType';
  confirmed: Scalars['Boolean'];
  date: Scalars['Float'];
  enabled: Scalars['Boolean'];
};

export type GqlExposedDocumentationOutputType = {
  __typename?: 'ExposedDocumentationOutputType';
  creditStudyId: Scalars['String'];
  documents: Array<GqlExposedDocumentOutputType>;
  id: Scalars['String'];
  isComplete: Scalars['Boolean'];
  requiredDocuments: Array<DocumentType>;
  status: DocumentationStatus;
  type: DocumentationType;
  userId: Scalars['Float'];
};

export type GqlExposedDocumentOutputType = {
  __typename?: 'ExposedDocumentOutputType';
  documentationId: Scalars['String'];
  id: Scalars['String'];
  type: DocumentType;
};

export enum DocumentType {
  BANK_STATEMENT = 'BANK_STATEMENT',
  CHAMBER_OF_COMMERCE = 'CHAMBER_OF_COMMERCE',
  DNI = 'DNI',
  EMPLOYMENT_CERTIFICATION = 'EMPLOYMENT_CERTIFICATION',
  INCOME_CERTIFICATION = 'INCOME_CERTIFICATION',
  PENSION_CERTIFICATION = 'PENSION_CERTIFICATION',
  RENT_DECLARATION = 'RENT_DECLARATION',
  RUT = 'RUT',
  SIGNED_FINANCIAL_STATUS = 'SIGNED_FINANCIAL_STATUS',
}

export enum DocumentationStatus {
  APPROVED = 'APPROVED',
  DOCUMENTS_REJECTED = 'DOCUMENTS_REJECTED',
  EXPIRED = 'EXPIRED',
  PRISTINE = 'PRISTINE',
  REVIEW_PENDING = 'REVIEW_PENDING',
}

export enum DocumentationType {
  EMPLOYED = 'EMPLOYED',
  INDEPENDENT = 'INDEPENDENT',
  LEGAL_PERSON = 'LEGAL_PERSON',
  PENSIONER = 'PENSIONER',
  UNKNOWN = 'UNKNOWN',
}

export type GqlExposedEstateOutputType = {
  __typename?: 'ExposedEstateOutputType';
  id: Scalars['Float'];
  legalId: Scalars['String'];
};

export type GqlEstatesSearchFilterType = {
  characteristics?: InputMaybe<Array<InputMaybe<GqlCharacteristicSearchFilter>>>;
  locationId?: InputMaybe<Scalars['Int']>;
  locationSlug?: InputMaybe<Scalars['String']>;
  maxArea?: InputMaybe<Scalars['Int']>;
  maxPrice?: InputMaybe<Scalars['Int']>;
  minArea?: InputMaybe<Scalars['Int']>;
  minBathrooms?: InputMaybe<Scalars['Int']>;
  minGarajes?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['Int']>;
  minRooms?: InputMaybe<Scalars['Int']>;
  propertyAge?: InputMaybe<Scalars['String']>;
  propertyType?: InputMaybe<Scalars['String']>;
  stratum?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type GqlCharacteristicSearchFilter = {
  slug?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type GqlFilters = {
  __typename?: 'Filters';
  aggregations: Array<GqlSearchFilterAggregation>;
  availableLocations: Array<GqlSearchableLocation>;
  currentBranch?: Maybe<Array<GqlSearchableLocation>>;
  pricing: GqlFilterPricing;
  rooms: GqlFilterRooms;
};

export type GqlSearchFilterAggregation = {
  __typename?: 'SearchFilterAggregation';
  aggregation: Scalars['String'];
  count: Scalars['Int'];
};

export type GqlSearchableLocation = {
  __typename?: 'SearchableLocation';
  code: Scalars['String'];
  displayName: Scalars['String'];
  locationType: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  status: Scalars['String'];
};

export type GqlFilterPricing = {
  __typename?: 'FilterPricing';
  options: Array<GqlFilterPricingOptions>;
};

export type GqlFilterPricingOptions = {
  __typename?: 'FilterPricingOptions';
  count: Scalars['Int'];
  name: Scalars['String'];
  value: GqlFilterPricingOptionValue;
};

export type GqlFilterPricingOptionValue = {
  __typename?: 'FilterPricingOptionValue';
  priceMax: Scalars['Int'];
  priceMin?: Maybe<Scalars['Int']>;
};

export type GqlFilterRooms = {
  __typename?: 'FilterRooms';
  options: Array<GqlFilterRoomsOptions>;
};

export type GqlFilterRoomsOptions = {
  __typename?: 'FilterRoomsOptions';
  count: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type GqlAppointmentAvalilabilityOutputType = {
  __typename?: 'AppointmentAvalilabilityOutputType';
  enabled: Scalars['Boolean'];
  isConfirmed: Scalars['Boolean'];
  status: AppointmentStatus;
  type: AppointmentType;
  visitDate: Scalars['Float'];
};

export enum AppointmentStatus {
  BEING_CONFIRMED_BY_LANDLORD = 'BEING_CONFIRMED_BY_LANDLORD',
  BEING_CONFIRMED_BY_VISIT_COMPANIONS = 'BEING_CONFIRMED_BY_VISIT_COMPANIONS',
  CANCELED = 'CANCELED',
  CONFIRMED = 'CONFIRMED',
  CREATED = 'CREATED',
  EXPIRED = 'EXPIRED',
  LANDLORD_CONFIRMED = 'LANDLORD_CONFIRMED',
  VISIT_COMPANION_AGENT_CONFIRMED = 'VISIT_COMPANION_AGENT_CONFIRMED',
  VISITED = 'VISITED',
}

export enum AppointmentType {
  FACE_2_FACE_ALONE = 'FACE_2_FACE_ALONE',
  FACE_2_FACE_MULTIPLE = 'FACE_2_FACE_MULTIPLE',
  VIRTUAL = 'VIRTUAL',
}

export type GqlAppointmentOutputType = {
  __typename?: 'AppointmentOutputType';
  estate: GqlAppointmentEstateOutputType;
  id: Scalars['Int'];
  instructions?: Maybe<Scalars['String']>;
  landlordName?: Maybe<Scalars['String']>;
  landlordPhone?: Maybe<Scalars['String']>;
  status: AppointmentStatus;
  suggestedInstructions?: Maybe<Scalars['String']>;
  tenant: GqlAppointmentUserType;
  type: AppointmentType;
  visitAssistant?: Maybe<GqlAppointmentUserType>;
  visitCompanion?: Maybe<GqlAppointmentUserType>;
  visitDate: Scalars['Float'];
};

export type GqlAppointmentEstateOutputType = {
  __typename?: 'AppointmentEstateOutputType';
  address: Scalars['String'];
  id: Scalars['Int'];
  legalId: Scalars['String'];
  pricing: GqlEstatePricingOutputType;
};

export type GqlEstatePricingOutputType = {
  __typename?: 'EstatePricingOutputType';
  admonPrice: Scalars['Float'];
  currencyISO: Scalars['String'];
  quote: Scalars['Float'];
};

export type GqlAppointmentUserType = {
  __typename?: 'AppointmentUserType';
  email: Scalars['String'];
  firstName: Scalars['String'];
  identityDocumentCode?: Maybe<Scalars['String']>;
  identityDocumentType?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  phoneCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  status: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
};

export type GqlTenantAppointmentsFilterInputType = {
  fromVisitDate?: InputMaybe<Scalars['Float']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  tenantId?: InputMaybe<Scalars['Float']>;
};

export type GqlUserOutputType = {
  __typename?: 'UserOutputType';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identityDocumentCode?: Maybe<Scalars['String']>;
  identityDocumentType?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type GqlCapturingManagerOutputType = {
  __typename?: 'CapturingManagerOutputType';
  email: Scalars['String'];
  firstName: Scalars['String'];
  idCapturingManager?: Maybe<Scalars['Int']>;
  identityDocumentCode?: Maybe<Scalars['String']>;
  identityDocumentType?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  phoneCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
};

export type GqlCompletedAppointmentOutputType = {
  __typename?: 'CompletedAppointmentOutputType';
  estate: GqlAppointmentEstateOutputType;
  id: Scalars['Float'];
  tenantUserId: Scalars['Float'];
  type: Scalars['String'];
  visitCompanionAgent: Scalars['String'];
  visitDate: Scalars['Float'];
};

export type GqlProperty = {
  __typename?: 'Property';
  about: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  administrationPrice: Scalars['Int'];
  businessModel?: Maybe<Scalars['String']>;
  characteristics?: Maybe<Array<GqlPropertyCharacteristic>>;
  currencyIso: Scalars['String'];
  id: Scalars['Int'];
  isExclusive: Scalars['Boolean'];
  isPriceDropped: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  latitude: Scalars['Float'];
  location: GqlLocation;
  locationMaps: Array<GqlPropertyLocationMap>;
  longitude: Scalars['Float'];
  media?: Maybe<Array<GqlPropertyMedia>>;
  price: Scalars['Int'];
  priceElectricity: Scalars['Float'];
  priceGas: Scalars['Float'];
  priceTIC: Scalars['Float'];
  priceWater: Scalars['Float'];
  propertyLegalId?: Maybe<Scalars['String']>;
  propertyType: Scalars['String'];
  recommendations?: Maybe<GqlEstateRecommendationType>;
  status?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userDiscard?: Maybe<Scalars['Boolean']>;
  userFav?: Maybe<Scalars['Boolean']>;
};

export type GqlPropertyCharacteristic = {
  __typename?: 'PropertyCharacteristic';
  category?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  withValue?: Maybe<Scalars['Boolean']>;
};

export type GqlLocation = {
  __typename?: 'Location';
  code: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
};

/** Property location static map url */
export type GqlPropertyLocationMap = {
  __typename?: 'PropertyLocationMap';
  size: Scalars['String'];
  url: Scalars['String'];
};

export type GqlPropertyMedia = {
  __typename?: 'PropertyMedia';
  id: Scalars['Int'];
  main: Scalars['Boolean'];
  uri: Scalars['String'];
};

export type GqlEstateRecommendationType = {
  __typename?: 'EstateRecommendationType';
  calculationMethod: GqlCalculationMethodType;
  kind: Scalars['String'];
  recommendedEstates: Array<GqlRecommendedEstateType>;
};

export type GqlCalculationMethodType = {
  __typename?: 'CalculationMethodType';
  method: Scalars['String'];
  version: Scalars['String'];
};

export type GqlRecommendedEstateType = {
  __typename?: 'RecommendedEstateType';
  administrationPrice: Scalars['Int'];
  area: Scalars['Float'];
  bathrooms: Scalars['Int'];
  currencyISO: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  isAnewProperty: Scalars['Boolean'];
  isFurnished: Scalars['Boolean'];
  isInHighDemand: Scalars['Boolean'];
  isPriceDropped: Scalars['Boolean'];
  legalId: Scalars['String'];
  price: Scalars['Int'];
  propertyType: Scalars['String'];
  ranking: GqlRankingType;
  rooms: Scalars['Int'];
  status: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type GqlRankingType = {
  __typename?: 'RankingType';
  calculatedRank: Scalars['Float'];
  obtainedScoring: Scalars['Float'];
};

export type GqlGeneralEstatePublicationQualityOutputType = {
  __typename?: 'GeneralEstatePublicationQualityOutputType';
  addressFormat: Scalars['String'];
  amountOfPictures: GqlComparableQualityIndexOutputType;
  descriptionLength: GqlComparableQualityIndexOutputType;
};

export type GqlComparableQualityIndexOutputType = {
  __typename?: 'ComparableQualityIndexOutputType';
  allowedDeviation: Scalars['Float'];
  value: Scalars['Float'];
};

export type GqlPropertyBrief = {
  __typename?: 'PropertyBrief';
  administrationPrice?: Maybe<Scalars['Int']>;
  area?: Maybe<Scalars['Int']>;
  bathrooms: Scalars['Int'];
  currencyIso: Scalars['String'];
  garajes: Scalars['Int'];
  hasBalcony: Scalars['Boolean'];
  hasTerrace: Scalars['Boolean'];
  highDemand: Scalars['Boolean'];
  id: Scalars['Int'];
  images: Array<GqlPropertyMedia>;
  isExclusive: Scalars['Boolean'];
  isFurnished: Scalars['Boolean'];
  isPriceDropped: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  newProperty?: Maybe<Scalars['Boolean']>;
  price: Scalars['Int'];
  propertyLegalId: Scalars['String'];
  propertyType: Scalars['String'];
  rooms: Scalars['Int'];
  status: Scalars['String'];
  stratum?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  userFav: Scalars['Boolean'];
};

export type GqlSearchableEstateLocationOutputType = {
  __typename?: 'SearchableEstateLocationOutputType';
  locationId: Scalars['Int'];
  locationName: Scalars['String'];
  locationNameLong: Scalars['String'];
  locationOrder: Scalars['Int'];
  locationSlug: Scalars['String'];
  locationType: EstateLocationType;
  parents: Array<GqlEstateLocationParentsOutputType>;
};

export enum EstateLocationType {
  COUNTRY = 'COUNTRY',
  LOCATION_LEVEL_10 = 'LOCATION_LEVEL_10',
  LOCATION_LEVEL_11 = 'LOCATION_LEVEL_11',
  LOCATION_LEVEL_12 = 'LOCATION_LEVEL_12',
  LOCATION_LEVEL_13 = 'LOCATION_LEVEL_13',
  LOCATION_LEVEL_14 = 'LOCATION_LEVEL_14',
  LOCATION_LEVEL_15 = 'LOCATION_LEVEL_15',
  LOCATION_LEVEL_16 = 'LOCATION_LEVEL_16',
  LOCATION_LEVEL_17 = 'LOCATION_LEVEL_17',
  LOCATION_LEVEL_19 = 'LOCATION_LEVEL_19',
  LOCATION_LEVEL_2 = 'LOCATION_LEVEL_2',
  LOCATION_LEVEL_20 = 'LOCATION_LEVEL_20',
  LOCATION_LEVEL_3 = 'LOCATION_LEVEL_3',
  LOCATION_LEVEL_4 = 'LOCATION_LEVEL_4',
  LOCATION_LEVEL_5 = 'LOCATION_LEVEL_5',
  LOCATION_LEVEL_6 = 'LOCATION_LEVEL_6',
  LOCATION_LEVEL_7 = 'LOCATION_LEVEL_7',
  LOCATION_LEVEL_8 = 'LOCATION_LEVEL_8',
  LOCATION_LEVEL_9 = 'LOCATION_LEVEL_9',
  NEIGHBORHOOD = 'NEIGHBORHOOD',
}

export type GqlEstateLocationParentsOutputType = {
  __typename?: 'EstateLocationParentsOutputType';
  locationId: Scalars['Int'];
  locationName: Scalars['String'];
  locationSlug: Scalars['String'];
};

export type GqlTenantOutputType = {
  __typename?: 'TenantOutputType';
  email?: Maybe<Scalars['String']>;
  estimatedMoveDate?: Maybe<EstimatedMoveDate>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  identityDocumentCode?: Maybe<Scalars['String']>;
  identityDocumentType?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  status: Scalars['String'];
  userId: Scalars['Int'];
};

export enum EstimatedMoveDate {
  AS_SOON_AS_POSSIBLE = 'AS_SOON_AS_POSSIBLE',
  DONT_KNOW = 'DONT_KNOW',
  NEXT_MONTH = 'NEXT_MONTH',
  NEXT_SEVEN_DAYS = 'NEXT_SEVEN_DAYS',
}

export type GqlFullEstateType = {
  __typename?: 'FullEstateType';
  address: Scalars['String'];
  addressDetails?: Maybe<Scalars['String']>;
  appointmentEstateInformation?: Maybe<GqlAppointmentEstateType>;
  characteristics: GqlEstateCharacteristicsType;
  description: Scalars['String'];
  id: Scalars['Float'];
  isExclusive: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  latitude: Scalars['Float'];
  legalId?: Maybe<Scalars['String']>;
  location: GqlLocationType;
  longitude: Scalars['Float'];
  media: Array<GqlEstateMediaType>;
  pricing: GqlPriceType;
  status: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type GqlAppointmentEstateType = {
  __typename?: 'AppointmentEstateType';
  baseVisitConsiderations?: Maybe<Scalars['String']>;
  isLandlordConfirmationNeeded: Scalars['Boolean'];
};

export type GqlEstateCharacteristicsType = {
  __typename?: 'EstateCharacteristicsType';
  allowPets: Scalars['Boolean'];
  allowPriceNegociation: Scalars['Boolean'];
  amountOfBathrooms?: Maybe<Scalars['Int']>;
  amountOfBedrooms?: Maybe<Scalars['Int']>;
  amountOfClosets?: Maybe<Scalars['Int']>;
  amountOfElevators?: Maybe<Scalars['Int']>;
  amountOfFloors?: Maybe<Scalars['Int']>;
  amountOfParkings?: Maybe<Scalars['Int']>;
  antiqueness?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['Float']>;
  builtArea?: Maybe<Scalars['Float']>;
  canAccessPersonsWithReducedMobility: Scalars['Boolean'];
  curtains?: Maybe<Scalars['String']>;
  deposit: Scalars['Boolean'];
  hasAlarm: Scalars['Boolean'];
  hasBalcony: Scalars['Boolean'];
  hasBasketField: Scalars['Boolean'];
  hasBbqZones: Scalars['Boolean'];
  hasChildrenZones: Scalars['Boolean'];
  hasCommonLaundryZones: Scalars['Boolean'];
  hasComunalLiving: Scalars['Boolean'];
  hasElevator: Scalars['Boolean'];
  hasFirePlace: Scalars['Boolean'];
  hasGarden: Scalars['Boolean'];
  hasGreenZones: Scalars['Boolean'];
  hasGym: Scalars['Boolean'];
  hasHall: Scalars['Boolean'];
  hasHeating: Scalars['Boolean'];
  hasIndoorGarden: Scalars['Boolean'];
  hasIndoorParking: Scalars['Boolean'];
  hasInterphone: Scalars['Boolean'];
  hasJacuzzi: Scalars['Boolean'];
  hasLaundryZones: Scalars['Boolean'];
  hasNaturalGas: Scalars['Boolean'];
  hasPanoramicView: Scalars['Boolean'];
  hasSafetyDoor: Scalars['Boolean'];
  hasSauna: Scalars['Boolean'];
  hasServiceRoom: Scalars['Boolean'];
  hasSoccerField: Scalars['Boolean'];
  hasSquashField: Scalars['Boolean'];
  hasStudy: Scalars['Boolean'];
  hasSwimmingPool: Scalars['Boolean'];
  hasTennisField: Scalars['Boolean'];
  hasTerrace: Scalars['Boolean'];
  hasTvSurveillance: Scalars['Boolean'];
  hasYard: Scalars['Boolean'];
  internalNumber?: Maybe<Scalars['Int']>;
  isAclosedUnit: Scalars['Boolean'];
  isFurnished: Scalars['Boolean'];
  isHighDemand: Scalars['Boolean'];
  isMallNearby: Scalars['Boolean'];
  isNearbyPrincipalWay: Scalars['Boolean'];
  isNearbySecondaryWay: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  isParkNearby: Scalars['Boolean'];
  isSchoolNearby: Scalars['Boolean'];
  isSupermarketNearby: Scalars['Boolean'];
  isTransportNearby: Scalars['Boolean'];
  kindOfEstate?: Maybe<Scalars['String']>;
  kindOfHeating?: Maybe<Scalars['String']>;
  kindOfKitchen?: Maybe<Scalars['String']>;
  kindOfParking?: Maybe<Scalars['String']>;
  kindOfTerraceOrBalcony?: Maybe<Scalars['String']>;
  large?: Maybe<Scalars['Float']>;
  naturalGas?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  numberOfApartment?: Maybe<Scalars['Int']>;
  numberOfFloor?: Maybe<Scalars['Int']>;
  petsAllowed?: Maybe<Scalars['String']>;
  stratum?: Maybe<Scalars['String']>;
  terraceArea?: Maybe<Scalars['Float']>;
  videoUrl?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['String']>;
  whatsappContact?: Maybe<Scalars['String']>;
};

export type GqlLocationType = {
  __typename?: 'LocationType';
  code: Scalars['String'];
  fincaraizId: Scalars['String'];
  id: Scalars['Int'];
  latitude: Scalars['Float'];
  locationType: Scalars['String'];
  longitude: Scalars['Float'];
  metrocuadradoId: Scalars['String'];
  name: Scalars['String'];
  parentId: Scalars['Int'];
  path: Scalars['String'];
  searchable: Scalars['Boolean'];
  slug: Scalars['String'];
  status: Scalars['String'];
};

export type GqlEstateMediaType = {
  __typename?: 'EstateMediaType';
  format: Scalars['String'];
  id: Scalars['Int'];
  isPrincipal: Scalars['Boolean'];
  uri: Scalars['String'];
};

export type GqlPriceType = {
  __typename?: 'PriceType';
  administrationPrice: Scalars['Float'];
  currency: Scalars['String'];
  price: Scalars['Float'];
};

export type GqlVisitCompanionOutputType = {
  __typename?: 'VisitCompanionOutputType';
  email: Scalars['String'];
  firstName: Scalars['String'];
  identityDocumentCode?: Maybe<Scalars['String']>;
  identityDocumentType?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  phoneCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  role?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

export type GqlVisitFeedbackOutputType = {
  __typename?: 'VisitFeedbackOutputType';
  cleanliness: Scalars['Float'];
  customerOpinion: Scalars['String'];
  stateSpace: Scalars['Float'];
  tenantFullName: Scalars['String'];
  visitDate: Scalars['Float'];
};

export type GqlDocumentationOutputType = {
  __typename?: 'DocumentationOutputType';
  creditStudyId: Scalars['String'];
  documents: Array<GqlDocumentOutputType>;
  id: Scalars['String'];
  isComplete: Scalars['Boolean'];
  requiredDocuments: Array<DocumentType>;
  status: DocumentationStatus;
  type: DocumentationType;
  userId: Scalars['Float'];
};

export type GqlDocumentOutputType = {
  __typename?: 'DocumentOutputType';
  documentationId: Scalars['String'];
  id: Scalars['String'];
  type: DocumentType;
  uri: Scalars['String'];
};

export type GqlListAgentsAvailableCoverageRegionsOutput = {
  __typename?: 'ListAgentsAvailableCoverageRegionsOutput';
  regions: Array<GqlAgentsCoverageRegion>;
};

export type GqlAgentsCoverageRegion = {
  __typename?: 'AgentsCoverageRegion';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type GqlListPendingAppointmentsForAgentInput = {
  geoQueryType?: InputMaybe<Scalars['String']>;
  lastToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['String']>;
};

export type GqlPendingAppointmentsPage = {
  __typename?: 'PendingAppointmentsPage';
  appointments: Array<GqlPendingAppointment>;
  nextToken?: Maybe<Scalars['String']>;
  totalCount: Scalars['Float'];
};

export type GqlPendingAppointment = {
  __typename?: 'PendingAppointment';
  estateAddress: Scalars['String'];
  estateId: Scalars['Float'];
  id: Scalars['Int'];
  type: AppointmentType;
  visitDate: Scalars['LocalDateTime'];
  wasEstateReferredByAgent: Scalars['Boolean'];
};

export type GqlPendingAppointmentUpdatesForAgentInput = {
  geoQueryType?: InputMaybe<Scalars['String']>;
};

export type GqlPendingAppointmentUpdatesForAgentOutput = {
  __typename?: 'PendingAppointmentUpdatesForAgentOutput';
  cancelled: Array<Scalars['Float']>;
  confirmed: Array<Scalars['Float']>;
  incoming: Array<GqlPendingAppointment>;
};

export enum PropertiesOrderByInput {
  age_ASC = 'age_ASC',
  age_DESC = 'age_DESC',
  price_ASC = 'price_ASC',
  price_DESC = 'price_DESC',
  relevant = 'relevant',
}

export type GqlPropertiesSearchResult = {
  __typename?: 'PropertiesSearchResult';
  avgPrice: Scalars['Int'];
  count: Scalars['Int'];
  values: Array<GqlPropertyBrief>;
};

export type GqlDiscardsResponse = {
  __typename?: 'DiscardsResponse';
  count: Scalars['Int'];
  discards: Array<GqlUserPropertyDiscard>;
  error: Scalars['Boolean'];
  message: Scalars['String'];
};

export type GqlUserPropertyDiscard = {
  __typename?: 'UserPropertyDiscard';
  property: GqlProperty;
};

export type GqlFavsResponse = {
  __typename?: 'FavsResponse';
  count?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['Boolean']>;
  favs?: Maybe<Array<GqlUserPropertyFav>>;
  message?: Maybe<Scalars['String']>;
};

export type GqlUserPropertyFav = {
  __typename?: 'UserPropertyFav';
  property: GqlProperty;
};

export enum PublicationCoverage {
  COVERED = 'COVERED',
  NOT_COVERED = 'NOT_COVERED',
  UNKNOWN = 'UNKNOWN',
}

export type GqlUserRecommendationOutputType = {
  __typename?: 'UserRecommendationOutputType';
  recommendationsByCategory: Array<GqlRecommendationsByCategoryOutputType>;
};

export type GqlRecommendationsByCategoryOutputType = {
  __typename?: 'RecommendationsByCategoryOutputType';
  category: GqlCategoryOutputType;
  estates: Array<GqlRecommendationEstateOutputType>;
};

export type GqlCategoryOutputType = {
  __typename?: 'CategoryOutputType';
  code: Scalars['String'];
  description: Scalars['String'];
};

export type GqlRecommendationEstateOutputType = {
  __typename?: 'RecommendationEstateOutputType';
  admonPrice: Scalars['Float'];
  area: Scalars['Float'];
  bathrooms: Scalars['Float'];
  bedrooms: Scalars['Float'];
  estateId: Scalars['Float'];
  estateLegalId: Scalars['String'];
  imagesUrl: Array<Scalars['String']>;
  price: Scalars['Float'];
  ranking: Scalars['Float'];
  title: Scalars['String'];
};

export type GqlMutation = {
  __typename?: 'Mutation';
  acceptTermsAndConditions: GqlTermsAndConditionsType;
  addDocument: GqlCommandResponseOutputType;
  applyForRentAdvance: Scalars['Boolean'];
  approveApplicationDocumentation: GqlCommandResponseOutputType;
  authenticate: GqlLoginResponseOutputType;
  cancelAppointment: GqlAppointmentOutputType;
  cancelAppointmentByOps: GqlAppointmentOutputType;
  confirmAppointment: Array<GqlAppointmentOutputType>;
  confirmAppointmentByVisitCompanionAgent: Array<GqlAppointmentOutputType>;
  contact: Scalars['Boolean'];
  createApplicationDocumentation: GqlCommandResponseOutputType;
  createAppointment: GqlAppointmentOutputType;
  createAppointmentDemandDeal: Scalars['Float'];
  createAppointmentEstate: Scalars['Float'];
  createB2bDeal: Scalars['String'];
  createB2bEstate: Scalars['Int'];
  createLandlord: GqlLandlordType;
  deleteCapturingAgent: Scalars['Boolean'];
  deleteCapturingManager: Scalars['Boolean'];
  deleteDocument: GqlCommandResponseOutputType;
  deletePhoto: Scalars['Boolean'];
  desistSupplyDealUserIsTenant: Scalars['Int'];
  editEstatePublication: Scalars['Int'];
  giveEstateKeys: Scalars['Int'];
  landlordConfirmAppointment: Array<GqlAppointmentOutputType>;
  landlordConfirmAppointmentByOps: Array<GqlAppointmentOutputType>;
  prequalifySupplyDealBasedOnUserIntent: Scalars['Boolean'];
  propertyApplication: Scalars['Boolean'];
  propertyDiscard: Scalars['Boolean'];
  propertyFav: Scalars['Boolean'];
  propertyUndiscard: Scalars['Boolean'];
  propertyUnfav: Scalars['Boolean'];
  prospectSupplyDeal: GqlCreateDealSupplyOutputType;
  referral: Scalars['Boolean'];
  registerEstatePublication: Scalars['Int'];
  rejectApplicationDocuments: GqlCommandResponseOutputType;
  removeVisitCompanion: Scalars['Boolean'];
  replaceDocument: GqlCommandResponseOutputType;
  reportWebError: GqlCommandResponseOutputType;
  republishEstate: Scalars['Boolean'];
  rescheduleAppointment: GqlAppointmentOutputType;
  restrictAllEstates: Scalars['Boolean'];
  restrictOneEstate: Scalars['Boolean'];
  saveBenefits: Scalars['Int'];
  saveCapturingAgent: GqlCapturingAgentOutputType;
  saveCapturingAgentDocument: GqlCapturingAgentDocumentOutputType;
  saveCapturingManager: GqlCapturingManagerOutputType;
  saveSupplyDealReferral: Scalars['Int'];
  saveVisitCompanion: GqlVisitCompanionOutputType;
  signUpPhone: Scalars['Boolean'];
  signUpPhoneCode: GqlLoginResponseOutputType;
  specifyApplicationDocumentationType: GqlCommandResponseOutputType;
  unpublishEstate: Scalars['Boolean'];
  updateTenant: GqlTenantOutputType;
  updateUserInfo: GqlCommandResponseOutputType;
  uploadB2bFile: GqlOutputPhoto;
  uploadFile: GqlOutputPhoto;
  userEdit: Scalars['Boolean'];
};

export type GqlMutationAcceptTermsAndConditionsArgs = {
  termsAndConditions: GqlTermsAndConditionsInputType;
  userId?: InputMaybe<Scalars['Int']>;
};

export type GqlMutationAddDocumentArgs = {
  creditStudyId: Scalars['String'];
  documentType: Scalars['String'];
  file: Scalars['Upload'];
};

export type GqlMutationApplyForRentAdvanceArgs = {
  landlord: GqlLandlordInputType;
};

export type GqlMutationApproveApplicationDocumentationArgs = {
  documentationId: Scalars['String'];
};

export type GqlMutationAuthenticateArgs = {
  password: Scalars['String'];
  userName: Scalars['String'];
};

export type GqlMutationCancelAppointmentArgs = {
  appointmentId: Scalars['Int'];
  cancelationReason: GqlAppointmentCancelationReasonInputType;
};

export type GqlMutationCancelAppointmentByOpsArgs = {
  appointmentId: Scalars['Int'];
  cancelationReason: GqlAppointmentCancelationReasonInputType;
};

export type GqlMutationConfirmAppointmentArgs = {
  appointmentId: Scalars['Int'];
  instructions?: InputMaybe<Scalars['String']>;
  newVisitDate: Scalars['Float'];
  visitCompanionId: Scalars['Int'];
};

export type GqlMutationConfirmAppointmentByVisitCompanionAgentArgs = {
  appointmentId: Scalars['Int'];
};

export type GqlMutationContactArgs = {
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type GqlMutationCreateApplicationDocumentationArgs = {
  creditStudyId: Scalars['String'];
};

export type GqlMutationCreateAppointmentArgs = {
  appointment: GqlCreateAppointmentInputType;
};

export type GqlMutationCreateAppointmentDemandDealArgs = {
  input: GqlCreateAppointmentDealInputType;
};

export type GqlMutationCreateAppointmentEstateArgs = {
  input: GqlCreateAppointmentEstateType;
};

export type GqlMutationCreateB2bDealArgs = {
  b2bDeal: GqlB2bLandlordType;
};

export type GqlMutationCreateB2bEstateArgs = {
  estate: GqlEstateInput;
  userId: Scalars['Int'];
};

export type GqlMutationCreateLandlordArgs = {
  newLandlord: GqlLandlordInputType;
};

export type GqlMutationDeleteCapturingAgentArgs = {
  userId: Scalars['Int'];
};

export type GqlMutationDeleteCapturingManagerArgs = {
  userId: Scalars['Int'];
};

export type GqlMutationDeleteDocumentArgs = {
  creditStudyId: Scalars['String'];
  documentId: Scalars['String'];
};

export type GqlMutationDeletePhotoArgs = {
  estateId: Scalars['Int'];
  photoId: Scalars['Int'];
  userId?: InputMaybe<Scalars['Int']>;
};

export type GqlMutationEditEstatePublicationArgs = {
  estate: GqlEstateInput;
  landlordUserId?: InputMaybe<Scalars['Int']>;
};

export type GqlMutationGiveEstateKeysArgs = {
  estateId: Scalars['Float'];
};

export type GqlMutationLandlordConfirmAppointmentArgs = {
  appointmentId: Scalars['Int'];
  instructions?: InputMaybe<Scalars['String']>;
};

export type GqlMutationLandlordConfirmAppointmentByOpsArgs = {
  appointmentId: Scalars['Int'];
  instructions?: InputMaybe<Scalars['String']>;
};

export type GqlMutationPrequalifySupplyDealBasedOnUserIntentArgs = {
  estateType: EstatePublicationType;
  landlordUserId?: InputMaybe<Scalars['Float']>;
  sellOrRentIntention: LandlordPublishingIntention;
};

export type GqlMutationPropertyApplicationArgs = {
  propertyId: Scalars['Int'];
};

export type GqlMutationPropertyDiscardArgs = {
  propertyId: Scalars['Int'];
};

export type GqlMutationPropertyFavArgs = {
  propertyId: Scalars['Int'];
};

export type GqlMutationPropertyUndiscardArgs = {
  propertyId: Scalars['Int'];
};

export type GqlMutationPropertyUnfavArgs = {
  propertyId: Scalars['Int'];
};

export type GqlMutationProspectSupplyDealArgs = {
  dealProperties: GqlCreateDealType;
  landlordUserId?: InputMaybe<Scalars['Float']>;
};

export type GqlMutationReferralArgs = {
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type GqlMutationRegisterEstatePublicationArgs = {
  estate: GqlEstateInput;
  landlordUserId?: InputMaybe<Scalars['Int']>;
};

export type GqlMutationRejectApplicationDocumentsArgs = {
  input: GqlRejectDocumentsInput;
};

export type GqlMutationRemoveVisitCompanionArgs = {
  userId: Scalars['Int'];
};

export type GqlMutationReplaceDocumentArgs = {
  creditStudyId: Scalars['String'];
  documentId: Scalars['String'];
  file: Scalars['Upload'];
};

export type GqlMutationReportWebErrorArgs = {
  error: GqlReportableErrorGqlType;
};

export type GqlMutationRepublishEstateArgs = {
  estateId: Scalars['Int'];
};

export type GqlMutationRescheduleAppointmentArgs = {
  appointmentId: Scalars['Int'];
  appointmentType: AppointmentType;
  newVisitAssistant?: InputMaybe<GqlAppointmentUserInputType>;
  newVisitDate: Scalars['Float'];
};

export type GqlMutationRestrictAllEstatesArgs = {
  availavility: Array<GqlSlotAvailabilityInputType>;
};

export type GqlMutationRestrictOneEstateArgs = {
  estateAvailavility: GqlEstateVisitAvailabilityInputType;
};

export type GqlMutationSaveBenefitsArgs = {
  benefits: GqlBenefitsInputType;
};

export type GqlMutationSaveCapturingAgentArgs = {
  capturingAgent: GqlCapturingAgentInputType;
};

export type GqlMutationSaveCapturingAgentDocumentArgs = {
  capturingAgentDocument: GqlCapturingAgentDocumentInputType;
};

export type GqlMutationSaveCapturingManagerArgs = {
  capturingManager: GqlCapturingManagerInputType;
};

export type GqlMutationSaveSupplyDealReferralArgs = {
  estateId: Scalars['Float'];
  referralType: Scalars['String'];
  referringUserId: Scalars['Float'];
};

export type GqlMutationSaveVisitCompanionArgs = {
  visitCompanion: GqlVisitCompanionInputType;
};

export type GqlMutationSignUpPhoneArgs = {
  authChannel: AuthChannel;
  number: Scalars['String'];
};

export type GqlMutationSignUpPhoneCodeArgs = {
  anonymousSessionId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  number: Scalars['String'];
  userType: UserType;
};

export type GqlMutationSpecifyApplicationDocumentationTypeArgs = {
  declaresRent: Scalars['Boolean'];
  documentation: Scalars['String'];
  employmentSituation: Scalars['String'];
};

export type GqlMutationUnpublishEstateArgs = {
  estateId: Scalars['Int'];
  unpublishReason: Scalars['String'];
};

export type GqlMutationUpdateTenantArgs = {
  tenant: GqlTenantInputType;
};

export type GqlMutationUpdateUserInfoArgs = {
  user: GqlUpdateUserInputType;
};

export type GqlMutationUploadB2bFileArgs = {
  estateId: Scalars['Int'];
  file: Scalars['Upload'];
  userId: Scalars['Int'];
};

export type GqlMutationUploadFileArgs = {
  estateId: Scalars['Int'];
  file: Scalars['Upload'];
  userId?: InputMaybe<Scalars['Int']>;
};

export type GqlMutationUserEditArgs = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  identityDocumentCode?: InputMaybe<Scalars['String']>;
  identityDocumentType?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type GqlTermsAndConditionsInputType = {
  estateId: Scalars['Int'];
  isExclusive: Scalars['Boolean'];
  type: TermsAndConditionsTypeEnum;
};

export enum TermsAndConditionsTypeEnum {
  BLUE = 'BLUE',
  BROKERAGE = 'BROKERAGE',
  PLUS = 'PLUS',
  WARRANTY = 'WARRANTY',
}

export type GqlTermsAndConditionsType = {
  __typename?: 'TermsAndConditionsType';
  estateId: Scalars['Int'];
  id: Scalars['Int'];
  isExclusive: Scalars['Boolean'];
  type: Scalars['String'];
};

export type GqlCommandResponseOutputType = {
  __typename?: 'CommandResponseOutputType';
  value: Scalars['String'];
};

export type GqlLandlordInputType = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  identityDocumentCode: Scalars['String'];
  identityDocumentType: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type GqlLoginResponseOutputType = {
  __typename?: 'LoginResponseOutputType';
  token?: Maybe<Scalars['String']>;
  userData?: Maybe<GqlUserOutputType>;
};

export type GqlAppointmentCancelationReasonInputType = {
  code: AppointmentCancelationReasonCode;
  description?: InputMaybe<Scalars['String']>;
};

export enum AppointmentCancelationReasonCode {
  AGENTS_AVAILABILITY = 'AGENTS_AVAILABILITY',
  APPOINTMENT_RESCHEDULED = 'APPOINTMENT_RESCHEDULED',
  DESISTED_DEAL = 'DESISTED_DEAL',
  ESTATE_RESERVED = 'ESTATE_RESERVED',
  ESTATE_UNAVAILABLE_AT_VISIT_DATE = 'ESTATE_UNAVAILABLE_AT_VISIT_DATE',
  LANDLORD_AVAILABILITY = 'LANDLORD_AVAILABILITY',
  LANDLORD_COULD_NOT_BE_CONECTED = 'LANDLORD_COULD_NOT_BE_CONECTED',
  LANDLORD_IS_EVALUATING_A_TENANT = 'LANDLORD_IS_EVALUATING_A_TENANT',
  LANDLORD_STILL_NOT_ACCEPT_TERMS_AND_CONDITIONS = 'LANDLORD_STILL_NOT_ACCEPT_TERMS_AND_CONDITIONS',
  MAX_PENDING_TIME_REACHED = 'MAX_PENDING_TIME_REACHED',
  OTHER = 'OTHER',
  REQUESTED_BY_TENANT = 'REQUESTED_BY_TENANT',
  TENANT_AVAILABILITY = 'TENANT_AVAILABILITY',
  TENANT_DID_NOT_ANSWERED = 'TENANT_DID_NOT_ANSWERED',
}

export type GqlCreateAppointmentInputType = {
  estateId: Scalars['Int'];
  tenant: Scalars['Int'];
  type: AppointmentType;
  visitAssistant?: InputMaybe<GqlAppointmentUserInputType>;
  visitDate: Scalars['Float'];
};

export type GqlAppointmentUserInputType = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  identityDocumentCode: Scalars['String'];
  identityDocumentType: Scalars['String'];
  lastName: Scalars['String'];
  phoneCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  userId?: InputMaybe<Scalars['Int']>;
};

export type GqlCreateAppointmentDealInputType = {
  estateId: Scalars['String'];
};

export type GqlCreateAppointmentEstateType = {
  baseVisitConsiderations?: InputMaybe<Scalars['String']>;
  estateId: Scalars['Float'];
  isLandlordConfirmationNeeded: Scalars['Boolean'];
};

export type GqlB2bLandlordType = {
  assetAcount: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  landlordId: Scalars['Int'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type GqlEstateInput = {
  about: Scalars['String'];
  address: Scalars['String'];
  addressDetails?: InputMaybe<Scalars['String']>;
  administrationPrice?: InputMaybe<Scalars['Int']>;
  appointmentEstateInformation?: InputMaybe<GqlAppointmentEstateInput>;
  benefitsId?: InputMaybe<Scalars['Int']>;
  businessModel?: InputMaybe<BusinessModel>;
  characteristics?: InputMaybe<GqlEstateCharacteristic>;
  currency: CurrencyType;
  estateType: EstateType;
  id?: InputMaybe<Scalars['Int']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  origin?: InputMaybe<EstateOriginType>;
  price: Scalars['Int'];
  status?: InputMaybe<EstateStatus>;
};

export type GqlAppointmentEstateInput = {
  baseVisitConsiderations?: InputMaybe<Scalars['String']>;
  isLandlordConfirmationNeeded: Scalars['Boolean'];
};

export enum BusinessModel {
  B2B = 'B2B',
  B2C = 'B2C',
}

export type GqlEstateCharacteristic = {
  allowPets?: InputMaybe<Scalars['Boolean']>;
  allowPriceNegociation?: InputMaybe<Scalars['Boolean']>;
  amountOfBathrooms: Scalars['Int'];
  amountOfBedrooms: Scalars['Int'];
  amountOfClosets?: InputMaybe<Scalars['Int']>;
  amountOfElevators?: InputMaybe<Scalars['Float']>;
  amountOfFloors?: InputMaybe<Scalars['Int']>;
  amountOfParkings: Scalars['Int'];
  antiqueness?: InputMaybe<Scalars['String']>;
  area: Scalars['Float'];
  builtArea?: InputMaybe<Scalars['Float']>;
  canAccessPersonsWithReducedMobility?: InputMaybe<Scalars['Boolean']>;
  curtains?: InputMaybe<Scalars['String']>;
  deposit?: InputMaybe<Scalars['Boolean']>;
  hasAlarm?: InputMaybe<Scalars['Boolean']>;
  hasBalcony?: InputMaybe<Scalars['Boolean']>;
  hasBasketField?: InputMaybe<Scalars['Boolean']>;
  hasBbqZones?: InputMaybe<Scalars['Boolean']>;
  hasChildrenZones?: InputMaybe<Scalars['Boolean']>;
  hasCommonLaundryZones?: InputMaybe<Scalars['Boolean']>;
  hasComunalLiving?: InputMaybe<Scalars['Boolean']>;
  hasElevator?: InputMaybe<Scalars['Boolean']>;
  hasFirePlace?: InputMaybe<Scalars['Boolean']>;
  hasGarden?: InputMaybe<Scalars['Boolean']>;
  hasGreenZones?: InputMaybe<Scalars['Boolean']>;
  hasGym?: InputMaybe<Scalars['Boolean']>;
  hasHall?: InputMaybe<Scalars['Boolean']>;
  hasHeating?: InputMaybe<Scalars['Boolean']>;
  hasIndoorGarden?: InputMaybe<Scalars['Boolean']>;
  hasIndoorParking?: InputMaybe<Scalars['Boolean']>;
  hasInterphone?: InputMaybe<Scalars['Boolean']>;
  hasJacuzzi?: InputMaybe<Scalars['Boolean']>;
  hasLaundryZones?: InputMaybe<Scalars['Boolean']>;
  hasNaturalGas?: InputMaybe<Scalars['Boolean']>;
  hasPanoramicView?: InputMaybe<Scalars['Boolean']>;
  hasSafetyDoor?: InputMaybe<Scalars['Boolean']>;
  hasSauna?: InputMaybe<Scalars['Boolean']>;
  hasServiceRoom?: InputMaybe<Scalars['Boolean']>;
  hasSoccerField?: InputMaybe<Scalars['Boolean']>;
  hasSquashField?: InputMaybe<Scalars['Boolean']>;
  hasStudy?: InputMaybe<Scalars['Boolean']>;
  hasSwimmingPool?: InputMaybe<Scalars['Boolean']>;
  hasTennisField?: InputMaybe<Scalars['Boolean']>;
  hasTerrace?: InputMaybe<Scalars['Boolean']>;
  hasTvSurveillance?: InputMaybe<Scalars['Boolean']>;
  hasYard?: InputMaybe<Scalars['Boolean']>;
  internalNumber?: InputMaybe<Scalars['Int']>;
  isAclosedUnit?: InputMaybe<Scalars['Boolean']>;
  isFurnished?: InputMaybe<Scalars['Boolean']>;
  isHighDemand?: InputMaybe<Scalars['Boolean']>;
  isMallNearby?: InputMaybe<Scalars['Boolean']>;
  isNearbyPrincipalWay?: InputMaybe<Scalars['Boolean']>;
  isNearbySecondaryWay?: InputMaybe<Scalars['Boolean']>;
  isNew?: InputMaybe<Scalars['Boolean']>;
  isParkNearby?: InputMaybe<Scalars['Boolean']>;
  isSchoolNearby?: InputMaybe<Scalars['Boolean']>;
  isSupermarketNearby?: InputMaybe<Scalars['Boolean']>;
  isTransportNearby?: InputMaybe<Scalars['Boolean']>;
  kindNaturalGas?: InputMaybe<Scalars['String']>;
  kindOfEstate?: InputMaybe<Scalars['String']>;
  kindOfHeating?: InputMaybe<Scalars['String']>;
  kindOfKitchen?: InputMaybe<Scalars['String']>;
  kindOfParking?: InputMaybe<Scalars['String']>;
  kindOfTerraceOrBalcony?: InputMaybe<Scalars['String']>;
  large?: InputMaybe<Scalars['Float']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  numberOfApartment?: InputMaybe<Scalars['Int']>;
  numberOfFloor?: InputMaybe<Scalars['Int']>;
  petsAllowed?: InputMaybe<Scalars['String']>;
  stratum: Scalars['Int'];
  terraceArea?: InputMaybe<Scalars['Float']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  view?: InputMaybe<Scalars['String']>;
  whatsappContact?: InputMaybe<Scalars['String']>;
};

export enum CurrencyType {
  ARS = 'ARS',
  COP = 'COP',
  EUR = 'EUR',
  MXN = 'MXN',
  USD = 'USD',
}

export enum EstateType {
  APARTMENT = 'APARTMENT',
  HOUSE = 'HOUSE',
}

export enum EstateOriginType {
  AUTOPUBLISH = 'AUTOPUBLISH',
  B2B = 'B2B',
}

export enum EstateStatus {
  BANNED = 'BANNED',
  CONSIGNED = 'CONSIGNED',
  LEASED = 'LEASED',
  PAUSED = 'PAUSED',
  PUBLISHED = 'PUBLISHED',
  REGISTERED = 'REGISTERED',
  RESERVED = 'RESERVED',
}

export type GqlLandlordType = {
  __typename?: 'LandlordType';
  email: Scalars['String'];
  firstName: Scalars['String'];
  landlordId: Scalars['Int'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  status: Scalars['String'];
  userId: Scalars['Int'];
};

export enum EstatePublicationType {
  COMMERCIAL = 'COMMERCIAL',
  RESIDENTIAL = 'RESIDENTIAL',
}

export enum LandlordPublishingIntention {
  RENT = 'RENT',
  SELL = 'SELL',
  SELL_OR_RENT = 'SELL_OR_RENT',
}

export type GqlCreateDealType = {
  address?: InputMaybe<Scalars['String']>;
  area?: InputMaybe<Scalars['Int']>;
  bathRoomsNr?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  furnished?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['String']>;
  leadOrigin: HubspotSource;
  longitude?: InputMaybe<Scalars['String']>;
  parkingLotOptions?: InputMaybe<Scalars['Int']>;
  phone: Scalars['String'];
  roomsNr?: InputMaybe<Scalars['Int']>;
  stratum?: InputMaybe<Scalars['Int']>;
  suggestedPrice?: InputMaybe<Scalars['String']>;
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
};

export enum HubspotSource {
  APTUNO = 'APTUNO',
  AUTOPUBLISH = 'AUTOPUBLISH',
  B2B = 'B2B',
  BY_CAPTURING_AGENT = 'BY_CAPTURING_AGENT',
  BY_LANDLORD = 'BY_LANDLORD',
  FINCARAIZ = 'FINCARAIZ',
  FINCARAIZ_RETRY = 'FINCARAIZ_RETRY',
  INMUEBLES24 = 'INMUEBLES24',
  INMUEBLES24_RETRY = 'INMUEBLES24_RETRY',
  MADE_BY_CAPTURING_AGENT = 'MADE_BY_CAPTURING_AGENT',
  METROCUADRADO = 'METROCUADRADO',
  METROCUADRADO_RETRY = 'METROCUADRADO_RETRY',
  PROPERATI = 'PROPERATI',
  RENT_CALCULATOR = 'RENT_CALCULATOR',
  SEGUNDAMANO = 'SEGUNDAMANO',
  SEGUNDAMANO_RETRY = 'SEGUNDAMANO_RETRY',
  STREET_ADS = 'STREET_ADS',
  STREET_ADS_RETRY = 'STREET_ADS_RETRY',
  VIVANUNCIOS = 'VIVANUNCIOS',
  VIVANUNCIOS_RETRY = 'VIVANUNCIOS_RETRY',
}

export type GqlCreateDealSupplyOutputType = {
  __typename?: 'CreateDealSupplyOutputType';
  addres?: Maybe<Scalars['String']>;
  amountOfBathrooms?: Maybe<Scalars['Float']>;
  amountOfBedrooms?: Maybe<Scalars['Float']>;
  area?: Maybe<Scalars['Float']>;
  autoPublish: Scalars['Boolean'];
  campaign?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  crmDealId?: Maybe<Scalars['String']>;
  dealName: Scalars['String'];
  furnished?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  landlordPhone: Scalars['String'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  origin: Scalars['String'];
  parkingOptions?: Maybe<Scalars['Float']>;
  pipeline?: Maybe<Scalars['String']>;
  sourceChannel?: Maybe<Scalars['String']>;
  stage: Scalars['String'];
  stratum?: Maybe<Scalars['Float']>;
  suggested_price?: Maybe<Scalars['String']>;
};

export type GqlRejectDocumentsInput = {
  documentationId: Scalars['String'];
  documentsToReject: Array<Scalars['String']>;
  suggestions: Scalars['String'];
};

export type GqlReportableErrorGqlType = {
  cause: Scalars['String'];
  loggedInUser?: InputMaybe<GqlLoggedInUser>;
};

export type GqlLoggedInUser = {
  id?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type GqlSlotAvailabilityInputType = {
  date: Scalars['Float'];
  enabled: Scalars['Boolean'];
  timesToRepeat?: InputMaybe<Scalars['Int']>;
};

export type GqlEstateVisitAvailabilityInputType = {
  estateId: Scalars['Int'];
  slots?: InputMaybe<Array<GqlSlotAvailabilityInputType>>;
};

export type GqlBenefitsInputType = {
  id?: InputMaybe<Scalars['Int']>;
  items: Array<EstateBenefitCode>;
};

export enum EstateBenefitCode {
  ALL_RISK_INSURANCE = 'ALL_RISK_INSURANCE',
  CLEANING_SERVICE = 'CLEANING_SERVICE',
  CO_PROPERTY_REPRESENTATION = 'CO_PROPERTY_REPRESENTATION',
  DAMAGE_MISSING_INSURANCE = 'DAMAGE_MISSING_INSURANCE',
  ELECTRONIC_LOCK = 'ELECTRONIC_LOCK',
  GET_LIQUIDITY = 'GET_LIQUIDITY',
  HEARTHQUAKE_COVERAGE = 'HEARTHQUAKE_COVERAGE',
  IMPROVE_SEO = 'IMPROVE_SEO',
  KEYS_BOX = 'KEYS_BOX',
  PAINTING_SERVICE = 'PAINTING_SERVICE',
  PROFESIONAL_PHOTOS = 'PROFESIONAL_PHOTOS',
  PROFILE_CHECK = 'PROFILE_CHECK',
  PUBLIC_SERVICES_PAYMENT = 'PUBLIC_SERVICES_PAYMENT',
  RENT_ADVANCE = 'RENT_ADVANCE',
  RENT_SINCE_PUBLICATION = 'RENT_SINCE_PUBLICATION',
  TIMELY_PAYMENT_12 = 'TIMELY_PAYMENT_12',
  TIMELY_PAYMENT_36 = 'TIMELY_PAYMENT_36',
  VISIT_ASSISTANT = 'VISIT_ASSISTANT',
}

export type GqlCapturingAgentInputType = {
  address: Scalars['String'];
  detailAddress: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  identityDocumentCode: Scalars['String'];
  identityDocumentType: Scalars['String'];
  lastName: Scalars['String'];
  ownerManager: Scalars['String'];
  phoneCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  userId?: InputMaybe<Scalars['Int']>;
};

export type GqlCapturingAgentOutputType = {
  __typename?: 'CapturingAgentOutputType';
  address: Scalars['String'];
  detailAddress: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  identityDocumentCode?: Maybe<Scalars['String']>;
  identityDocumentType?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  ownerManager: Scalars['String'];
  phoneCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
};

export type GqlCapturingAgentDocumentInputType = {
  capturingAgentId?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
  uri: Scalars['String'];
};

export type GqlCapturingAgentDocumentOutputType = {
  __typename?: 'CapturingAgentDocumentOutputType';
  capturingAgentId?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  uri: Scalars['String'];
};

export type GqlCapturingManagerInputType = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  identityDocumentCode: Scalars['String'];
  identityDocumentType: Scalars['String'];
  lastName: Scalars['String'];
  phoneCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  userId?: InputMaybe<Scalars['Int']>;
};

export type GqlVisitCompanionInputType = {
  coverage: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  identityDocumentCode: Scalars['String'];
  identityDocumentType: Scalars['String'];
  lastName: Scalars['String'];
  phoneCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  role: Scalars['String'];
  userId?: InputMaybe<Scalars['Int']>;
};

export enum AuthChannel {
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
}

export enum UserType {
  ADMIN = 'ADMIN',
  CAPTURING_AGENT = 'CAPTURING_AGENT',
  LANDLORD = 'LANDLORD',
  MKT = 'MKT',
  OPS = 'OPS',
  TENANT = 'TENANT',
  VISIT_COMPANION_AGENT = 'VISIT_COMPANION_AGENT',
}

export type GqlTenantInputType = {
  email: Scalars['String'];
  estimatedMoveDate: Scalars['String'];
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  identityDocumentCode: Scalars['String'];
  identityDocumentType: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  status: Scalars['String'];
  userId: Scalars['Int'];
};

export type GqlUpdateUserInputType = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  identityDocumentCode: Scalars['String'];
  identityDocumentType: IdentityDocumentType;
  lastName: Scalars['String'];
};

export enum IdentityDocumentType {
  CC = 'CC',
  CE = 'CE',
  CPEP = 'CPEP',
  NIT = 'NIT',
  PA = 'PA',
}

export type GqlOutputPhoto = {
  __typename?: 'OutputPhoto';
  id: Scalars['Float'];
  url: Scalars['String'];
};

export type GqlCreateAppointmentEstateMutationVariables = Exact<{
  input: GqlCreateAppointmentEstateType;
}>;

export type GqlCreateAppointmentEstateMutation = { __typename?: 'Mutation'; createAppointmentEstate: number };

export type GqlRescheduleAppointmentMutationVariables = Exact<{
  newVisitAssistant?: InputMaybe<GqlAppointmentUserInputType>;
  newVisitDate: Scalars['Float'];
  appointmentType: AppointmentType;
  appointmentId: Scalars['Int'];
}>;

export type GqlRescheduleAppointmentMutation = {
  __typename?: 'Mutation';
  rescheduleAppointment: {
    __typename?: 'AppointmentOutputType';
    id: number;
    visitDate: number;
    estate: { __typename?: 'AppointmentEstateOutputType'; id: number };
  };
};

export type GqlCreateAppointmentMutationVariables = Exact<{
  appointment: GqlCreateAppointmentInputType;
}>;

export type GqlCreateAppointmentMutation = {
  __typename?: 'Mutation';
  createAppointment: {
    __typename?: 'AppointmentOutputType';
    id: number;
    visitDate: number;
    estate: { __typename?: 'AppointmentEstateOutputType'; id: number };
    tenant: { __typename?: 'AppointmentUserType'; userId?: number | null };
  };
};

export type GqlPublicationCoverageQueryVariables = Exact<{
  estateId: Scalars['Float'];
}>;

export type GqlPublicationCoverageQuery = { __typename?: 'Query'; publicationCoverage: PublicationCoverage };

export type GqlGetEstateReferralPhoneQueryVariables = Exact<{
  estateId: Scalars['Float'];
}>;

export type GqlGetEstateReferralPhoneQuery = {
  __typename?: 'Query';
  getEstateReferral: { __typename?: 'UserOutputType'; phoneNumber?: string | null };
};

export type GqlPrequalifySupplyDealBasedOnUserIntentMutationVariables = Exact<{
  estateType: EstatePublicationType;
  sellOrRentIntention: LandlordPublishingIntention;
  landlordUserId?: InputMaybe<Scalars['Float']>;
}>;

export type GqlPrequalifySupplyDealBasedOnUserIntentMutation = {
  __typename?: 'Mutation';
  prequalifySupplyDealBasedOnUserIntent: boolean;
};

export type GqlProspectSupplyDealMutationVariables = Exact<{
  dealProperties: GqlCreateDealType;
  landlordUserId?: InputMaybe<Scalars['Float']>;
}>;

export type GqlProspectSupplyDealMutation = {
  __typename?: 'Mutation';
  prospectSupplyDeal: { __typename?: 'CreateDealSupplyOutputType'; id?: number | null };
};

export type GqlRegisterEstatePublicationMutationVariables = Exact<{
  estate: GqlEstateInput;
  landlordUserId?: InputMaybe<Scalars['Int']>;
}>;

export type GqlRegisterEstatePublicationMutation = { __typename?: 'Mutation'; registerEstatePublication: number };

export type GqlEditEstatePublicationMutationVariables = Exact<{
  estate: GqlEstateInput;
  landlordUserId?: InputMaybe<Scalars['Int']>;
}>;

export type GqlEditEstatePublicationMutation = { __typename?: 'Mutation'; editEstatePublication: number };
