import { useMutation } from '@apollo/client';
import { Phone } from '../../../../../share/user/user';
import { useGetEstateReferralPhone } from '../../../../Publication/integrations/gql/api/get-estate-referral-phone.api';
import { useIsEstatePublicationCovered } from '../../../../Publication/integrations/gql/api/estate-publication-coverage.api';
import { useCreateAppointmentDeal } from './create-appointment-demand-deal.api';
import {
  GqlCreateAppointmentMutation,
  GqlCreateAppointmentMutationVariables,
  GqlRescheduleAppointmentMutation,
  GqlRescheduleAppointmentMutationVariables,
} from '../../../../Shared/integrations/gql/core/generated-types';
import { schema as RescheduleAppointmentSchema } from '../schemas/reschedule-appointment.schema';
import { schema as ScheduleAppointmentSchema } from '../schemas/schedule-appointment.schema';
import { useCallback, useState } from 'react';
import { useAnalyticsTracker } from '../../../../../services/analytics';
import { AppointmentScheduledEvent } from '../../../events/appointment-scheduled.event';
import { TenantAppointmentRescheduledEvent } from '../../../events/appointment-rescheduled.event';
import { env } from '../../../../../environment/environment';
import { AppointmentSubmit } from './models/submit-appointment.model';
import { AppointmentScheduledOnUncoveredPublicationEvent } from '../../../events/appointment-scheduled-on-uncovered-publication.event';

const useScheduleAppointment = () => {
  const tracker = useAnalyticsTracker();
  const [schedule] = useMutation<GqlCreateAppointmentMutation, GqlCreateAppointmentMutationVariables>(
    ScheduleAppointmentSchema
  );

  return (appointment: AppointmentSubmit) =>
    schedule({
      variables: AppointmentSubmit.asGqlCreateAppointmentMutationVariables(appointment),
    })
      .then((response) => response.data!)
      .finally(() => {
        tracker.trackEvent(new AppointmentScheduledEvent(appointment.tenantId, appointment.estateLegalId));
      });
};

const useRescheduleAppointment = () => {
  const tracker = useAnalyticsTracker();
  const [reschedule] = useMutation<GqlRescheduleAppointmentMutation, GqlRescheduleAppointmentMutationVariables>(
    RescheduleAppointmentSchema
  );

  return (appointment: AppointmentSubmit) =>
    reschedule({
      variables: AppointmentSubmit.asGqlRescheduleAppointmentMutationVariables(appointment),
    })
      .then((response) => response.data!)
      .finally(() => {
        tracker.trackEvent(new TenantAppointmentRescheduledEvent(appointment.estateLegalId.value));
      });
};

const useCommunicateTenantWithEstateReferralUser = () => {
  const tracker = useAnalyticsTracker();

  return (
    estateReferralPhone: Phone,
    { estateLegalId, visitDate, tenantName, tenantId }: AppointmentSubmit
  ): Promise<void> =>
    new Promise((resolve) => {
      const visitDay = visitDate.toISOString().split('T')[0];
      const visitTime = visitDate.toLocaleTimeString();
      const estateUrl = `https://${env.urlAptuno}/detalles/${estateLegalId.value}`;

      const uriMessage = encodeURI(
        `https://wa.me/${estateReferralPhone.onlyDigits().value}?text=¡Hola, te saluda ${
          tenantName.value
        }!\nVi tu inmueble en *Aptuno*. ¿Podríamos coordinar una visita para el *${visitDay}* a las *${visitTime}*?.\n*Inmueble*: [${estateUrl}]`
      );

      tracker
        .trackEvent(new AppointmentScheduledOnUncoveredPublicationEvent(tenantId, estateLegalId, visitDate))
        .finally(() => {
          window.open(uriMessage, '_blank');
          resolve(void 0);
        });
    });
};

const useOnCoveredEstatePublication = () => {
  const scheduleAppointment = useScheduleAppointment();
  const rescheduleAppointment = useRescheduleAppointment();

  return async (appointment: AppointmentSubmit): Promise<void> => {
    const submitAppointment = !!appointment.alreadyExistingAppointmentId
      ? rescheduleAppointment(appointment)
      : scheduleAppointment(appointment);

    return submitAppointment.then(() => void 0);
  };
};

const useOnUnCoveredEstatePublication = () => {
  const getEstateReferralPhone = useGetEstateReferralPhone();
  const communicateTenantWithEstateReferralUser = useCommunicateTenantWithEstateReferralUser();

  return (appointment: AppointmentSubmit): Promise<void> =>
    getEstateReferralPhone(appointment.estateId).then((userPhone) =>
      communicateTenantWithEstateReferralUser(userPhone, appointment)
    );
};

const useSubmitAppointment = () => {
  const [isSubmitingAppointment, setSubmitingAppointment] = useState(false);
  const createAppointmentDemandDeal = useCreateAppointmentDeal();
  const isEstatePublicationCovered = useIsEstatePublicationCovered();

  const onCoveredEstatePublication = useOnCoveredEstatePublication();
  const onUnCoveredEstatePublication = useOnUnCoveredEstatePublication();

  const submitingAppointment = useCallback(() => setSubmitingAppointment(true), []);
  const notSubmitingAppointment = useCallback(() => setSubmitingAppointment(false), []);

  const submitAppointment = async (appointment: AppointmentSubmit): Promise<void> => {
    submitingAppointment();
    const estateId = appointment.estateId;

    return Promise.all([isEstatePublicationCovered(estateId), createAppointmentDemandDeal(estateId)])
      .then(([isEstatePublicationCovered]) =>
        isEstatePublicationCovered ? onCoveredEstatePublication(appointment) : onUnCoveredEstatePublication(appointment)
      )
      .finally(() => notSubmitingAppointment());
  };

  return {
    submitAppointment,
    isSubmitingAppointment,
  };
};

export { useSubmitAppointment };
