import gql from 'graphql-tag';

export const GET_FILTERS = gql`
  query Filters(
    $locationSlug: String
    $minPrice: Int
    $maxPrice: Int
    $rooms: Int
    $locationId: Int
    $propertyType: String
    $propertyAge: String
    $minGarajes: Int
    $minBaths: Int
    $minArea: Int
    $maxArea: Int
    $stratum: [Int]
    $characteristics: [CharacteristicSearchFilter]
  ) {
    filters(
      filters: {
        minPrice: $minPrice
        maxPrice: $maxPrice
        minRooms: $rooms
        locationSlug: $locationSlug
        locationId: $locationId
        propertyType: $propertyType
        propertyAge: $propertyAge
        minBathrooms: $minBaths
        minGarajes: $minGarajes
        minArea: $minArea
        maxArea: $maxArea
        stratum: $stratum
        characteristics: $characteristics
      }
    ) {
      availableLocations {
        name
        code
        displayName
        slug
        locationType
      }
      rooms {
        options {
          name
          value
          count
        }
      }
      pricing {
        options {
          name
          value {
            priceMin
            priceMax
          }
          count
        }
      }
      currentBranch {
        name
        code
        displayName
        slug
        locationType
      }
    }
  }
`;

export const GET_SEARCHABLE_LOCATIONS = gql`
  query GetSearchableEstateLocation($typedLocationName: String!) {
    getSearchableEstateLocation(typedLocationName: $typedLocationName) {
      locationId
      locationName
      locationSlug
      locationOrder
      locationType
      parents {
        locationId
        locationName
        locationSlug
      }
    }
  }
`;
