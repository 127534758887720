import { environmentDev } from './environment.dev';
import { environmentProd } from './environment.prod';
import { environmentProdMX } from './environment.prod-mx';
import { environmentQa } from './environment.qa';

const COUNTRY_CODE = process.env.REACT_APP_DEPLOY_COUNTRY_CODE;
const COUNTRY_ENVS = {
  CO: environmentProd,
  MX: environmentProdMX,
};

function getEnvironment() {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production':
      return COUNTRY_ENVS[COUNTRY_CODE];
    case 'qa':
      return environmentQa;
    default:
      return environmentDev;
  }
}

export const env = getEnvironment();
