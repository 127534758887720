import React, { useState } from 'react'
import ModalLeave from '../commons/ModalLeave/ModalLeave';
import { Container, BackButton, Tittle, CloseButton } from './NavbarSimple.styled';

const NavbarSimple = ({ onBackButton, onClosePersonalized, title, isPublish = true }) => {
  const [ShowCloseModal, setShowCloseModal] = useState(false);

  const onClose = () => {
    setShowCloseModal(true);
  };

  return (
    <Container>
      {ShowCloseModal && <ModalLeave onClose={() => setShowCloseModal(false)} isPublish={isPublish} />}
      {onBackButton && (
        <BackButton
          src="/images/LeftArrow.svg"
          alt="Icono Flecha Abajo | Aptuno"
          title="Icono Flecha Abajo | Aptuno"
          onClick={onBackButton}
        />
      )}
      <Tittle>{title ? title : 'Publicar inmueble'}</Tittle>
      <CloseButton
        src="/images/CrossButton.svg"
        alt="Icono de Cross"
        title="Icono de Cross | Aptuno"
        onClick={onClosePersonalized ? onClosePersonalized : onClose}
      />
    </Container>
  );
};

export default NavbarSimple;
