import { EstateAddressDetails } from '../Context/Publication/model/estate-address-details.model';
import { GqlEstateInput } from '../Context/Shared/integrations/gql/core/generated-types';
import { currencies } from '../data/currencies';
import { isColombia } from '../helpers/regionUtils';
import history from '../history';

export const TitleMobile: string[] = [
  'Ayúdanos con estas preguntas',
  'Empecemos con la ubicación',
  'Datos clave de la publicación',
  'Otros atributos del inmueble',
  'Definamos los detalles principales',
  'Por último, agreguemos las fotos',
  'Tenemos tres opciones diferentes.',
];

export const TitleDesktop: string[] = [
  'Empecemos con la ubicación de la propiedad',
  'Ingresa los datos clave de la publicación',
];

type estateTypeOptionsType = {
  label: string;
  value: string;
};

export const estateTypeOptions: estateTypeOptionsType[] = [
  { label: 'Comercial (Oficinas o Locales)', value: 'COMMERCIAL' },
  { label: 'Residencial (Vivienda)', value: 'RESIDENTIAL' },
];

export const RENT_PREDICTION_API_URL: string = '/calcular-precio';

export const antiquenessMap = {
  Remodelado: 0,
  'Entre 0 y 5 años': 2,
  'Entre 5 y 10 años': 8,
  'Entre 10 y 20 años': 15,
  'Más de 20 años': 20,
};

const getStratum = (estateFront) => (isColombia() ? estateFront.basicInfo.characteristics.stratum : 1);

export const mapToBackModel = (estateFront): GqlEstateInput => {
  const location = estateFront.location;
  const addressDetails = EstateAddressDetails.LocalStore.fromLocallyStored(location.addressDetails).toString();

  const build: GqlEstateInput = {
    id: estateFront.id,
    benefitsId: estateFront?.benefits?.id,
    currency: isColombia() ? currencies.COP : currencies.MXN,
    address: location.formattedAddress,
    addressDetails,
    ...location.coords,
    about: location.description,
    ...estateFront.basicInfo,
    characteristics: {
      ...estateFront.extraInfo,
      ...estateFront.basicInfo.characteristics,
      stratum: getStratum(estateFront),
      allowPets: 'No' != estateFront?.basicInfo?.characteristics?.petsAllowed,
    },
    appointmentEstateInformation: estateFront.appointmentEstateInformation
      ? {
          ...estateFront.appointmentEstateInformation,
        }
      : undefined,
  };

  return build;
};

export const goToCreateLandlordPage = () => history.push('/publicar-inmueble/crear-propietario');
export const goToLocationSelectPage = () => history.push('/publicar-inmueble');
export const goToPublishTypePage = () => history.push('/publicar-inmueble/tipo-publicacion');
export const goToBasicInfoPage = () => history.push('/publicar-inmueble/informacion-basica');
export const goToPhotoUploadPage = () => history.push('/publicar-inmueble/informacion-fotos');
export const goToExclusivityPage = () => history.push('/publicar-inmueble/informacion-exclusividad');
export const goToTermsAndConditionsPage = () => history.push('/publicar-inmueble/informacion-plan');

export const IMAGE_QUALITY: number = 0.85;
export const MAX_WIDTH: number = 1200;
export const MIN_PHOTOS: number = 0;

export const PUBLICATION_TYPE = { EXCLUSIVE: 'EXCLUSIVE', STANDARD: 'STANDARD' };

export const PLAN_TYPES = {
  BLUE: 'BLUE',
  PLUS: 'PLUS',
  EXCLUSIVE: 'EXCLUSIVE',
  BROKERAGE: 'BROKERAGE',
  WARRANTY: 'WARRANTY',
};

export const getConstructionYear = (antiqueness) => antiquenessMap[antiqueness];

export const getCity = (address: string) => {
  if (address.toUpperCase().search(/CUNDINAMARCA/) >= 0 || address.toUpperCase().search(/BOGOT[A|Á]/) >= 0) {
    return 'Bogotá';
  } else if (address.toUpperCase().search(/ANTIOQUIA/) >= 0 || address.toUpperCase().search(/MEDELL[I|Í]N/) >= 0) {
    return 'Medellín';
  } else if (address.toUpperCase().search(/CIUDAD DE M[É|E]XICO/) >= 0 || address.toUpperCase().search(/CDMX/) >= 0) {
    return 'Ciudad de México';
  } else {
    return undefined;
  }
};
