import { AptunoButton } from '@aptuno/aptuno-ui';
import styled from 'styled-components';

export const CookiesBoxWrapper = styled.div<{bgColor: string, textColor: string}>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${props => props.bgColor ? props.bgColor : '#ffffff'};
  color: ${props => props.textColor ? props.textColor : '#000000'};
  min-height: 152px;
  width: 100%;
  max-width: 592px;
  z-index: 999;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 6px 5px 2px rgb(0 0 0 / 7%);

  & a {
    text-decoration: underline !important;
  }
  & a:hover {
    text-decoration: underline !important;
    color: #0055FB !important;
  }
`;

export const BackDrop = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 998;
`;

export const CookiesBoxText = styled.p`
  font-size: 12px !important;
  line-height: 16px !important;
`;
export const CookiesTitle = styled.h3`
  font-size: 16px !important;
  line-height: 24px !important;
  width: 100%;
`;

export const CookiesBoxBtn = styled(AptunoButton)`
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
`;