import { createBrowserHistory } from 'history';
import config from './config';
import TagManager from 'react-gtm-module';
import { env } from './environment/environment';
import ReactPixel from 'react-facebook-pixel';

let history;
let exPath = window.location.pathname + window.location.search;

if (typeof document !== 'undefined') {

  if (config.isON_GTM) {
    console.log('INIT GTM')
    const tagManagerArgs = {
      gtmId: config.isProdEnv ? env.tagManagerKey : config.GTM_QA_KEY,
    }
    TagManager.initialize(tagManagerArgs)
  }

  history = createBrowserHistory()
  history.listen((a, b, c) => {
    if (TagManager && exPath !== window.location.pathname + window.location.search) {
      exPath = window.location.pathname + window.location.search
      console.log('sending pageview', exPath)
      
      ReactPixel.pageView();
      
      const data = {
        dataLayer: {
          event: 'PageView',
          page: exPath,
        }
      };
      TagManager.dataLayer(data);

    } else console.log('no sending pageview', exPath);
  });
  
}

export default history;
