import { useQuery } from '@apollo/client';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { AptunoButton, Stepper } from '@aptuno/aptuno-ui';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useLocation, useParams } from 'react-router-dom';

import {
  ActionsWrapper,
  DateTimeLabel,
  Disclaimer,
  ImageHeader,
  Text,
} from '../../components/Appointment/Appointment.styled';
import AppointmentDateAndTimeSelector from '../../components/Appointment/AppointmentDateAndTimeSelector';
import AppointmentUserDataForm from '../../components/Appointment/AppointmentUserDataForm';
import { GlobalStyle } from '../../components/commons/Layout.styled';
import NavbarSimple from '../../components/NavbarSimple/NavbarSimple';
import OTPValidator from '../../components/OTPValidator';
import { ProcessContainer } from '../../components/ProcessInfoBar/ProcessInfoBar.styled';
import { ErrorsTypes } from '../../data/errors';
import { authChannels } from '../../data/miscellaneous';
import { env } from '../../environment/environment';
import { getCountryByCurrency } from '../../helpers/coolFunctions';
import history from '../../history';
import {
  GET_AMOUNT_OF_EFFECTIVE_APPOINTMENTS_ON_DIFFERENT_ESTATES_FOR_CHAIN_SCHEDULING,
  GET_AMOUNT_OF_OPEN_APPOINTMENTS_ON_DIFFERENT_ESTATES,
  GET_ESTATE_AVAILABILITY,
} from '../../hooks/useAppointments';
import { DO_PHONE_SIGNUP } from '../../hooks/useAuthQuery';
import { GET_IDENTITY_VALIDATION } from '../../hooks/useIdentityValidation';
import { GET_PROPERTY } from '../../hooks/usePropertyQuery';
import { GET_TENANT_FOR_AUTHENTICATED_USER, UPDATE_TENANT } from '../../hooks/useUserQuery';
import { useAnalyticsTracker } from '../../services/analytics';
import { Container } from './Appointment.styled';
import { AppointmentStartedEvent } from './events/appointment-started.event';
import { OTPRequestedEvent } from '../../Context/Auth/events/otp-requested.event';
import { UserIdentityAppointmentEvent } from './events/user-identity-appointment.event';
import { EstateId, EstateLegalId } from '../../share/estate/estate';
import { useSubmitAppointment } from '../../Context/Appointment/integrations/gql/api/submit-appointment.api';
import { Email, PersonName, Phone, UserId } from '../../share/user/user';
import { AppointmentId } from '../../share/appointment/appointment.model';
import { AppointmentType, IdentityDocumentType } from '../../Context/Shared/integrations/gql/core/generated-types';
import { useLoggedInUser } from '../../Context/Auth/SessionProvider';

const stepsData = [
  { title: 'Fecha y hora', description: 'Escoge el espacio que mejor te funcione' },
  { title: 'Completa tu información' },
  { title: 'Verifica tu número' },
];

const Appointment = () => {
  const { estateId: estateLegalId } = useParams() || {};
  const { visita: appointmentId } = queryString.parse(useLocation().search);
  const visitAssistant = useLocation().state?.visitAssistant;

  const { data: dataGetProperty } = useQuery(GET_PROPERTY, {
    errorPolicy: 'all',
    variables: { propertyId: estateLegalId },
  });
  const [getIdentityValidation, { data: identityValidation }] = useLazyQuery(GET_IDENTITY_VALIDATION, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    context: { clientName: 'second' },
  });

  const estateId = EstateId.unsafe(dataGetProperty?.property?.id);

  /* Contexts */
  const user = useLoggedInUser();

  /* Local States */
  const today = new Date();
  const initialDate = new Date(today);
  const fromAvailableDate = new Date(today);
  fromAvailableDate.setHours(initialDate.getHours() + 18);

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [tenantIsAssistant, setTenantIsAssistant] = useState(true);
  const [appointmentData, setAppointmentData] = useState(null);
  const [country] = useState(env.countryCode);
  const [authChannel, setAuthChannel] = useState(authChannels.whatsapp);
  const [retryNr, setRetry] = useState(0);
  const tracker = useAnalyticsTracker();
  const [targetCity, setTargetCity] = useState();
  const [generalAvailableSlots, setGeneralAvailableSlots] = useState([]);
  const [recommendatesAvailableSlots, setRecommendatesAvailableSlots] = useState([]);

  const isUserLoggedIn = !!user && !!user.data;
  const isUserInfoEditionEnabled = !identityValidation?.getIdentityValidation?.value;

  /* Graphl Queries & Mutations */
  const [getTenant, { data: tenantData }] = useLazyQuery(GET_TENANT_FOR_AUTHENTICATED_USER, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const [
    getEstateAvailability,
    {
      loading: loadingEstateAvailability,
      data: estateAvailability,
      refetch: refetchEstateAvailability,
      called: calledStateAvailability,
    },
  ] = useLazyQuery(GET_ESTATE_AVAILABILITY, { errorPolicy: 'all' });
  const estateAvailabilityLoaded = calledStateAvailability && !loadingEstateAvailability;
  const [doSavePhone, { loading: isLoadingSignup }] = useMutation(DO_PHONE_SIGNUP);

  const [updateTenant, { data: tenantUpdateResponse, loading: loadingTenantEdit }] = useMutation(UPDATE_TENANT);

  const [
    getAmountOfOpenAppointmentsOnDifferentEstates,
    { data: amountOpenAppointments, refetch: refetchOpenAppointments },
  ] = useLazyQuery(GET_AMOUNT_OF_OPEN_APPOINTMENTS_ON_DIFFERENT_ESTATES);

  const [getAmountOfEffectiveAppointmentsOnDifferentEstatesForChainScheduling] = useLazyQuery(
    GET_AMOUNT_OF_EFFECTIVE_APPOINTMENTS_ON_DIFFERENT_ESTATES_FOR_CHAIN_SCHEDULING
  );

  const { submitAppointment, isSubmitingAppointment } = useSubmitAppointment();

  const refetchAllAvailability = () => {
    refetchEstateAvailability();
  };

  const getAllAvailability = () => {
    if (dataGetProperty && dataGetProperty.property) {
      getEstateAvailability({
        variables: {
          estateId: Number(dataGetProperty.property.id),
          date: Date.parse(initialDate),
        },
      });
    }
  };

  useEffect(() => {
    tracker.trackEvent(new AppointmentStartedEvent(estateLegalId));
  }, []);

  /** Modal State */
  useEffect(() => {
    toFirstStep();
    estateAvailability ? refetchAllAvailability() : getAllAvailability();
  }, [dataGetProperty, getEstateAvailability]);

  /** Stepper**/
  const toFirstStep = () => setCurrentStep(() => 0);
  const toNextStep = () => setCurrentStep((step) => (step < stepsData.length - 1 ? step + 1 : stepsData.length - 1));
  const toPreviousStep = () => setCurrentStep((step) => (step > 0 ? step - 1 : 0));

  const sendAppointment = (appointmentData) => {
    if (estateId) {
      const request = {
        tenantId: UserId.of(user.data.id),
        tenantName: PersonName.of(`${appointmentData.firstnameTenant} ${appointmentData.lastnameTenant}`),
        estateId,
        estateLegalId: EstateLegalId.of(estateLegalId),
        visitDate: new Date(selectedDate),
        alreadyExistingAppointmentId: appointmentId ? AppointmentId.of(appointmentId) : undefined,
        visitAssistant: !tenantIsAssistant
          ? {
              userId: visitAssistant ? UserId.of(visitAssistant.userId) : undefined,
              firstName: PersonName.of(appointmentData.firstnameAssistant),
              lastName: PersonName.of(appointmentData.lastnameAssistant),
              phoneNumber: Phone.of(appointmentData.phoneNumberAssistant),
              email: Email.of(appointmentData.emailAssistant),
              identityDocumentType: IdentityDocumentType[appointmentData.dniTypeAssistant],
              identityDocumentCode: `${appointmentData.dniNumberAssistant}`,
            }
          : undefined,
      };

      submitAppointment(request)
        .then(() => {
          dispatchFbPurchaseEvent();

          Promise.all([getOpenAppointments(), getAmountOfEffectiveAppointmentsOnDifferentEstatesForChainScheduling()]) // TODO: Check if this is needed
            .then(([openApointments, effectiveAppointmens]) =>
              goToAppointmentConfirmed(
                openApointments.data?.getAmountOfOpenAppointmentsOnDifferentEstates || 0,
                effectiveAppointmens.data?.getAmountOfEffectiveAppointmentsOnDifferentEstatesForChainScheduling || 0
              )
            )
            .catch(() => goToAppointmentConfirmed(0, 0));
        })
        .catch((error) => {
          const submitAppointmentError = error.graphQLErrors
            ?.flatMap(({ extensions }) => extensions?.exception?.errors?.map((error) => ErrorsTypes[error.code]))
            ?.find((error) => !!error);

          if (submitAppointmentError) {
            setSubmitAppointmentError(submitAppointmentError);
          }
        });
    }
  };

  /* Handle Appointment Submit*/
  const onAppointmentUserSubmited = (data) => {
    if (!isUserLoggedIn) {
      requestOtpCode(data.phoneNumberTenant);
      setAppointmentData(data);
      toNextStep();
    } else if (isUserInfoEditionEnabled) {
      setAppointmentData(data);
      updateTenantProfile(data);
    } else {
      sendAppointment(data);
    }
  };

  useEffect(() => {
    const wasTenantInfoUpdated = !!tenantUpdateResponse;
    const isNotTenantInfoEditable = tenantData && !isUserInfoEditionEnabled;
    const isTenantInfoEditableAndNotUpdatedYet = tenantData && isUserInfoEditionEnabled && !wasTenantInfoUpdated;

    if (isTenantInfoEditableAndNotUpdatedYet) {
      updateTenantProfile(appointmentData);
    } else if (wasTenantInfoUpdated || isNotTenantInfoEditable) {
      sendAppointment(appointmentData);
    }
  }, [tenantData, tenantUpdateResponse, isUserInfoEditionEnabled]);

  useEffect(() => {
    if (isUserLoggedIn) {
      getIdentityValidation();
    }
  }, [isUserLoggedIn]);

  const getOpenAppointments = () => {
    if (amountOpenAppointments && amountOpenAppointments.getAmountOfOpenAppointmentsOnDifferentEstates) {
      return refetchOpenAppointments();
    } else {
      return getAmountOfOpenAppointmentsOnDifferentEstates();
    }
  };

  const goToAppointmentConfirmed = (amountOpenAppointments, amountEffectiveAppointments) => {
    tracker.trackIdentity(new UserIdentityAppointmentEvent(user.data.id, targetCity));

    const locationData = {
      pathname: '/cita-confirmada',
      state: {
        amountOpenAndEffectiveAppointments: amountOpenAppointments + amountEffectiveAppointments,
        amountEffectiveAppointments,
        addressLastAppointment: dataGetProperty.property.address,
        dateLastAppointment: selectedDate,
        appointmentType: AppointmentType.FACE_2_FACE_MULTIPLE,
        propertyLegalId: estateLegalId,
      },
    };

    history.push(locationData);
  };

  const dispatchFbPurchaseEvent = () => {
    const estate = dataGetProperty?.property;
    const purchaseData = {
      content_type: 'home_listing',
      content_ids: estate.propertyLegalId,
      currency: estate.currencyIso,
      search_string: 'inmuebles-arriendo',
      value: 1,
      property_type: estate.propertyType.toLowerCase(),
      availability: 'for_rent',
      neighborhood: estate.location.name,
      country: getCountryByCurrency(estate.currencyIso),
      listing_type: 'for_rent_by_agent',
    };

    ReactPixel.track('Purchase', purchaseData);
  };

  /** Handle Auth */
  const requestOtpCode = (phone) => {
    tracker.trackEvent(new OTPRequestedEvent('Scheduling', country, retryNr, phone)).finally(() => {
      if (retryNr < 6) {
        setAuthChannel(retryNr < 3 ? authChannels.whatsapp : authChannels.sms);
        doSavePhone({
          variables: {
            number: phone,
            authChannel: retryNr < 3 ? authChannels.whatsapp : authChannels.sms,
          },
        });
      } else {
        setCurrentStep(1);
      }
    });
  };

  const handleOtpRetry = (retry) => {
    setRetry(retryNr + 1);
    requestOtpCode(appointmentData.phoneNumberTenant);
  };

  const updateTenantProfile = (data) => {
    updateTenant({
      variables: {
        tenant: {
          userId: user.data.id,
          status: user.data.status,
          phoneNumber: data.phoneNumberTenant,
          firstName: data.firstnameTenant,
          lastName: data.lastnameTenant,
          email: data.emailTenant,
          identityDocumentType: data.dniTypeTenant,
          identityDocumentCode: data.dniNumberTenant,
          estimatedMoveDate: data.estimatedMoveDate,
        },
      },
    });
  };

  const [submitAppointmentError, setSubmitAppointmentError] = useState(null);

  const checkAvailabilitySlotsWeek = () => {
    const isAnyScheduleEnableOnGeneralSlots = generalAvailableSlots.filter((el) => el.slots.length > 0).length > 0;
    const isAnyScheduleEnableOnRecommendatesSlots =
      recommendatesAvailableSlots.filter((el) => el.slots.length > 0).length > 0;

    return !!estateAvailability && isAnyScheduleEnableOnGeneralSlots && isAnyScheduleEnableOnRecommendatesSlots;
  };

  const isBrowser = typeof window !== 'undefined';

  const handleBackBtnClick = () => {
    if (isBrowser) {
      window.location.href = `https://${env.urlAptuno}/inmueble/${Number(estateLegalId)}`;
    }
  };

  useEffect(() => {
    if (!!estateAvailability && !!estateAvailability.estateVisitAvailability) {
      const { availability, recommended } = estateAvailability.estateVisitAvailability;

      const filterEnableSlots = (weekCalendar) => {
        return weekCalendar.map((el) => {
          return {
            ...el,
            slots: el.slots.filter((slot) => (slot.enabled && slot.date >= fromAvailableDate) || slot.confirmed),
          };
        });
      };

      const recommendatesAvailableSlots = filterEnableSlots(recommended);
      setRecommendatesAvailableSlots(recommendatesAvailableSlots);

      const generalAvailableSlots = filterEnableSlots(availability);
      setGeneralAvailableSlots(generalAvailableSlots);
    }
  }, [estateAvailability]);

  return (
    <>
      <GlobalStyle />
      <NavbarSimple
        title={`Agendar visita en ${dataGetProperty?.property.title || '...'}`}
        isPublish={false}
        onBackButton={() => handleBackBtnClick()}
        onClosePersonalized={() => handleBackBtnClick()}
      />
      <ProcessContainer>
        {currentStep <= stepsData.length - 1 && <Stepper steps={stepsData} activeStep={currentStep} />}
      </ProcessContainer>
      <Container>
        {estateAvailabilityLoaded && !checkAvailabilitySlotsWeek() && (
          <Disclaimer>
            <DateTimeLabel>¡Lo sentimos!</DateTimeLabel>
            <Text>No hay disponibilidad para el inmueble seleccionado.</Text>
            <ActionsWrapper>
              <AptunoButton size={'big'} onClick={() => handleBackBtnClick()}>
                Aceptar
              </AptunoButton>
            </ActionsWrapper>
          </Disclaimer>
        )}
        {checkAvailabilitySlotsWeek() && !submitAppointmentError && (
          <>
            {currentStep === 0 && (
              <AppointmentDateAndTimeSelector
                onPrevius={toPreviousStep}
                onNext={toNextStep}
                generalSlots={generalAvailableSlots}
                recommendationsSlots={recommendatesAvailableSlots}
                onDateAndTimeSelected={({ datetime }) => {
                  setSelectedDate(datetime);
                }}
                legalId={dataGetProperty.property.propertyLegalId}
              />
            )}
            {currentStep === 1 && (
              <AppointmentUserDataForm
                editDisabled={!isUserInfoEditionEnabled}
                userData={tenantData?.getTenantForAuthenticatedUser || user.data}
                tenantAssistantData={visitAssistant}
                tenantIsAssistant={(value) => setTenantIsAssistant(value)}
                onPrevius={toPreviousStep}
                onSubmit={onAppointmentUserSubmited}
                isLoading={loadingTenantEdit || isLoadingSignup || isSubmitingAppointment}
              />
            )}
            {currentStep === 2 && (
              <OTPValidator
                isLoading={isLoadingSignup}
                authChannel={authChannel}
                country={country}
                phoneNumber={appointmentData.phoneNumberTenant}
                onValidate={() => getTenant()}
                onRetry={handleOtpRetry}
                nameSource={'scheduling'}
                leadOrigin="AUTOPUBLISH"
              />
            )}
          </>
        )}
        {!!submitAppointmentError && (
          <>
            <Disclaimer alignCenter>
              <ImageHeader src={'/icons/cancel.svg'} alt="Icono Cancelar" title="Icono Cancelar | Aptuno" />
              <DateTimeLabel>{submitAppointmentError.title}</DateTimeLabel>
              <Text>{submitAppointmentError.text}</Text>
            </Disclaimer>
            <ActionsWrapper>
              <AptunoButton
                onClick={() => {
                  toFirstStep();
                  setSubmitAppointmentError(null);
                  refetchEstateAvailability();
                }}
              >
                Seleccionar una fecha diferente
              </AptunoButton>
            </ActionsWrapper>
          </>
        )}
      </Container>
    </>
  );
};

export default Appointment;
