export type StandardRawUrlData = {
  page: StandardRawUrlDataPage;
};

export type StandardRawUrlDataPage = {
  path: string;
  url: string;
};

export const standardRawUrlDataFromLocation = (): StandardRawUrlData => {
  const currentUrl = window.location;
  return {
    page: {
      path: currentUrl.pathname,
      url: currentUrl.href,
    },
  };
};

export const standardRawUrlDataFromPath = (path?: string, url?: string): StandardRawUrlData => {
  return {
    page: {
      path: path ? path : '',
      url: url ? url : '',
    },
  };
};
