import styled, { keyframes, css } from 'styled-components';

const animation = keyframes`
 0% { height: 75px; width: 90px; }
 100% { height: 75px; width: 230px; }
 `;

const hoverBehavior = css`
  width: 230px;
  height: 75px;
  animation-name: ${animation};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
`;

const FloatingButton = styled.button`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  width: 72px;
  height: 72px;
  bottom: 120px;
  right: 18px;
  right: ${(props) => (props.isMobile ? null : 'calc(50% - 400px)')};
  background-color: #fe784b;
  color: #ffffff;
  border: 0px solid #fe784b;
  border: 4px solid rgba(230, 237, 255, 0.24);
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.06);
  border-radius: 50px;
  cursor: pointer;
  z-index: 20;
  ${(props) => (props.isBigButton ? 'width: 230px' : null)};
  ${(props) => (props.isMouseOver ? hoverBehavior : null)}
`;

const ModalHeader = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  gap: 15px;
  z-index: 9;
  align-items: center;
  & > svg {
    color: #dee2e6;
    width: 30px;
    height: 30px;
  }
`;

const ModalTitle = styled.h1`
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.024em;
  color: #000000;
  margin: 0px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: auto;
  max-height: calc(100vh - 92px);
  width: calc(100% + 32px);
  margin-left: -16px;
`;

const Card = styled.div`
  max-width: 100%;
  max-height: 126px;
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.06);
  border-radius: 16px;
  padding: 36px 16px 24px 16px;
  position: relative;
  overflow: hidden;
  margin: 0px 16px;
`;

const CardTitle = styled.h1`
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.024em;
  color: #555a64;
`;

const CardDescription = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #6c757d;
`;

const Image = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
`;

const ContactInfoContainer = styled.div`
  margin: 0px;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.008em;
  color: #ffffff;
`;

const ContactInfo = styled.h4`
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
`;

const ContactPhoneNumber = styled.p`
  margin: 0px;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
`;

export {
  FloatingButton,
  ModalTitle,
  ModalHeader,
  ModalContent,
  Card,
  Image,
  CardTitle,
  CardDescription,
  ContactInfoContainer,
  ContactInfo,
  ContactPhoneNumber,
};
