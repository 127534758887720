import React, { useContext, useEffect, useState } from 'react';
import {
  FloatingButton,
  ModalTitle,
  ModalHeader,
  ModalContent,
  Card,
  Image,
  CardDescription,
  CardTitle,
  ContactInfoContainer,
  ContactInfo,
  ContactPhoneNumber,
} from './CallSupport.styled';
import { Modal, Icon, AptunoButton } from '@aptuno/aptuno-ui';
import { responsiveContext } from '../../App';
import { env } from '../../environment/environment';
import { useAnalyticsTracker } from '../../services/analytics';
import { CallSupportRequested } from '../../Context/Publication/events/call-support-requested.event';

const CallModal = ({ modalState }) => {
  const [isModalOpen, setModalOpen] = modalState;
  const eventTracker = useAnalyticsTracker();

  const { responsive } = useContext(responsiveContext);

  const handleAnalitycsExclusivityPage = () => {
    eventTracker.trackEvent(new CallSupportRequested());
  };

  const handleCallClick = () => {
    handleAnalitycsExclusivityPage();
    window.open(`tel:${env.landlordsSupportPhone}`);
  };

  return (
    <Modal
      isModalBottom={responsive.isMobile}
      header={
        <ModalHeader>
          <Icon width={'30px'} height={'30px'} icon="minus" />
          <ModalTitle>¿Quieres hablar con un asesor?</ModalTitle>
        </ModalHeader>
      }
      size={600}
      isActive={isModalOpen}
      onClose={() => setModalOpen(false)}
    >
      <ModalContent>
        <Card>
          <Image src="/images/autopublish/click-to-call.svg" />
          <CardTitle>¡Descomplícate!</CardTitle>
          <CardDescription>
            Llámanos al {env.landlordsSupportPhone} y nosotros nos encargamos de tu publicación{' '}
          </CardDescription>
        </Card>
        {responsive.isMobile && <AptunoButton onClick={handleCallClick}>Llamar a un asesor</AptunoButton>}
      </ModalContent>
    </Modal>
  );
};

const CallSupport = () => {
  const [showModal, setShowModal] = useState(false);
  const [isShowbuttonInfo, showbuttonInfo] = useState(false);
  const { responsive } = useContext(responsiveContext);

  const handleClick = () => setShowModal(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      showbuttonInfo(true);
    }, 15000);

    return () => clearTimeout(timeout);
  }, []);

  if (showModal) return <CallModal modalState={[showModal, setShowModal]} />;

  return (
    <FloatingButton onClick={handleClick} isMobile={responsive.isMobile} isMouseOver={isShowbuttonInfo}>
      <Icon icon="phoneringing" width={'30px'} height={'30px'} />
      {isShowbuttonInfo && (
        <ContactInfoContainer>
          <ContactInfo>¿Tienes dudas? ¡Llámanos!</ContactInfo>
          <ContactPhoneNumber>{env.landlordsSupportPhone}</ContactPhoneNumber>
        </ContactInfoContainer>
      )}
    </FloatingButton>
  );
};

export default CallSupport;
