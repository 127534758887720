export const ErrorsTypes = {
  THERE_IS_NOT_TENANT_WITH_SPECIFIED_PHONE: {
    title: 'Lo sentimos',
    text: 'El usuario no ha sido encontrado',
    btnText: 'Aceptar',
  },
  UNAVAILABLE_VISIT_DATE: {
    title: 'Lo sentimos',
    text: 'La fecha y hora seleccionada ya no se encuentra disponible.',
    btnText: 'Aceptar',
  },
  DEFAULT: { title: '¡Lo sentimos!', text: 'Ha ocurrido un error', btnText: 'Aceptar' },
  INVALID_USER_STATUS_OR_ROLE: {
    title: '¿Eres un agente?',
    text: `En este momento no estás autorizado para recibir visitas. Si crees que esto es un error, por favor escríbenos a ayuda@aptuno.com.
  `,
    btnText: 'Entendido',
  },
  INVALID_JSON_WEB_TOKEN: {
    title: 'Oh, no. Algo salió mal.',
    text: `Lo sentimos, parece que algo no funcionó bien de nuestro lado. Por favor intenta de nuevo más tarde.`,
    btnText: 'Entendido',
  },
  INVALID_APPOINTMENT_STATUS_FOR_VISIT_COMPANION_CONFIRMATION: {
    title: '¡Lo sentimos!',
    text: 'Ésta visita ya fue confirmada por otro agente. Te avisaremos cuando existan más visitas disponibles.',
    btnText: 'Aceptar',
  },
  APPOINTMENTS_WITH_STATUS_FOR_MULTIPLE_CONFIRMATION_NOT_FOUND: {
    title: '¡Lo sentimos!',
    text: 'Ésta visita ya fue confirmada por otro agente. Te avisaremos cuando existan más visitas disponibles.',
    btnText: 'Aceptar',
  },
  USER_PHONE_VALIDATION_FOR_OTP_VERIFICATION_NOT_FOUND: {
    title: '¡Lo sentimos!',
    text: 'Código incorrecto',
    btnText: 'Aceptar',
  },
  INVALID_VISIT_COMPANION_AGENT_USER: {
    title: '¡Lo sentimos!',
    text: 'Parece que estás intentando ingresar a la plataforma como un agente, pero no tienes permiso para hacerlo',
    btnText: 'Aceptar',
  },
  ESTATE_IN_FINAL_STATUS: {
    title: '¡Lo sentimos!',
    text: 'El inmueble que estás intentando arrendar ya fue tomado por otro usuario. Te invitamos a buscar otra opción en nuestro inventario',
    btnText: 'Aceptar',
  },
  UNAVAILABLE_VISIT_COMPANION_AGENT_AT_REQUESTED_VISIT_DATE: {
    title: 'Lo sentimos',
    text: 'Ya tienes agendado una visita para ésta hora',
    btnText: 'Aceptar',
  },
};

