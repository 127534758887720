import { useLazyQuery } from '@apollo/client';
import { EstateId } from '../../../../../share/estate/estate';
import { PublicationCoverage } from '../../../model/estate-publication-coverage.model';
import { schema as GetEstatePublicationCoverageSchema } from '../schemas/get-estate-publication-coverage.schema';
import {
  GqlPublicationCoverageQuery,
  GqlPublicationCoverageQueryVariables,
} from '../../../../Shared/integrations/gql/core/generated-types';

const isEstatePublicationCovered = (estatePublicationCoverage: PublicationCoverage) =>
  PublicationCoverage.COVERED === estatePublicationCoverage;

export const useEstatePublicationCoverage = () => {
  const [execute, { data }] = useLazyQuery<GqlPublicationCoverageQuery, GqlPublicationCoverageQueryVariables>(
    GetEstatePublicationCoverageSchema
  );

  return {
    estatePublicationCoverage: data?.publicationCoverage,

    getEstatePublicationCoverage: async (estateId: EstateId): Promise<PublicationCoverage> => {
      const variables: GqlPublicationCoverageQueryVariables = { estateId: estateId.value };

      return execute({ variables }).then((response) => response!.data!.publicationCoverage);
    },
  };
};

export const useIsEstatePublicationCovered = () => {
  const { getEstatePublicationCoverage } = useEstatePublicationCoverage();

  return async (estateId: EstateId): Promise<boolean> =>
    getEstatePublicationCoverage(estateId).then(isEstatePublicationCovered);
};
