import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  padding: 16px;
  margin: auto;
  justify-items: center;
  max-width: 460px;
  margin-top: 2%;

  ${props =>
    props.responsive.isMobile &&
    `margin-top: 20%;
  `}
`;

const BoxImage = styled.div`
  margin-bottom: 32px;
  img {
    max-width: 250px;
  }
`;

const Image = styled.img`
  max-height: 250px;
`;
const TitleText = styled.span`
  font-family: Satoshi;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: -0.024em;
  color: #000000;
  max-width: 328px;
  text-align: center;
`;

const MessageText = styled.span`
  font-family: Satoshi;
  font-size: 14px;
  line-height: 20px;
  color: #6c757d;
  margin-top: 20px;
  margin-bottom: 24px;
  max-width: 300px;
  text-align: center;
`;

const HomeButton = styled.div`
  width: 100%;
  margin-bottom: 10px;

  ${props =>
    props.responsive.isMobile &&
    `
    margin-top: 30px;
    padding: 0px 18px 0px 18px;
  `}
`;

export { BoxImage, Container, HomeButton, Image, MessageText, TitleText };
