import { Alert, AptunoButton, Icon } from '@aptuno/aptuno-ui';
import { uuid4 as uuid } from '@sentry/utils';
import React, { useEffect, useState } from 'react';
import { getDayName, getStringDateTime, longToDateDay, longToDateMonth, longToTime } from '../../helpers/dateTimes';
import { useAnalyticsTracker } from '../../services/analytics';
import {
  ActionsWrapper,
  AlertContainer,
  ConfirmedTimeBox,
  DateBox,
  DateSelectorWrapper,
  DateSlot,
  DateTimeLabel,
  DayNum,
  DayOfWeek,
  LabelInformative,
  LabelInformativeLeft,
  LabelInformativeRight,
  LinkToStep,
  ShowAnotherOptions,
  Text,
  TimeBox,
  TimeBoxDescription,
  TimeBoxTextWrapper,
  TimeBoxTitle,
  TimeSlot,
} from './Appointment.styled';
import { GeneralScheduleViewedEvent } from './events/general-schedule-viewed.event';
import { VisitDateSelected } from './events/visit-date-selected.event';

const NoSlotAvailability = ({ onPrevius }) => (
  <>
    <DateTimeLabel>¡Lo sentimos!</DateTimeLabel>
    <Text>
      No hay disponibilidad para el tipo de visita seleccionado. <br />
      <LinkToStep onClick={onPrevius}>Intenta con otro tipo de visita</LinkToStep>
    </Text>
  </>
);

const AppointmentDateAndTimeSelector = ({
  generalSlots = [],
  recommendationsSlots = [],
  onPrevius,
  onDateAndTimeSelected,
  onNext,
  legalId,
}) => {
  const getFirstDayWithSlots = () => selectedSlots.find((item) => item.slots.length > 0);

  const nextStepSchedulling = () => {
    const dateFormatted =
      getDayName(selectedDay.weekDay) + '/' + longToDateDay(selectedDay.date) + '/' + longToDateMonth(selectedDay.date);
    const timeFormatted = longToTime(selectedTime.date);

    eventTracker.trackEvent(new VisitDateSelected(legalId, dateFormatted, timeFormatted));

    onNext();
  };

  const [selectedSlots, setSelectedSlots] = useState(recommendationsSlots);
  const [confirmedSlots, setConfirmedSlots] = useState([]);
  const [isRecommendationsSelected, setIsRecommendationsSelected] = useState(true);
  const [selectedDay, setSelectedDay] = useState(getFirstDayWithSlots());
  const [selectedTime, setSelectedTime] = useState(undefined);

  const eventTracker = useAnalyticsTracker();

  const tracker = useAnalyticsTracker();

  useEffect(() => {
    if (isRecommendationsSelected) {
      setSelectedSlots(recommendationsSlots);
    } else {
      setSelectedSlots(generalSlots);
    }
  }, [isRecommendationsSelected]);

  useEffect(() => {
    const confirmedSlots = selectedSlots.flatMap((item) => item.slots).filter((slot) => slot.confirmed);
    setConfirmedSlots(confirmedSlots);
    setSelectedDay(getFirstDayWithSlots());
    setSelectedTime(undefined);
  }, [selectedSlots]);

  const handleShowAnotherOptionsClick = () => {
    setIsRecommendationsSelected(!isRecommendationsSelected);
    if (isRecommendationsSelected) {
      tracker.trackEvent(new GeneralScheduleViewedEvent());
    }
  };

  return (
    <DateSelectorWrapper>
      {generalSlots.length === 0 && recommendationsSlots.length === 0 && <NoSlotAvailability onPrevius={onPrevius} />}

      {selectedSlots.length > 0 && (
        <>
          {!isRecommendationsSelected && confirmedSlots.length > 0 && (
            <>
              <TimeBoxTextWrapper>
                <TimeBoxTitle>Horarios confirmados:</TimeBoxTitle>
                <TimeBoxDescription>Estos horarios tienen un cupo asegurado para tu visita.</TimeBoxDescription>
              </TimeBoxTextWrapper>
              <ConfirmedTimeBox>
                {confirmedSlots.map((slot) => (
                  <TimeSlot
                    key={uuid()}
                    onClick={() => {
                      onDateAndTimeSelected({
                        datetime: slot.date,
                        source: isRecommendationsSelected ? 'RECOMMENDATIONS' : 'GENERALS',
                      });
                      setSelectedTime(slot);
                    }}
                    active={!!selectedTime && selectedTime.date === slot.date}
                    isConfirmed={slot.confirmed}
                  >
                    <Icon icon={'time'} aria-label={'time'} />
                    <Text>{getStringDateTime(new Date(slot.date))}</Text>
                  </TimeSlot>
                ))}
              </ConfirmedTimeBox>
            </>
          )}
          <TimeBoxTextWrapper>
            <TimeBoxTitle>{isRecommendationsSelected ? 'Recomendados' : 'Horarios por confirmar'}:</TimeBoxTitle>
            <TimeBoxDescription>
              {isRecommendationsSelected
                ? 'Te recomendamos estos horarios'
                : 'Si eliges alguno de estos horarios, contactaremos al propietario para confirmar su disponibilidad'}
              .
            </TimeBoxDescription>
          </TimeBoxTextWrapper>
          <DateBox>
            {selectedSlots.map((slot) => (
              <DateSlot
                key={uuid()}
                onClick={() => {
                  setSelectedDay(slot);
                  setSelectedTime(undefined);
                }}
                active={!!selectedDay && slot.weekDay === selectedDay.weekDay && slot.date === selectedDay.date}
              >
                <DayOfWeek>{getDayName(slot.weekDay)}</DayOfWeek>
                <DayNum>{longToDateDay(slot.date)}</DayNum>
              </DateSlot>
            ))}
          </DateBox>

          {!selectedDay && <DateTimeLabel>¡Selecciona un día!</DateTimeLabel>}
          {!!selectedDay && selectedDay.slots.length === 0 && (
            <AlertContainer>
              <Alert icon="warning" color="#FFF1ED" iconColor="#FF5B25">
                Parece que no hay disponibilidad para este día, por favor intenta con otro.
              </Alert>
            </AlertContainer>
          )}
          {!!selectedDay && selectedDay.slots.length > 0 && (
            <TimeBox>
              {selectedDay.slots.map((slot) => (
                <TimeSlot
                  key={uuid()}
                  onClick={() => {
                    onDateAndTimeSelected({
                      datetime: slot.date,
                      source: isRecommendationsSelected ? 'RECOMMENDATIONS' : 'GENERALS',
                    });
                    setSelectedTime(slot);
                  }}
                  active={!!selectedTime && selectedTime.date === slot.date}
                  isConfirmed={!isRecommendationsSelected && slot.confirmed}
                >
                  <Icon icon={'time'} aria-label={'time'} />
                  <Text>{longToTime(slot.date)}</Text>
                </TimeSlot>
              ))}
            </TimeBox>
          )}
          {!!selectedDay && !!selectedTime && (
            <>
              <br />
              <DateTimeLabel>
                {getDayName(selectedDay.weekDay)} {longToDateDay(selectedDay.date)} de{' '}
                {longToDateMonth(selectedDay.date)} {selectedTime.date ? `- ${longToTime(selectedTime.date)}` : ''}
              </DateTimeLabel>
            </>
          )}
          {!isRecommendationsSelected && confirmedSlots.length > 0 && (
            <>
              <br />
              <LabelInformative>
                <LabelInformativeLeft>
                  <Icon icon={'time'} />
                </LabelInformativeLeft>
                <LabelInformativeRight>
                  Los espacios con este color han sido confirmados por el propietario.
                </LabelInformativeRight>
              </LabelInformative>
            </>
          )}
          <br />
          <ShowAnotherOptions onClick={() => handleShowAnotherOptionsClick()}>
            {isRecommendationsSelected ? (
              <span>
                <Icon icon={'arrowdown'} aria-label={'arrowdown'} /> No me sirven estas opciones
              </span>
            ) : (
              <span>
                <Icon icon={'arrowup'} aria-label={'arrowup'} /> Volver a horarios recomendados
              </span>
            )}
          </ShowAnotherOptions>
        </>
      )}
      <ActionsWrapper>
        <AptunoButton appearance={'secondary'} onClick={() => onPrevius()}>
          <Icon icon={'arrowleftalt'} />
          Atrás
        </AptunoButton>
        <AptunoButton
          disabled={!(!!selectedDay && !!selectedTime && selectedDay.date && selectedTime.date)}
          onClick={nextStepSchedulling}
        >
          Siguiente
        </AptunoButton>
      </ActionsWrapper>
    </DateSelectorWrapper>
  );
};

export default AppointmentDateAndTimeSelector;
