import React from 'react';
import { Modal, Icon } from '@aptuno/aptuno-ui';

import { ModalTitle, ModalHeader, ModalContent } from './ModalBase.styled';

const ModalBase = ({ modalState, title, children }) => {
  const [isModalOpen, setModalOpen] = modalState;

  return (
    <Modal
      header={
        <ModalHeader>
          <Icon icon="arrowleftalt" width="15px" height="15px" onClick={() => setModalOpen(false)} />
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
      }
      size={600}
      isActive={isModalOpen}
      onClose={() => setModalOpen(false)}
    >
      <ModalContent>{children}</ModalContent>
    </Modal>
  );
};

export default ModalBase;
