import { useQuery } from '@apollo/client/react/hooks';
import { useMemo } from 'react';
import gql from 'graphql-tag';
import { GeneralEstatePublicationQualityIndex } from '@aptuno/aptuno-shared/lib/estate-publication-quality';
import { env } from '../environment/environment';

export const GET_GENERAL_ESTATE_PUBLICATION_QUALITY_INDEX = gql`
  query {
    getGeneralEstatePublicationQualityIndex {
      addressFormat
      amountOfPictures {
        value
        allowedDeviation
      }
      descriptionLength {
        value
        allowedDeviation
      }
    }
  }
`;

export const useEstatePublicationQualityIndex = () => {
  const { data: generalPublicationQualityIndexDto } = useQuery(GET_GENERAL_ESTATE_PUBLICATION_QUALITY_INDEX, {
    fetchPolicy: 'cache-first',
  });

  const generalPublicationQualityIndex = useMemo(
    () =>
      generalPublicationQualityIndexDto?.getGeneralEstatePublicationQualityIndex ||
      env.defaultGeneralEstatePublicationQualityIndex,
    [generalPublicationQualityIndexDto]
  );

  const qualityIndexValidator = useMemo(
    () => GeneralEstatePublicationQualityIndex(generalPublicationQualityIndex),
    [generalPublicationQualityIndex]
  );

  return { qualityIndexValidator, generalPublicationQualityIndex };
};
