import React from 'react';
import { NavbarSimpleContainer, Image, NavbarSimpleStyle } from './NavbarAptuno.styled';

const NavbarSimple = () => {
  return (
    <NavbarSimpleContainer>
      <NavbarSimpleStyle>
        <Image src="/logo.png" alt="Logo aptuno" title="Logo | Aptuno" />
      </NavbarSimpleStyle>
    </NavbarSimpleContainer>
  );
};

export default NavbarSimple;
