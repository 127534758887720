const getDayName = (dayName) => {
  switch (dayName.toUpperCase()) {
    case "SUNDAY":
      return "Dom";
    case "MONDAY":
      return "Lun";
    case "TUESDAY":
      return "Mar";
    case "WEDNESDAY":
      return "Mie";
    case "THURSDAY":
      return "Jue";
    case "FRIDAY":
      return "Vie";
    case "SATURDAY":
      return "Sab";
    default:
      return "";
  }
};

const WEEK_DAYS = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miercoles",
  "Jueves",
  "Viernes",
  "Sabado",
];

const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const longToDateDay = (date) => {
  let parsedDate =  new Date(date).getDate();
  if(parsedDate < 10)  { parsedDate = '0' + parsedDate }
  
  return parsedDate;
};

const longToWeekDay = (date) => {
  return WEEK_DAYS[new Date(date).getDay()];
};

const longToDateMonth = (date) => {
  return MONTHS[new Date(date).getMonth()];
};

const longToTime = (date) => {
  const parsedDate = new Date(date);
  return `${parsedDate.toLocaleTimeString('es-CO', { hour: '2-digit', minute: '2-digit' })}`;
};

const parseDate = (date) => {
  let day = date.getDate();
  let month = date.getMonth();

  if(day < 10)  { day = '0' + day }
  if(month < 10)  { month = '0' + (month + 1) }
  return `${day}/${month}/${date.getFullYear()}`
}

const getStringDateTime = (date) => {
  let day = date.getDate();
  let month = longToDateMonth(date);
  return `${day} ${month} · ${longToTime(date)}`
}

const getArrayBetweenTimes = (start, end)  =>{
  // Convert to number of half-hours
  start = parseInt(start) * 2 + (+start.slice(-2) > 0);
  end = parseInt(end) * 2 + (+end.slice(-2) > 0) + 1;
  // Produce series
  return Array.from({ length: end - start }, (_, i) =>
    (((i + start) >> 1) + ':' + ((i + start) % 2) * 3 + '0').replace(/^\d:/, '0$&')
  );
}

const formattedTimeISO = timeUnformatted => {
  const hour = timeUnformatted.split(':')[0];
  const minutes = timeUnformatted.split(':')[1];
  return new Date('', '', '', hour, minutes).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const roundTimeQuarterHour = time => {
  var timeToReturn = new Date(time);

  timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
  timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 30) * 30);
  return timeToReturn;
};

const nextTimeAvailable = (numOfHours = 18, date = new Date()) => {
  date.setTime(date.getTime() + numOfHours * 30 * 60 * 1000);
  return roundTimeQuarterHour(date);
};

export {
  MONTHS,
  longToWeekDay,
  getDayName,
  longToDateDay,
  longToDateMonth,
  longToTime,
  parseDate,
  getStringDateTime,
  getArrayBetweenTimes,
  formattedTimeISO,
  roundTimeQuarterHour,
  nextTimeAvailable,
};
