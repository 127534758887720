import { COUNTRY_CODE } from '../constants';
import { env } from '../environment/environment';

export const isColombia = () => env.countryCode === COUNTRY_CODE.COLOMBIA;

type Props = {
  countries: string[];
  children?: any;
};

export const RenderOnlyInCountries = ({ countries, children }: Props) => {
  const currentCountry = env.countryCode;
  return countries.includes(currentCountry) ? children : null;
};
