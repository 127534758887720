import { GlobalStyle, SIZES, Theme, VARIANTS } from '@aptuno/aptuno-ui';
import React, { useEffect, useState } from 'react';
import { getLocalStorageItem, setLocalStorageItem } from '../../helpers/localStorage';
import { useAnalyticsTracker } from '../../services/analytics/useAnalyticsTracker';
import Portal from '../Portal';
import { CookiesBoxBtn, CookiesBoxText, CookiesBoxWrapper, CookiesTitle } from './CookiesBox.styled';
import { CookiesAccepted } from './events/cookies-accepted.event';

type CookiesBoxProps = {
  urlLink: string;
  bgColor?: string;
  textColor?: string;
};

const CookiesBox = ({ urlLink, bgColor, textColor }: CookiesBoxProps) => {
  const [isAccepted, setIsAccepted] = useState(JSON.parse(getLocalStorageItem('userAcceptedCookies') || 'false'));
  const eventTracker = useAnalyticsTracker();

  useEffect(() => {
    if (isAccepted) {
      eventTracker.trackEvent(new CookiesAccepted());
      setLocalStorageItem('userAcceptedCookies', 'true');
    }
  }, [isAccepted]);

  const handleOnAccept = () => {
    setIsAccepted(true);
  };
  return (
    <Portal>
      <GlobalStyle />
      <Theme colorModes="light">
        {!isAccepted && (
          <>
            <CookiesBoxWrapper bgColor={bgColor} textColor={textColor}>
              <CookiesTitle>Un aviso sobre nuestras cookies &#127850;</CookiesTitle>
              <CookiesBoxText>
                Al navegar este sitio, estás aceptando el uso de cookies y la información y los datos que obtengamos
                serán usados de acuerdo a nuestra{' '}
                <a href={urlLink} rel="noopener noreferrer" target="_blank">
                  política de privacidad y tratamiento de datos.
                </a>
              </CookiesBoxText>
              <CookiesBoxBtn
                appearance={VARIANTS.SECONDARY}
                size={SIZES.BIGGEST}
                fitHeight
                fitWidth
                onClick={handleOnAccept}
              >
                Aceptar
              </CookiesBoxBtn>
            </CookiesBoxWrapper>
          </>
        )}
      </Theme>
    </Portal>
  );
};

export { CookiesBox };
