import * as React from 'react';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { useFirebase } from '../../FirebaseProvider';
import { LoggedInUserStatus, useLoggedInUser, useUpdateSessionToken } from '../../../Context/Auth/SessionProvider';
import history from '../../../history';

export function EmailLoginScreen() {
  const [isLoading, setIsLoading] = React.useState(true);
  const updateSessionToken = useUpdateSessionToken();
  const loggedInUser = useLoggedInUser();

  const { auth } = useFirebase();

  React.useEffect(() => {
    const isSignInWithEmail = isSignInWithEmailLink(auth, window.location.href);
    console.log('isSignInWithEmail', isSignInWithEmail);
    if (isSignInWithEmail) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (email) {
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            //window.localStorage.removeItem('emailForSignIn');
            console.log('result', result);

            //todo: should we call this function on a http client middleware?
            result.user.getIdToken(true).then((token) => {
              console.log('token', token);

              if (typeof updateSessionToken === 'function') {
                updateSessionToken({
                  kind: 'SET',
                  payload: token,
                });
              }
            });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [auth]);

  React.useEffect(() => {
    if (LoggedInUserStatus.LOGGED_IN === loggedInUser.status) {
      history.push('/perfil');
    }
  }, [loggedInUser]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>EmailLoginScreen</h1>
    </div>
  );
}
