import styled from 'styled-components';

const OtpInputDescription = styled.p`
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #6c757d;
`;

const OtpInputTitle = styled.h2`
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.024em;
  color: #000000;
`;

export { OtpInputDescription, OtpInputTitle };
