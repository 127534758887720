import React, { createContext, lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import Seo from '../../components/Seo';
import { TitleDesktop, TitleMobile } from '../../constants/autopublish';
import lsTokens from '../../data/local-storage-tokens';
import { useUtmInfo } from '../../hooks/useUtmInfo';
import { useAnalyticsTracker } from '../../services/analytics';
import { getSeoForPage } from '../../services/seo';
import { PAGE_CATEGORY } from '../../services/url';
import { PublicationStarted } from './events/publicantion-started';
import ExclusivitySelectPage from './ExclusivitySelectPage/ExclusivitySelectPage';
import NotResidentialRentPage from './NotResidentialRentPage/NotResidentialRentPage';
import { useLoggedInUser } from '../../Context/Auth/SessionProvider';

const CreateLandlordPage = lazy(() => import('./CreateLandlordPage/CreateLandlordPage'));
const AutoPublishTypePage = lazy(() => import('./AutoPublishTypePage/AutoPublishTypePage'));
const LocationSelectPage = lazy(() => import('./LocationSelectPage/LocationSelectPage'));
const EstateBasicInfoPage = lazy(() => import('./EstateBasicInfoPage/EstateBasicInfoPage'));
const EstateExtraInfoPage = lazy(() => import('./EstateExtraInfoPage/EstateExtraInfoPage'));
const EstatePricingPage = lazy(() => import('./EstatePricingPage/EstatePricingPage'));
const EstatePhotosPage = lazy(() => import('./EstatePhotosPage/EstatePhotosPage'));
const TermsAndConditions = lazy(() => import('./TermsAndConditions/TermsAndConditions'));
const PublishSuccess = lazy(() => import('./ModalPublish/PublishSuccess/PublishSuccess'));

export const publishEstate = createContext();

export const estatePublishSteps = () => {
  const IsMobile = window.screen.width <= 800;
  return [
    { title: 'Comencemos', description: TitleMobile[0] },
    { title: 'Confirma la dirección', description: IsMobile ? TitleMobile[1] : TitleDesktop[0] },
    { title: 'Información general', description: IsMobile ? TitleMobile[2] : TitleDesktop[1] },
    { title: 'Información adicional', description: TitleMobile[3] },
    { title: 'Detalles publicación', description: TitleMobile[4] },
    { title: 'Agregar fotos', description: TitleMobile[5] },
    { title: 'Escoge tu publicación', description: '' },
    { title: 'Escoge el mejor plan para ti.', description: TitleMobile[6] },
  ];
};

const EstatePublish = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const loggedInUser = useLoggedInUser();
  const [seo] = React.useState(() => getSeoForPage(t, PAGE_CATEGORY.HOME));
  const eventTracker = useAnalyticsTracker();
  const [estate, setEstate] = useState({
    publishType: {},
    benefits: {},
    location: {},
    files: {},
  });

  useEffect(() => {
    localStorage.setItem(lsTokens.publishFlow, JSON.stringify(estate));
  }, [estate]);

  useEffect(() => {
    eventTracker.trackEvent(new PublicationStarted(loggedInUser.data?.id)).finally(() => {
      const prevEstate = localStorage.getItem(lsTokens.publishFlow);
      if (prevEstate) setEstate(JSON.parse(prevEstate));
    });
  }, []);

  useUtmInfo();

  return (
    <publishEstate.Provider value={{ estate, setEstate }}>
      <Seo
        title={seo.title}
        description={seo.description}
        image={seo.image}
        url={seo.canonical || window.location.href}
      />
      <Route exact path={`${path}/`}>
        <Redirect to={`${path}/tipo-publicacion`} />
      </Route>

      <Route path={`${path}/crear-propietario`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <CreateLandlordPage />
        </Suspense>
      </Route>
      <Route path={`${path}/tipo-publicacion`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <AutoPublishTypePage />
        </Suspense>
      </Route>
      <Route path={`${path}/lo-sentimos`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <NotResidentialRentPage />
        </Suspense>
      </Route>
      <Route path={`${path}/seleccionar-ubicacion`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <LocationSelectPage />
        </Suspense>
      </Route>
      <Route path={`${path}/informacion-basica`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <EstateBasicInfoPage />
        </Suspense>
      </Route>
      <Route path={`${path}/informacion-adicional`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <EstateExtraInfoPage />
        </Suspense>
      </Route>
      <Route path={`${path}/informacion-canon`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <EstatePricingPage />
        </Suspense>
      </Route>
      <Route path={`${path}/informacion-fotos`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <EstatePhotosPage />
        </Suspense>
      </Route>
      <Route path={`${path}/informacion-exclusividad`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <ExclusivitySelectPage />
        </Suspense>
      </Route>
      <Route
        path={`${path}/informacion-plan/:estateId`}
        render={(props) => (
          <Suspense fallback={<div> Cargando ... </div>}>
            <TermsAndConditions {...props} />
          </Suspense>
        )}
      />
      <Route
        path={`${path}/informacion-plan/`}
        render={(props) => (
          <Suspense fallback={<div> Cargando ... </div>}>
            <TermsAndConditions {...props} />
          </Suspense>
        )}
      />
      <Route path={`${path}/publicacion-satisfactoria`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <PublishSuccess />
        </Suspense>
      </Route>
    </publishEstate.Provider>
  );
};

export default EstatePublish;
