import styled from 'styled-components';

export const MainTitle = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.024em;
  color: #000000;
`;

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px !important;
  color: #6c757d;
  margin-top: 0;
  margin-bottom: 1rem;
  padding-top: 8px;
  @media screen and (min-width: 768px) {
  }
`;
