import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { env } from './environment/environment';

const resources = {
  CO: {
    translation: {
      homeSearchPlaceholder: '¿A dónde quieres mudarte?',
      homeTitle: 'Busco apartamento o casa en arriendo {{location2Show}}',
      withoutComplications: 'Arrienda sin complicaciones',
      withoutComplicationsDesc:
        '¡Dile adiós a los procesos lentos y engorrosos! Aplica a nuestro estudio de crédito rápido y en 2 horas podrás conocer los resultados, si apruebas, no necesitarás más. ¡Es gratis!',
      easyQuicklyDesc:
        'Negocia el canon de arriendo, aplica y firma todo de manera digital. Sin formularios ni notarías.',
      homeSeoTitle: 'Apartamentos en Arriendo | Aptuno Colombia',
      homeSeoDescription:
        'Si buscas casas y apartamentos en Arriendo en Bogotá o Medellín, en Aptuno puedes encontrar o publicar el tuyo con todo el respaldo, rápido y sin fiador.',
      homeSeoH1: `"Busco apartamento o casa en arriendo en Bogot&aacute; o Medell&iacute;n"`,
      homeSeoCanonical: 'https://www.aptuno.com/',
      searchResultSeoTitle: '{{propertyTypeCapitalized}}s en arriendo en {{location}} sin fiador | Aptuno',
      searchResultSeoDescription:
        'Mas de {{propertiesCount}} {{propertyTypeLowerCase}}s en arriendo en {{location}}. Acesse a los mejores anuncios en arriendo de inmuebles en {{location}} sin fiador',
      searchResultSeoH1: '{{propertyTypeCapitalized}} en arriendo en {{location}}',
      detailPageSeoTitle:
        '{{propertyTypeCapitalized}} en arriendo en la {{address}}, {{location}}, por $ {{formattedPrice}} | Aptuno',
      detailPageSeoDescription:
        'Arriende apartamento sin fiador con {{bedrooms}} {{bedroomsText}} y {{}area} m² por {{formattedPrice}} mensuales en la {{address}} - {{location}} | Aptuno',
      detailPageSeoH1:
        '{{propertyTypeCapitalized}} con {{bedrooms}} {{bedroomsText}} para arrendar, {{area}} m² por $ {{formattedPrice}} al mes',
      autopublishRoute: 'publicar-inmueble-en-arriendo',
      autopublishRouteOLDforRedirect: 'publicar-inmueble-en-arriendo-gratis',
      rentCalculatorRoute: 'calculadora-de-arrendamiento/calcular',
      searchResultRoute: 'inmuebles-arriendo',
      adminIncluded: 'Administración incluida',
      toRent: 'para arrendar',
      brand: 'aptuno logo',
      iAmAgent: 'Soy Agente inmobiliario',
      estate: 'inmueble',
      fiscalAddressAptuno: 'Carrera 11 No. 79 - 66. Bogotá, Colombia.',
      priceBelowMin: 'El valor del canon de arrendamiento de tu inmueble es inferior a lo que podemos operar.',
      lease: 'arriendo',
    },
    whyRentAptuno: {
      guaranteedPay:
        'Recibe el canon del arriendo el día acordado dentro de los 7 primeros días del mes. Con nuestra garantía de arrendamiento. \n¡Olvídate de perseguir cobros!',
      title: '¿Por qué publicar tu inmueble con Aptuno?',
      leaseQuicklier: 'Arrienda más rápido',
      findNextTenant:
        'Minimiza la vacancia de tu inmueble y aumenta tu rentabilidad.' + '¡Encuentra tu próximo arrendatario aquí!',
      potencialTenantsEveryDay:
        'Programa una sesión de fotos gratuita, anuncia tu inmueble en nuestro portal y consigue potenciales arrendatarios todos los días. \n¡Arrienda con todas las garantías!',
    },
    basicInfoForm: {
      apartmentType: 'Apartamento',
      priceQuestion: 'Valor mensual del arriendo',
      priceLabel: '$ Ingresa aquí el valor en COP',
      adminPriceQuestion: 'Valor mensual de la administración',
      adminPriceLabel: '$ Ingresa aquí el valor en COP',
      amountOfRoomsQuestion: 'Número de habitaciones:',
      amountOfParkingLotsQuestion: 'Número de parqueaderos:',
      minPrice: 'Valor mínimo ${{min}}',
      maxPrice: 'Valor máximo ${{max}}',
      required: 'Es requerido',
      minAdminPrice: 'Valor mínimo ${{min}}',
      maxAdminPrice: 'Valor máximo ${{max}}',
    },
    locationSelectPage: {
      alertInfo:
        'Recuerda que solo publicaremos inmuebles residenciales en arriendo. No publicaremos habitaciones, locales, ' +
        'inmuebles en venta o cualquier otro tipo de clasificado.',
      descriptionExample:
        'Ejemplo: Apartamento iluminado de 60 mtrs. Cuenta con una habitación con closet, ' +
        'dos baños, zona de estudio, cocina integral tipo americano con estufa a gas, calentador ' +
        'a gas, zona de lavandería, zona social con sala comedor y un parqueadero privado (se aceptan' +
        ' mascotas, disponible a partir del 01 de diciembre). El edificio cuenta con ascensor, ' +
        'circuito cerrado de TV y servicio de vigilancia 24 horas. Cerca a Carrera 9, Calle 82 y Carrera Séptima.',
    },
    estatePriceSuggestion: {
      title: 'Este es nuestro estimado del valor de arriendo.',
    },
    uploadPhotosPage: {
      infoText:
        'Suba al menos 3 fotos para publicar el anuncio, los ' +
        'inmuebles que tienen entre 8 y 15 fotos tienen mayores probabilidades de ser arrendados!',
    },
    generalInfo: {
      leaseReportTitle: 'Reporte valor de arriendo',
    },
    estateInfo: {
      leaseReportTitle: 'Reporte valor de arriendo',
    },
    footer: {
      platformDesc: 'Aptuno, la plataforma de búsqueda de apartamentos y casas en arriendo en Colombia.',
      iAmTenant: 'Soy Residente',
      businessName: '© 2020 Aptuno S.A.S.',
    },
    loginLandlord: {
      tenants: 'arrendatarios',
      reduceVacancy: 'Minimiza la vacancia',
      lookingForYourIdealHome: 'Si estás buscando tu hogar ideal',
    },
    rentCalculatorForm: {
      bedrooms: 'Habitaciones',
      furnished: 'Amoblado',
      parkingLots: 'Parqueaderos',
      howCalculateRentValue: '¿Cómo calcular el valor de arriendo de una propiedad?',
      abilityToNegotiate:
        'Abrir la posibilidad de un debate frente al precio final de arrendamiento y escuchar las ofertas de los inquilinos.',
      setMinimum: 'Debes tener claro cuál es el precio mínimo por el cual estás dispuesto a arrendar tu propiedad. ',
      setAllItems:
        'Debes ser claro sobre los ítems que están incluidos en el precio del arriendo tales como: Servicios públicos, administración, parqueadero y demás que se puedan presentar en cada caso.',
    },
    rentcalculator: {
      title: '¡Calcula el costo de arrendamiento ideal!',
      calcDescription: 'sobre el precio del arriendo de tu inmueble',
      wishToLease: 'Si deseas arrendar,',
      whyRentAptuno: '¿Por qué publicar tu inmueble con Aptuno?',
      guaranteedPay:
        'Recibe el canon del arriendo el día acordado dentro de los 7 primeros días del mes. Con nuestra garantía de arrendamiento. \n¡Olvídate de perseguir cobros!',
      leaseQuicklier: 'Renta más rápido',
      findNextTenant:
        'Minimiza la vacancia de tu inmueble y aumenta tu rentabilidad. ¡Encuentra tu próximo arrendatario aquí!',
      potencialTenantsEveryDay:
        'Programa una sesión de fotos gratuita, anuncia tu inmueble en nuestro portal y consigue potenciales arrendatarios todos los días. \n¡Arrienda con todas las garantías!',
      seoTitle: 'Herramienta para Calcular Arrendamiento | Aptuno Colombia',
      seoDescription:
        'Con nuestra Herramienta para calcular el valor del canon de arrendamiento encontrarás cuánto deberías cobrar  para maximizar la rentabilidad de tu propiedad.',
      seoUrl: 'https://www.aptuno.com/calculadora-de-arrendamiento',
      titleArticle1: '¿Quién paga las reparaciones de una propiedad en arriendo?',
      urlArticle1: '/blog/post/quien-paga-las-reparaciones-de-una-propiedad-en-arriendo',
      titleArticle2: 'Tips para calcular el canon de arrendamiento adecuado',
      urlArticle2: '/blog/post/tips-para-calcular-el-canon-de-arrendamiento-adecuado',
      publishBannerDescription:
        'Te ayudamos a encontrar tu inquilino ideal y con el precio de arriendo óptimo para tu inmueble.',
    },
    profitsSection: {
      title: 'Asegúrate de pedir el canon óptimo de arriendo de tu inmueble',
      description:
        'Calculamos con algoritmos de inteligencia artificial su canon actual, con las características de su inmueble y el mercado en la zona para saber si tienes el precio correcto, sino lo ajustamos para conseguir el inquilino en menor tiempo.',
      vacancyTime: 'Minimiza vacancia',
    },
    rentReport: {
      title: 'de arriendo',
      leasePrice: 'de canon mensual',
      description:
        'Calculamos este valor con base a inmuebles comparables cerca a tu propiedad, la tendencia ' +
        'en los precios de los arriendos de los últimos 16 meses y la información que nos brindaste.',
      error: '¡Ups, algo salió mal!, vuelve calcular nuevamente el valor de arriendo',
      goToRentCalculator: 'Ir a calculadora de arriendo',
      withAdmonIncluded: 'de canon mensual con admon incluida.',
      bedrooms: 'habitaciones',
      shortbedrooms: 'hab',
      parking: 'parqueadero',
      shortParking: 'parq',
    },
    timeToRent: {
      mainSentence:
        '¿Sabías que el tiempo promedio para arrendar un apartamento es 4.5 meses colocando solo un aviso en la ventana?',
      timeEmpty:
        'Entre más tiempo esté vacía tu propiedad mayor será el costo que tendrás que asumir por administración y servicios.',
    },
    defaultNavbar: {
      rentCalculator: 'Calculadora de Arrendamiento',
    },
    mainTags: {
      furnished: 'Amoblado',
    },
    propertyDetails: {
      isCosignerNeeded: '¿Se necesita codeudor para este inmueble?',
      isCosignerNeededDesc:
        'Dependerá del resultado de tu evaluación. Con nuestro estudio de crédito ágil, necesitas tener tu documentación al día y no estar reportado en centrales de riesgo, en pocos minutos tendrás los resultados (Aplican TyC)',
      adminIncluded: 'incluye admon',
    },
    socialMedia: {
      facebook: 'https://www.facebook.com/aptuno/',
      instagram: 'https://www.instagram.com/aptuno/',
      linkedin: 'https://www.linkedin.com/company/aptuno/',
      twitter: 'https://twitter.com/aptuno/',
    },
    glossary: {
      bedroom: 'habitación',
      bedrooms: 'habitaciones',
      parking: 'parqueadero',
      parkings: 'parqueaderos',
      rent: 'canon',
      rentVerb: 'arrendar',
      lease: 'arriendo',
      administration: 'administración',
      administrationIncluded: 'administración incluida',
      apartment: 'departamento',
      apartments: 'departamentos',
      bbqArea: 'zona BBQ',
      pool: 'piscina',
      elevator: 'ascensor',
      elevators: 'ascensores',
      deposit: 'bodega',
      furnished: 'amoblado',
      furnisheds: 'amoblados',
      neighborhood: 'Barrio',
    },
    documentsLegal: {
      label: 'Tu número de documento',
    },
    contractModelDocument: {
      url: 'https://docs.google.com/document/d/1UW_-niMx7r3kVOu_YBp_-uDFaVqifgYK/edit',
    },
    blog: {
      seoTitle: 'Blog, Noticias y Tendencias sobre Arrendamientos | Aptuno',
      seoDescription:
        'Encuentra todo sobre noticias leyes y tendencias sobre todo lo referente a arriendos en Colombia para propietarios o inquilinos. Ingresa y conoce más.',
      seoUrl: 'https://www.aptuno.com/blog',
      seoImage:
        'https://images.ctfassets.net/bj5ota02hmze/2hzHfPvLkM6mzHyYqoIWAp/62fd623e9a4f98bc67f0b0ec85dc1d90/arriendo_plataforma_.jpg',
    },
    help: {
      seoTitle: 'Ayuda para Arrendadores e Inquilinos | Aptuno',
      seoDescription:
        'Recibe toda la información sobre nuestra plataforma al momento de poner en arriendo tu casa o apartamento o si eres inquino. Ingresa y resuelve tus dudas.',
      seoUrl: 'https://www.aptuno.com/ayuda',
      seoImage: 'https://www.aptuno.com/images/aptuno_logo.png',
    },
    aboutUs: {
      seoTitle: 'Plataforma para Arriendo de Casas y Apartamentos | Aptuno Colombia',
      seoDescription:
        'Aptuno es plataforma altamente técnológica que te ayudará a publicar tu casa o apartamento y arrendar en tiempo record. Conoce más de nosotros aquí.',
      seoUrl: 'https://www.aptuno.com/quienes-somos',
      seoImage: 'https://www.aptuno.com/images/aptuno_logo.png',
    },
    TCExclusivity: {
      company:
        'APTUNO SAS, sociedad comercial, identificada con el NIT 901.323.021-1 con sede en la Carrera 12a # 78-40 - We Work, Bogotá, Colombia (en adelante “Aptuno”).',
      publishEstateItem: 'Anunciar y/o promocionar en arrendamiento el inmueble.',
    },
    administrativeLevel: {
      city: 'Ciudad',
      country: 'País',
      coverage: 'Lugar',
      departament: 'Departamento',
      locality: 'Localidad',
      municipality: 'Ciudad',
      neighborhood: 'Barrio',
      park: 'Lugar',
      university: 'Lugar',
      upz: 'Barrio',
      zone: 'Lugar',
      station: 'Lugar',
      club: 'Lugar',
      mall: 'Lugar',
      medical_center: 'Lugar',
      museum: 'Lugar',
      poi: 'Lugar',
      school: 'Lugar',
      location_level_5: 'Departamento',
      location_level_9: 'Ciudad',
      location_level_10: 'Localidad',
      location_level_11: 'UPR',
      location_level_12: 'UPZ',
      location_level_13: 'Comuna',
      location_level_14: 'Corregimiento',
      location_level_15: 'Vereda',
    },
  },
  MX: {
    translation: {
      homeSearchPlaceholder: '¿A dónde quieres mudarte?',
      homeTitle: 'Busco departamento o casa en renta {{location2Show}}',
      withoutComplications: 'Renta sin complicaciones',
      withoutComplicationsDesc:
        '¡Dile adiós a los procesos lentos y engorrosos! Aplica a nuestra solicitud de renta rápido y en 2 horas podrás conocer los resultados, si apruebas, no necesitarás más. ¡Es gratis!',
      easyQuicklyDesc:
        'Negocia el costo de renta, aplica y firma todo de manera digital. Sin Aval y sin complicaciones.',
      homeSeoTitle: 'Departamentos en Renta | Aptuno México',
      homeSeoDescription:
        'Si buscas o quieres publicar casas o departamentos en renta en CDMX, en Aptuno encuentras la mejor solución con todo el respaldo, rápido, y sin aval.',
      homeSeoH1: `"Busco departamento o casa en arriendo en Ciudad de México"`,
      homeSeoCanonical: 'https://www.aptuno.mx/',
      searchResultSeoTitle: '{{propertyTypeCapitalized}}s en renta en {{location}} sin aval | Aptuno',
      searchResultSeoDescription:
        'Mas de {{propertiesCount}} {{propertyTypeLowerCase}}s para renta en {{location}}. Acesse a los mejores anuncios en renta de inmuebles en {{location}} sin aval',
      searchResultSeoH1: '{{propertyTypeCapitalized}} para renta en {{location}}',
      detailPageSeoTitle:
        '{{propertyTypeCapitalized}} para renta en {{address}}, {{location}}, por $ {{formattedPrice}} | Aptuno',
      detailPageSeoDescription:
        'Rente departamento sin aval con {{bedrooms}} {{bedroomsText}} y {{}area} m² por {{formattedPrice}} mensuales en {{address}} - {{location}} | Aptuno',
      detailPageSeoH1:
        '{{propertyTypeCapitalized}} con {{bedrooms}} {{bedroomsText}} para rentar, {{area}} m² por $ {{formattedPrice}} al mes',
      autopublishRoute: 'anunciar-casa-o-departamento-en-renta',
      autopublishRouteOLDforRedirect: 'anunciar-gratis-casa-o-departamento-en-renta',
      rentCalculatorRoute: 'calculadora-de-renta/calcular',
      searchResultRoute: 'inmuebles-renta',
      adminIncluded: 'Mantenimiento incluido',
      toRent: 'para arrendar',
      brand: 'aptuno logo',
      iAmAgent: 'Soy un corredor',
      estate: 'propiedad',
      fiscalAddressAptuno:
        'C. Montes Urales 424, Lomas - Virreyes, Lomas de Chapultepec V Secc., Miguel Hidalgo, 11000 Ciudad de México, CDMX',
      priceBelowMin: 'El valor de renta de tu departamento es inferior a lo que podemos operar.',
      lease: 'renta',
    },
    whyRentAptuno: {
      guaranteedPay:
        'Recibe el pago puntual de tu renta el día acordado dentro de los 7 primeros días del mes. Con nuestra garantía de renta. \n¡Olvídate de cobrar! ',
      title: '¿Por qué publicar tu inmueble con Aptuno?',
      leaseQuicklier: 'Renta más rápido',
      findNextTenant:
        'Minimiza la vacancia de tu inmueble y aumenta tu rentabilidad.' + '¡Encuentra tu próximo arrendatario aquí!',
      potencialTenantsEveryDay:
        'Programa una sesión de fotos gratuita, anuncia tu inmueble en nuestro portal y consigue potenciales inquilinos todos los días. \n¡Arrienda con todas las garantías!',
    },
    basicInfoForm: {
      apartmentType: 'Departamento',
      priceQuestion: 'Valor mensual de la renta',
      priceLabel: '$ Ingresa aquí el valor en MXN',
      adminPriceQuestion: 'Valor mensual del mantenimiento',
      adminPriceLabel: '$ Ingresa aquí el valor en MXN',
      amountOfRoomsQuestion: 'Número de recámaras:',
      amountOfParkingLotsQuestion: 'Número de estacionamientos:',
      minPrice: 'Valor mínimo ${{min}}',
      maxPrice: 'Valor máximo ${{max}}',
      required: 'Es requerido',
      minAdminPrice: 'Valor mínimo ${{min}}',
      maxAdminPrice: 'Valor máximo ${{max}}',
    },
    locationSelectPage: {
      alertInfo:
        'Recuerda que solo publicaremos inmuebles de uso habitacional en renta. No publicaremos recamaras, ' +
        'locales comerciales, inmuebles en venta o cualquier otro tipo de clasificado.',
      descriptionExample:
        'Ejemplo: Departamento iluminado de 60 mtrs. Cuenta con una habitación con closet, ' +
        'dos baños, estudio, cocina integral tipo americano con estufa a gas, calentador ' +
        'a gas, area de lavado, con sala comedor y un lugar de estacionamiento privado (se aceptan' +
        ' mascotas, disponible a partir del 01 de diciembre). El edificio cuenta con elevador, ' +
        'circuito cerrado de TV y servicio de vigilancia 24 horas. Cerca a Tabasco 100, Roma Norte Cuahtemoc CDMX, 06700.',
    },
    estatePriceSuggestion: {
      title: 'Este es nuestro estimado del valor de renta.',
    },
    uploadPhotosPage: {
      infoText:
        'Suba al menos 3 fotos para publicar el anuncio, los ' +
        'inmuebles que tienen entre 8 y 15 fotos tienen mayores probabilidades de ser rentados!',
    },
    generalInfo: {
      leaseReportTitle: 'Reporte valor de renta',
    },
    estateInfo: {
      leaseReportTitle: 'Reporte valor de renta',
    },
    footer: {
      platformDesc: 'Aptuno, la plataforma de búsqueda de departamentos y casas en renta en Ciudad de México.',
      iAmTenant: 'Soy Inquilino',
      businessName: '© 2021 Departamento Uno S. de R.L. de C.V.',
    },
    loginLandlord: {
      tenants: 'arrendatarios',
      reduceVacancy: 'Reduce la vacancia',
      lookingForYourIdealHome: 'Si estás buscando dónde vivir',
    },
    rentCalculatorForm: {
      bedrooms: 'Recámaras',
      furnished: 'Amueblado',
      parkingLots: 'Estacionamientos',
      howCalculateRentValue: '¿Cómo calcular el precio de renta de una propiedad?',
      abilityToNegotiate:
        'Abrir la posibilidad de un debate frente al precio final de renta y escuchar las ofertas de los inquilinos.',
      setMinimum: 'Debes tener claro cuál es el precio mínimo por el cual estás dispuesto a rentar tu propiedad. ',
      setAllItems:
        'Debes ser claro sobre lo que está incluido en el precio de renta, por ejemplo: Servicios (Luz, Agua, Gas, etc), Mantenimiento, Estacionamiento y cualquier otra que aplique.',
    },
    rentcalculator: {
      title: '¡Calcula el costo de renta ideal!',
      calcDescription: 'sobre el precio del arriendo de tu inmueble',
      wishToLease: 'Si deseas rentar,',
      whyRentAptuno: '¿Por qué publicar tu inmueble con Aptuno?',
      guaranteedPay:
        'Recibe el pago puntual de tu renta el día acordado dentro de los 7 primeros días del mes. Con nuestro garantía de renta. \n¡Olvídate de cobrar! ',
      leaseQuicklier: 'Renta más rápido',
      findNextTenant:
        'Minimiza la vacancia de tu inmueble y aumenta tu rentabilidad. \n¡Encuentra tu próximo arrendatario aquí!',
      potencialTenantsEveryDay:
        'Programa una sesión de fotos gratuita, anuncia tu inmueble en nuestro portal y consigue potenciales inquilinos todos los días. \n¡Arrienda con todas las garantías!',
      seoTitle: 'Herramienta para Calcular la Renta | Aptuno México',
      seoDescription:
        'Con nuestra Herramienta para calcular el valor del canon de renta, sabrás cuánto deberías cobrar para maximizar la rentabilidad de tu propiedad.',
      seoUrl: 'https://www.aptuno.mx/calculadora-de-arrendamiento',
      titleArticle1: '¿Cómo calcular el precio de renta de tu departamento?',
      urlArticle1: '/blog/post/como-calcular-el-precio-de-renta-de-tu-departamento',
      titleArticle2: '¿Cómo publicar un depa o casa en renta por internet?',
      urlArticle2: '/blog/post/como-publicar-un-depa-o-casa-en-renta-por-internet',
      publishBannerDescription:
        'Te ayudamos a encontrar tu inquilino ideal y con el precio de renta óptimo para tu propiedad.',
    },
    profitsSection: {
      title: 'Asegúrate de pedir la renta óptima para tu inmueble',
      description:
        'Calculamos tu renta con algoritmos de inteligencia artificial, con las características de tu inmueble y el mercado en la zona para saber si tienes el precio correcto, sino te sugerimos el mejor precio, para conseguir el inquilino en menor tiempo.',
      vacancyTime: 'Reduce la vacancia',
    },
    rentReport: {
      title: 'de la renta',
      leasePrice: 'de valor de la renta',
      description:
        'Calculamos este valor con base a inmuebles comparables cerca a tu propiedad, la tendencia ' +
        'en los precios de la renta de los últimos 16 meses y la información que nos brindaste.',
      error: '¡Ups, algo salió mal!, vuelve calcular nuevamente el valor de la renta',
      goToRentCalculator: 'Ir a calculadora de la renta',
      withAdmonIncluded: 'de renta mensual con mantenimiento incluido.',
      bedrooms: 'recámaras',
      shortbedrooms: 'recáma.',
      parking: 'estacionamiento',
      shortParking: 'estac.',
    },
    timeToRent: {
      mainSentence:
        '¿Sabías que el tiempo promedio para rentar un departamento es 3.5 meses colocando un aviso en la ventana?',
      timeEmpty:
        'Entre más tiempo esté vacía tu propiedad mayor será el costo que tendrás que asumir por mantenimiento y servicios.',
    },
    defaultNavbar: {
      rentCalculator: 'Calculadora de Renta',
    },
    mainTags: {
      furnished: 'Amueblado',
    },
    propertyDetails: {
      isCosignerNeeded: '¿Se necesita aval para este inmueble?',
      isCosignerNeededDesc:
        'Con nuestro buró de crédito rápido, simplemente necesitas tener tu documentación ' +
        'al día y no estar reportado en centrales de riesgo, en 2 horas obtendrás los ' +
        'resultados. ¡Si apruebas el estudio no necesitarás más! Anímate a visitarlo y ' +
        'aplicar. (Aplican TyC)',
      adminIncluded: 'incluye mtto',
    },
    socialMedia: {
      facebook: 'https://www.facebook.com/aptuno.mx/',
      instagram: 'https://www.instagram.com/aptuno.mx/',
      linkedin: 'https://www.linkedin.com/company/aptuno/',
      twitter: 'https://twitter.com/aptuno_mexico/',
    },
    glossary: {
      bedroom: 'recámara',
      bedrooms: 'recámaras',
      parking: 'estacionamiento',
      parkings: 'estacionamientos',
      rent: 'renta',
      rentVerb: 'rentar',
      lease: 'renta',
      administration: 'mantenimiento',
      administrationIncluded: 'mantenimiento incluido',
      apartment: 'apartamento',
      apartments: 'apartamentos',
      bbqArea: 'roof garden',
      pool: 'alberca',
      elevator: 'elevador',
      elevators: 'elevadores',
      deposit: 'bodega',
      furnished: 'amueblado',
      furnisheds: 'amueblados',
      neighborhood: 'Colonia',
    },
    documentsLegal: {
      label: 'Tu número de INE',
    },
    contractModelDocument: {
      url: 'https://docs.google.com/document/d/1D15l2OQ-7U1cEvokndPmhAexzwppKJe4/edit',
    },
    blog: {
      seoTitle: 'Blog, Noticias y Tendencias sobre Arrendamientos | Aptuno México',
      seoDescription:
        'Encuentra todo sobre noticias leyes y tendencias sobre todo lo referente a rentas en México para propietarios e inquilinos. Ingresa y conoce más.',
      seoUrl: 'https://www.aptuno.mx/blog',
      seoImage:
        'https://images.ctfassets.net/bj5ota02hmze/2hzHfPvLkM6mzHyYqoIWAp/62fd623e9a4f98bc67f0b0ec85dc1d90/arriendo_plataforma_.jpg',
    },
    help: {
      seoTitle: 'Ayuda para Propietarios e Inquilinos | Aptuno México',
      seoDescription:
        'Recibe toda la información sobre nuestra plataforma al momento de poner en renta tu casa o depa, o si eres inquino. Ingresa y resuelve tus dudas.',
      seoUrl: 'https://www.aptuno.mx/ayuda',
      seoImage: 'https://www.aptuno.mx/images/aptuno_logo.png',
    },
    aboutUs: {
      seoTitle: 'Plataforma para Renta de Casas y Departamentos | Aptuno México',
      seoDescription:
        'Aptuno es plataforma altamente técnológica que te ayudará a publicar tu casa o departamento y rentar en tiempo record. Conoce más de nosotros aquí.',
      seoUrl: 'https://www.aptuno.mx/quienes-somos',
      seoImage: 'https://www.aptuno.com/images/aptuno_logo.png',
    },
    TCExclusivity: {
      company:
        'DEPARTAMENTO UNO S. de R.L de C.V sociedad comercial ubicada en CALLE RUBÉN DARÍO, NO. 36, OFICINA 2, Colonia BOSQUE DE CHAPULTEPEC I SECCIÓN, C.P. 11580, MIGUEL HIDALGO, Ciudad de México(en adelante “Aptuno”).',
      publishEstateItem: 'Anunciar y/o promocionar en Renta el inmueble.',
    },
    administrativeLevel: {
      city: 'Ciudad',
      country: 'País',
      coverage: 'Lugar',
      departament: 'Estado',
      locality: 'Alcaldía',
      municipality: 'Ciudad',
      neighborhood: 'Colonia',
      park: 'Lugar',
      university: 'Lugar',
      upz: 'Colonia',
      zone: 'Lugar',
      station: 'Lugar',
      club: 'Lugar',
      mall: 'Lugar',
      medical_center: 'Lugar',
      museum: 'Lugar',
      poi: 'Lugar',
      school: 'Lugar',
      location_level_5: 'Estado',
      location_level_9: 'Ciudad',
      location_level_10: 'Alcaldía',
    },
  },
};

console.log('initializing i18n lang', env.countryCode);
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: env.countryCode, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
