import React, { useEffect, useState } from 'react';
import { useContentful } from 'react-contentful';
import { env } from '../../environment/environment';
import { capitalize } from '../../helpers/coolFunctions';
import history from '../../history';
import { StickyWrapper, StickyBox, StickyTitle, Content, CallToActionEl } from './Stickybar.styled';

const Stickybar = ({ color, bgColor }) => {
  const [isStickyActive, setStickyActive] = useState(false);
  const [item, setItem] = React.useState(null);
  const cObj = { contentType: env.contentful.stickybar };

  cObj.query = {};

  const { data } = useContentful(cObj);

  useEffect(() => {
    let timeout = null;

    if (data && data.items && data.items.length > 0) {
      setItem(data.items[0].fields);
      if (!getStickyWasClosed()) {
        timeout = setTimeout(() => setStickyActive(true), 1000);
      }
    }

    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    };
  }, [data]);

  const handleOnClose = () => {
    setStickyActive(false);
    persistUserCloseSticky();
  };

  const handleCallToAction = () => {
    persistUserCloseSticky();
    setStickyActive(false);
    if (item.url.toLowerCase().match(/^http/)) {
      window.open(item.url, 'blank');
    } else {
      history.push(item.url);
    }
  };

  const getStickyWasClosed = () => {
    try {
      if (sessionStorage) {
        const data = sessionStorage.getItem('stickyWasClosed');
        return data;
      } else {
        console.log('[SessionStorage] SessionStorage is not available for this device');
        return;
      }
    } catch (e) {
      console.log(`[SessionStorage] Error getting item 'stickyWasClosed'`, e);
      return;
    }
  };

  const persistUserCloseSticky = () => {
    try {
      if (sessionStorage) {
        sessionStorage.setItem('stickyWasClosed', true);
      } else {
        console.log('[SessionStorage] SessionStorage is not available for this device');
      }
    } catch (e) {
      console.log(`[SessionStorage] Error setting item 'stickyWasClosed'`, e);
    }
  };

  return (
    <>
      {isStickyActive && item ? (
        <>
          <StickyWrapper>
            <StickyBox color={color} bgColor={bgColor}>
              <Content>
                {item.title && <StickyTitle>{capitalize(item.title)}</StickyTitle>}
                {item.text && <p>{item.text}</p>}
                {item.callToAction && item.url && (
                  <CallToActionEl onClick={handleCallToAction}>{item.callToAction}</CallToActionEl>
                )}
              </Content>
              <span className="close icon-close_icon m-auto" onClick={handleOnClose}></span>
            </StickyBox>
          </StickyWrapper>
        </>
      ) : null}
    </>
  );
};

export default Stickybar;
