export default {
 GA_KEY: 'UA-153730560-1',
 GTM_KEY: 'GTM-TK9KSNM',
 GTM_QA_KEY: 'G-GTFJWVCERH',
 offOnDevelop: false,
 isDesa: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'),
 isProdEnv: process.env.NODE_ENV === 'production',
 isON_GTM: true,
 MatterportApiKey: "d8b62113e29c3299"
};
