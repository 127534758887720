import { Phone } from '../user/user';

function noop() {
  // No operation performed.
}

// return the same value received.
function identity<T>(t: T) {
  return t;
}

const extractPhoneCode = (phone: Phone): string => {
  return phone.value.substring(0, 3);
};

const extractPhoneNumber = (phone: Phone): string => {
  return phone.value.substring(3);
};

export { noop, identity, extractPhoneCode, extractPhoneNumber };
