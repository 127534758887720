import { TraceableProcess, TrackingEvent } from '../../../services/analytics/model';
import { EstateLegalId } from '../../../share/estate/estate';
import { UserId } from '../../../share/user/user';

export class AppointmentScheduledOnUncoveredPublicationEvent implements TrackingEvent {
  public readonly action: string = 'AppointmentScheduledOnUncoveredPublication';
  public readonly category: TraceableProcess = TraceableProcess.APPOINTMENT_MANAGEMENT;
  public readonly label: string;
  constructor(
    public readonly tenantId: UserId,
    public readonly estateLegalId: EstateLegalId,
    public readonly visitDate: Date
  ) {
    this.label = `UserId = ${tenantId.value}, PropertyId = ${
      estateLegalId.value
    }, VisitDate = ${visitDate.toISOString()}`;
  }
}
