import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("/fonts/Satoshi-Regular.woff2");
  }
  @font-face {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("/fonts/Satoshi-Medium.woff2");
  }
  @font-face {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/fonts/Satoshi-Bold.woff2");
  }
  @font-face {
    font-family: "Satoshi";
    font-weight: 500, bold;
    font-display: swap;
    src: url("/fonts/Satoshi-Bold.woff2") format("woff2"), url("/fonts/Satoshi-Bold.woff") format("woff");
  }
  @font-face {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("/fonts/Satoshi-Black.woff2");
  }

  @font-face {
    font-family: "icomoon";
    src: url("/fonts/icomoon.eot?hbfemh");
    src: url("/fonts/icomoon.eot?hbfemh#iefix") format("embedded-opentype"),
      url("/fonts/icomoon.ttf?hbfemh") format("truetype"), url("/fonts/icomoon.woff?hbfemh") format("woff"),
      url("/fonts/icomoon.svg?hbfemh#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  white-space: pre-line;
`;

export default GlobalStyle;
