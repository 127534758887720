import React, { memo, useState, useCallback, CSSProperties, useEffect } from 'react';
import SingleInput from './single-field';

// based on: https://codesandbox.io/embed/react-otp-input-scratch-zm034?fontsize=14&hidenavigation=1&theme=dark

export interface OTPConfigProps {
  length: number;
  onChangeOTP: (otp: string) => any;
}

export interface OtpFieldProps {
  autoFocus?: boolean;
  isNumberInput?: boolean;
  disabled?: boolean;
}

export interface OtpStyleProps {
  style?: CSSProperties;
  className?: string;

  inputStyle?: CSSProperties;
  inputClassName?: string;
}

export interface OtpControlProps {
  activeInput: number;
  setActiveInput: (active: number) => void,
  otpValues: string[]
  setOTPValues: (values: string[]) => void,
}


type OTPInputBaseProps = OTPConfigProps & OtpFieldProps & OtpStyleProps
type OTPInputComponentProps = OTPInputBaseProps & OtpControlProps


export function OTPInputComponent(props: Omit<OTPInputComponentProps, 'onChangeOTP'>) {
  const {
    length,
    isNumberInput,
    autoFocus,
    disabled,

    className,
    inputClassName,
    inputStyle,

    activeInput,
    setActiveInput,
    otpValues,
    setOTPValues
  } = props;

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (str: string) => {
      let changedValue = str;

      if (!isNumberInput || !changedValue) {
        return changedValue;
      }

      return Number(changedValue) >= 0 ? changedValue : '';
    },
    [isNumberInput]
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || '';
      setOTPValues(updatedOTPValues);
    },
    [activeInput, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const deleteAtPrevious = useCallback(() => {
    const prev = activeInput - 1;

    //delete
    const updatedOTPValues = [...otpValues];
    updatedOTPValues[prev] = '';
    setOTPValues(updatedOTPValues);

    // focus
    const selectedIndex = Math.max(Math.min(length - 1, prev), 0);
    setActiveInput(selectedIndex);

  }, [activeInput, focusInput]);

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          const activeInputHasValue = otpValues[activeInput];
          if (activeInputHasValue) {
            changeCodeAtFocus('');
          } else {
            deleteAtPrevious();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z\d]$/)) {
            e.preventDefault();
          }

          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, length - activeInput)
        .split('');
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, otpValues]
  );
  const fields = Array(length)
    .fill('')
    .map((_, index) => (
      <div className={`${className}`} key={`SingleInput-${index}`}>
        <SingleInput
          type={isNumberInput ? 'number' : 'text'}
          focus={activeInput === index}
          value={otpValues && otpValues[index]}
          autoFocus={autoFocus}
          onFocus={handleOnFocus(index)}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onBlur={onBlur}
          onPaste={handleOnPaste}
          style={inputStyle}
          className={inputClassName}
          disabled={disabled}
        />
      </div>
    ));

  return (
    <>
      {fields}
    </>
  );
}

interface OtpInputItem {
  inputComponent: JSX.Element,
  clear: () => void,
}

export function useOtpInput(props: OTPInputBaseProps): OtpInputItem {

  const [activeInput, setActiveInput] = useState(0);
  const initialState = Array<string>(props.length).fill('');
  const [otpValues, setOTPValues] = useState(initialState);

  const { onChangeOTP, ...otherProps } = props;

  // upstream any changes
  useEffect(() => {
    const value = otpValues.join('');
    onChangeOTP?.(value);
  }, [otpValues]);


  const inputComponent = (
    <OTPInputComponent
      {...otherProps}
      activeInput={activeInput}
      setActiveInput={setActiveInput}
      otpValues={otpValues}
      setOTPValues={setOTPValues}
    />
  );

  const handleClear = () => {
    setActiveInput(0);
    setOTPValues(initialState);
  };

  return {
    inputComponent,
    clear: handleClear
  };
}

export default OTPInputComponent;
