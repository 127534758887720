import { AptunoButton, Icon } from '@aptuno/aptuno-ui';
import React from 'react';

import { ActionBarContainer } from './ProcessActionBar.styled';

const ProcessActionBar = props => {
  return (
    <ActionBarContainer>
      <AptunoButton size={'small'} appearance={'secondary'} onClick={props.onSecondButtonClick}>
        <Icon icon={'arrowleftalt'} />
        Regresar
      </AptunoButton>
      {props.onPrimaryButtonClick && (
        <AptunoButton
          appearance={props.appearance}
          size={'big'}
          isDisabled={props.primaryButtonDisabled}
          onClick={props.onPrimaryButtonClick}
        >
          {props.primaryButtonName}
        </AptunoButton>
      )}
    </ActionBarContainer>
  );
};

export default ProcessActionBar;
