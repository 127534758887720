export enum TraceableProcess {
  AUTOPUBLISH = 'Autopublish',
  APPLICATION = 'Application',
  APPOINTMENT_SCHEDULING = 'Appointment Scheduling',
  APPOINTMENT_MANAGEMENT = 'Appointment Management',
  NAVIGATION = 'Navigation',
  HOME_RECOMMENDATION = 'Home Recommendations',
  SEARCH_DISCOVERY = 'Search & Discovery',
  EXPERIMENTS = 'Experiments',
  USER_PROFILE = 'User profile',
  CHAIN_SCHEDULING = 'Chain Scheduling',
  LEASE_CALCULATOR = 'Lease Calculator',
  LANDLORD_LANDING = 'Landlord landing',
  LANDLORD_DASHBOARD = 'Landlord Dashboard',
  GOOGLE_MAPS = 'Google Maps',
}

export interface Tag {
  key: string;
  value: string;
}

export interface TrackingEvent {
  action: string;
  category: TraceableProcess;
  label?: string;
  tags?: Tag[];
}

export interface TrackingIdentity {
  userId: string;
}

export interface TrackingPage {
  category: string | undefined;
  name: string | undefined;
}

export interface Tracker {
  trackEvent: (event: TrackingEvent) => Promise<void>;
  trackIdentity: (event: TrackingIdentity) => Promise<void>;
  trackPage(page?: TrackingPage): Promise<void>;
}
