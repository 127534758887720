import { useEffect, useState } from 'react';
import { ESCAPE } from '../helpers/keys';

export function useOutsideClick(wrapperRef, defaultValue = false) {
  const [isListOpen, setIsListOpen] = useState(defaultValue);

  const handleHideDropdown = event => {
    if (event.key === ESCAPE) {
      setIsListOpen(false);
    }
  };

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsListOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  });

  return { wrapperRef, isListOpen, setIsListOpen };
}
