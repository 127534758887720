import React, { Fragment } from 'react' 
import { PAGE_CATEGORY } from "./url"
import { formatMoney } from "./../helpers/coolFunctions";
import { 
  PROPERTY_ATTRIBUTES, 
  CHARACTERISTICS_TO_EXTRACT,
  extractCharacteristics
} from "./../helpers/property";
import { env } from '../environment/environment';

function home(t) {
  return {
    title: t('homeSeoTitle'),
    description: t('homeSeoDescription'),
    h1: t('homeSeoH1'),
    canonical: t('homeSeoCanonical'),
    image: `https://www.${env.urlAptuno}/images/aptuno_logo.png`
  }
}

function resultPage(t, {
  propertyType = null, 
  location = 'Bogotá', 
  propertiesCount = 0,
}) {
  const propertyTypeCapitalized = getPropertyTypeTextCapitalized(propertyType)
  const propertyTypeLowerCase = propertyTypeCapitalized.toLowerCase()

  return {
    title: t('searchResultSeoTitle', { propertyTypeCapitalized, location }),
    description: t('searchResultSeoDescription', {propertiesCount, propertyTypeLowerCase, location}),
    content: (
      <Fragment>
        <p className="font-satoshi-medium lh-20 ls-l-013">
          {propertiesCount.toLocaleString("es-Co", { maximumFractionDigits: 0 })} resultados
        </p>
      </Fragment>
    )
  }
}

function getPropertyTypeTextCapitalized(propertyType) {
  switch(propertyType){
    case 'APARTMENT':
      return `Apartamento`
    case 'HOUSE':
      return `Casa`
  }
  return `Inmueble`
}

function detailPage(t, { 
  address, 
  characteristics: rawCharacteristics, 
  canonical,
  media,
  location, 
  price,
  propertyType
}) {
  const propertyTypeCapitalized = getPropertyTypeTextCapitalized(propertyType)
  const formattedPrice = formatMoney(Number(price), 0, ".", ".")
  
  const characteristics = extractCharacteristics(rawCharacteristics || [], CHARACTERISTICS_TO_EXTRACT) 
  const bedrooms = characteristics[PROPERTY_ATTRIBUTES.BEDROOMS]
  const area = characteristics[PROPERTY_ATTRIBUTES.AREA]
  const bedroomsText = bedrooms === 1 ? 'habitacion' : 'habitaciones';

  return {
    title: t('detailPageSeoTitle', { location: location.name, propertyTypeCapitalized, address, formattedPrice }),
    description: t('detailPageSeoDescription', {
      bedrooms,
      bedroomsText,
      area,
      formattedPrice,
      address,
      location: location.name,
    }),
    h1: t('detailPageSeoH1', {propertyTypeCapitalized, bedrooms, bedroomsText, area, formattedPrice}),
    image: media && media[0] ? media[0].uri : '/images/aptuno_logo_2colors.svg',
    canonical: canonical !== window.location.pathname ? canonical : null,
  };
}

function getSeoForPage(translate, pageCategory = PAGE_CATEGORY.HOME, pageParams = {}) {
  switch(pageCategory) {
    case PAGE_CATEGORY.RESULT_PAGE:
      return resultPage(translate, pageParams)
    case PAGE_CATEGORY.DETAIL_PAGE:
      return detailPage(translate, pageParams)
    default:
      return home(translate);
  }
}

export {
  PAGE_CATEGORY,
  getSeoForPage
}
