import { assign, createMachine } from 'xstate';
import { AnalyticsProps, TrackingInformation, UserData, ValidateOtpResponse } from './types';


const noop = () => {
};


export interface OtpMachineContext {
  readonly phoneNumber: string;
  readonly userType: string;
  readonly anonymousId: string;
  readonly country: string;

  readonly otpLength: number;

  readonly trackingInformation: TrackingInformation;

  // on validate
  readonly otpCode: string | undefined;

  // on error
  readonly errorCount: number;
  readonly errorMessage: string | undefined;

  // on success
  readonly userData: UserData | undefined;
  readonly token: string | undefined;
}

export type OtpData = OtpMachineContext & {
  otpCode: string;
}

export type CompleteUserInformation = OtpMachineContext & {
  otpCode: string;
  userData: UserData;
  token: string;
};

export type Pristine = {
  otpCode: undefined;
  errorMessage: undefined;
};

export type OtpMachineEvent =
  | {
  type: 'RESEND', data: {
    count: number
  }
}
  | {
  type: 'STORE', data: {
    otpCode: string
  }
}
  | {
  type: 'done.invoke.validateOtp', data: {
    userData: UserData,
    token: string,
  }
} | {
  type: 'error.platform.validateOtp', data: {
    errorMessage: string
  }
}


export type OtpTypeState =
  | { value: 'loggedOut'; context: OtpMachineContext & Pristine }
  | { value: 'validating'; context: OtpData }
  | { value: 'resending'; context: OtpMachineContext & Pristine }
  | {
  value: 'error'; context: OtpMachineContext & {
    errorMessage: string,
  }
}
  | {
  value: 'loggedIn'; context: CompleteUserInformation
}


interface CreateOtpMachineArgs {
  anonymousId: string;
  country: string;
  userType: string;
  phoneNumber: string;

  trackingInformation: TrackingInformation;

  otpLength: number;

  reportAnalytics: (props: AnalyticsProps) => void;

  validateOtp: (data: OtpData) => Promise<ValidateOtpResponse>;
  resendOtp: (errorCount: number) => Promise<void>;
}

function createOtpMachine(args: CreateOtpMachineArgs) {
  return createMachine<OtpMachineContext, OtpMachineEvent, OtpTypeState>({
    id: 'otp-input',
    initial: 'loggedOut',
    context: {
      phoneNumber: args.phoneNumber,
      userType: args.userType,
      trackingInformation: args.trackingInformation,
      anonymousId: args.anonymousId,
      country: args.country,

      otpLength: args.otpLength,

      errorCount: 0,
      errorMessage: undefined,


      otpCode: undefined,
      userData: undefined,
      token: undefined
    },
    states: {
      loggedOut: {
        on: {
          RESEND: {
            target: 'resending'
          },
          STORE: [
            {
              cond: 'otpHasValidLength',
              target: 'validating',
              actions: ['storeOtp']
            },
            {
              target: 'loggedOut',
              actions: ['storeOtp']
            }
          ]
        }
      },
      validating: {
        invoke: {
          id: 'validateOtp',
          src: 'validateOtp',
          onError: {
            target: 'error',
            actions: ['onValidateError', 'incrementErrorCount', 'storeErrorMessage']
          },
          onDone: {
            target: 'loggedIn',
            actions: ['onValidateSuccess', 'sendAnalytics', 'storeUserData', 'storeUserToken']
          }
        }
      },
      resending: {
        invoke: {
          id: 'resendingOtp',
          src: 'resendOtp',
          onError: {
            target: 'loggedOut', // TODO @cristian.barrientos :  what should happen when this happens?
            actions: ['onResendOtpError']
          },
          onDone: {
            target: 'loggedOut',
            actions: ['onResendOtpSuccess']
          }
        }
      },
      error: {
        after: {
          2000: [
            { target: 'resending', cond: 'alreadyMaxErrorCount', actions: ['resetErrorCount'] }
          ]
        },
        on: {
          RESEND: {
            target: 'resending'
          },
          STORE: {
            target: 'loggedOut',
            actions: ['storeOtp']
          }
        }
      },
      loggedIn: {
        type: 'final'
      }
    }
  }, {
    services: {
      validateOtp: (ctx, event) => {
        if (event.type !== 'STORE') return Promise.reject('Invalid event');

        return args.validateOtp({
          ...ctx,
          otpCode: event.data.otpCode
        })
          .then(response => {
            if (response._tag === 'ValidateOtpFailure') {
              return Promise.reject(response);
            }

            return Promise.resolve(response);
          });
      },
      resendOtp: (ctx) => args.resendOtp(ctx.errorCount)
    },
    guards: {
      alreadyMaxErrorCount: ctx => ctx.errorCount >= 3,
      otpHasValidLength: (context, e) => {
        if (e.type !== 'STORE') return false;
        const otp = e.data.otpCode;
        return otp.length === context.otpLength;
      }
    },

    actions: {
      onValidateError: noop,
      onValidateSuccess: noop,
      onResendOtpError: noop,
      onResendOtpSuccess: noop,
      storeUserData: assign((ctx, event) => {
        if (event.type !== 'done.invoke.validateOtp') return {};
        const { userData, token } = event.data;
        return {
          token,
          userData
        };
      }),
      storeErrorMessage: assign({
        errorMessage: (ctx, e) => {
          if (e.type !== 'error.platform.validateOtp') return undefined;
          return e.data.errorMessage;
        }
      }),
      incrementErrorCount: assign({
        errorCount: (ctx) => ctx.errorCount + 1
      }),
      resetErrorCount: assign({
        errorCount: (_) => 0
      }),
      storeOtp: assign({
        otpCode: (ctx, e) => {
          if (e.type !== 'STORE') return;

          return e.data.otpCode;
        }
      }),
      sendAnalytics: (ctx, event) => {
        if (event.type !== 'done.invoke.validateOtp') return;

        const { userData, token } = event.data;
        const allUserData = {
          ...userData,
          country: ctx.country
        };

        args.reportAnalytics({
          user: {
            type: ctx.userType,
            phoneNumber: ctx.phoneNumber,
            data: allUserData,
            token: token
          },
          tracking: ctx.trackingInformation
        });
      },
      storeUserToken: (ctx, event) => {
        if (event.type !== 'done.invoke.validateOtp') return;
        const { token } = event.data;
        localStorage.setItem('token', token);
      }
    }
  });
}

export {
  createOtpMachine as default,
  createOtpMachine
};
