import React, { useContext, useEffect, useState } from 'react';
import ProcessActionBar from '../../../components/ProcessActionBar/ProcessActionBar';
import ProcessInfoBar from '../../../components/ProcessInfoBar/ProcessInfoBar';
import history from '../../../history';
import { estatePublishSteps, publishEstate } from '../EstatePublish';
import {
  Container,
  GlobalStyle,
  Item,
  TextTitle,
  RadioImage,
  RadioTitle,
  RadioDescription,
  RadioContainer,
  MainRadioContainer,
  RadioWrapper,
  BenefitsContainer,
  PlanItemText,
  PlanItemStyle,
  BenefitsTitle,
  ButtonContainer,
  TermsButton,
} from './ExclusivitySelectPage.styled';
import { useResponsive, RadioButton, Icon } from '@aptuno/aptuno-ui';
import NavbarSimple from '../../../components/NavbarSimple/NavbarSimple';
import { useForm } from 'react-hook-form';
import { TermsAndConditionsModal } from './TermsAndConditionsModal';
import CallSupport from '../../../components/CallSupport/CallSupport';
import { goToLocationSelectPage, goToTermsAndConditionsPage, PUBLICATION_TYPE } from '../../../constants/autopublish';
import { useAnalyticsTracker } from '../../../services/analytics/useAnalyticsTracker';
import { ExclusivitySelected } from '../events/exclusivity-selected.event';

const PlanItem = (props) => {
  return (
    <PlanItemStyle iconColor={props.iconColor} backgroundColor={props.backgroundColor}>
      <Icon width="14px" icon={props.icon} aria-label={props.icon} />
      <PlanItemText>{props.children}</PlanItemText>
    </PlanItemStyle>
  );
};

const Benefits = ({ isExclusive }) => {
  return (
    <BenefitsContainer>
      <BenefitsTitle>Beneficios:</BenefitsTitle>
      {isExclusive ? (
        <>
          <PlanItem iconColor="#D250E6" backgroundColor="" icon="check">
            10% de descuento en el plan que escojas
          </PlanItem>
          <PlanItem iconColor="#D250E6" backgroundColor="" icon="check">
            Visibilidad aumentada en nuestro catálogo
          </PlanItem>
          <PlanItem iconColor="#D250E6" backgroundColor="" icon="check">
            Publicación destacada en portales aliados
          </PlanItem>
        </>
      ) : (
        <PlanItem iconColor="#0055FB" backgroundColor="" icon="check">
          Puedes re-publicar tu inmueble en otros portales o inmobiliarias de tu preferencia
        </PlanItem>
      )}
    </BenefitsContainer>
  );
};

const ExclusivitySelectPage = () => {
  const Responsive = useResponsive();
  const eventTracker = useAnalyticsTracker();
  const { estate, setEstate } = useContext(publishEstate);
  const [isExclusive, setIsExclusive] = useState(true);
  const [showTermAndConditions, setShowTermAndConditions] = useState(false);

  useEffect(() => {
    if (!estate.basicInfo?.price) goToLocationSelectPage();
  }, []);

  const handlePrimaryButtonClick = () => {
    eventTracker.trackEvent(new ExclusivitySelected(isExclusive, !!estate?.newLandlord?.userId));
    setEstate((estate) => {
      return { ...estate, isExclusive };
    });
    goToTermsAndConditionsPage();
  };

  const getExclusivityDefaultValue = () => {
    if (estate.isExclusive === undefined) return PUBLICATION_TYPE.EXCLUSIVE;
    return estate.isExclusive ? PUBLICATION_TYPE.EXCLUSIVE : PUBLICATION_TYPE.STANDARD;
  };

  const { register, getValues } = useForm({
    mode: 'onBlur',
    defaultValues: { publicationType: getExclusivityDefaultValue() },
  });

  const onTypeChange = () => {
    setIsExclusive(PUBLICATION_TYPE.EXCLUSIVE === getValues('publicationType'));
  };

  return (
    <>
      {showTermAndConditions && (
        <TermsAndConditionsModal modalState={[showTermAndConditions, setShowTermAndConditions]} />
      )}
      <GlobalStyle />
      <CallSupport />
      <NavbarSimple onBackButton={() => history.push('/publicar-inmueble/informacion-fotos')} />
      <ProcessInfoBar steps={estatePublishSteps(estate.location.formattedAddress)} currentStep={6} />
      <Container Responsive={Responsive}>
        <Item>
          <TextTitle>¿Qué tipo de publicación te gustaría?</TextTitle>
        </Item>

        <MainRadioContainer responsive={Responsive} selected={isExclusive}>
          <RadioImage
            src={
              isExclusive
                ? '/images/autopublish/exclusivity-active.svg'
                : '/images/autopublish/exclusivity-inactive.svg'
            }
            alt="Icono de Cerrar"
            title="Icono de Cerrar | Aptuno"
          />
          <RadioWrapper>
            <RadioButton
              id="exclusive"
              name="publicationType"
              value={PUBLICATION_TYPE.EXCLUSIVE}
              ref={register({ required: true })}
              onClick={(e) => onTypeChange(e)}
            />
            <RadioTitle isExclusive={isExclusive}>Exclusiva</RadioTitle>
          </RadioWrapper>
          <RadioDescription isExclusive={isExclusive}>
            Tu inmueble será privilegiado entre todas nuestras publicaciones
          </RadioDescription>
        </MainRadioContainer>

        <RadioContainer responsive={Responsive}>
          <RadioWrapper>
            <RadioButton
              id="standard"
              name="publicationType"
              value={PUBLICATION_TYPE.STANDARD}
              ref={register({ required: true })}
              onClick={(e) => onTypeChange(e)}
            />
            <RadioTitle isExclusive={!isExclusive}>Estándar</RadioTitle>
          </RadioWrapper>
        </RadioContainer>

        <Benefits isExclusive={isExclusive} />

        <ButtonContainer>
          <TermsButton onClick={() => setShowTermAndConditions(true)}>
            Consulta todos los términos y condiciones
          </TermsButton>
        </ButtonContainer>

        <ProcessActionBar
          onSecondButtonClick={() => history.push('/publicar-inmueble/informacion-fotos')}
          primaryButtonName={'Continuar'}
          onPrimaryButtonClick={handlePrimaryButtonClick}
        />
      </Container>
    </>
  );
};

export default ExclusivitySelectPage;
