import { AppointmentId } from '../../../../../../share/appointment/appointment.model';
import { EstateId, EstateLegalId } from '../../../../../../share/estate/estate';
import { Email, PersonName, Phone, UserId } from '../../../../../../share/user/user';
import { extractPhoneCode, extractPhoneNumber } from '../../../../../../share/utils';
import {
  AppointmentType,
  GqlAppointmentUserInputType,
  GqlCreateAppointmentMutationVariables,
  GqlRescheduleAppointmentMutationVariables,
  IdentityDocumentType,
} from '../../../../../Shared/integrations/gql/core/generated-types';

export type VisitAssistant = {
  userId: UserId;
  firstName: PersonName;
  lastName: PersonName;
  phoneNumber: Phone;
  email: Email;
  identityDocumentType: IdentityDocumentType;
  identityDocumentCode: string;
};

export namespace VisitAssistant {
  export const asGqlAppointmentUserInputType = (visitAssistant: VisitAssistant): GqlAppointmentUserInputType => ({
    userId: visitAssistant.userId.value,
    email: visitAssistant.email.value,
    firstName: visitAssistant.firstName.value,
    lastName: visitAssistant.lastName.value,
    identityDocumentType: visitAssistant.identityDocumentType,
    identityDocumentCode: visitAssistant.identityDocumentCode,
    phoneCode: extractPhoneCode(visitAssistant.phoneNumber),
    phoneNumber: extractPhoneNumber(visitAssistant.phoneNumber),
  });
}

export type AppointmentSubmit = {
  tenantId: UserId;
  tenantName: PersonName;
  estateId: EstateId;
  estateLegalId: EstateLegalId;
  visitDate: Date;
  alreadyExistingAppointmentId?: AppointmentId;
  visitAssistant?: VisitAssistant;
};

export namespace AppointmentSubmit {
  export const asGqlCreateAppointmentMutationVariables = (
    appointment: AppointmentSubmit
  ): GqlCreateAppointmentMutationVariables => ({
    appointment: {
      tenant: appointment.tenantId.value,
      estateId: appointment.estateId.value,
      type: AppointmentType.FACE_2_FACE_MULTIPLE,
      visitDate: appointment.visitDate.getTime(),
      visitAssistant: appointment.visitAssistant
        ? VisitAssistant.asGqlAppointmentUserInputType(appointment.visitAssistant)
        : undefined,
    },
  });

  export const asGqlRescheduleAppointmentMutationVariables = (
    appointment: AppointmentSubmit
  ): GqlRescheduleAppointmentMutationVariables => ({
    appointmentId: Number(appointment.alreadyExistingAppointmentId || 0),
    appointmentType: AppointmentType.FACE_2_FACE_MULTIPLE,
    newVisitDate: appointment.visitDate.getTime(),
    newVisitAssistant: appointment.visitAssistant
      ? VisitAssistant.asGqlAppointmentUserInputType(appointment.visitAssistant)
      : undefined,
  });
}
