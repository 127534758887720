import { ApolloError, gql, useMutation } from '@apollo/client';
import { EstateId } from '../../../../../share/estate/estate';

type Input = {
  input: {
    estateId: string;
  };
};

type Output = {
  createAppointmentDemandDeal: number;
};

const MUTATION = gql`
  mutation CreateAppointmentDemandDeal($input: CreateAppointmentDealInputType!) {
    createAppointmentDemandDeal(input: $input)
  }
`;

const handleError = (cause: ApolloError, onNext: () => void, onError: (error: ApolloError) => void): void => {
  const dealAlreadyExists = cause.graphQLErrors.some((error) => {
    const errorCode = error.extensions?.exception['code'];
    const resourceType = error.extensions?.exception['resourceType'];

    return errorCode && resourceType && errorCode === 'RESOURCE_ALREADY_EXISTS' && resourceType === 'DemandDeal';
  });

  return dealAlreadyExists ? onNext() : onError(cause);
};

export const useCreateAppointmentDeal = () => {
  const [call] = useMutation<Output, Input>(MUTATION);

  return (scheduledEstate: EstateId): Promise<void> => {
    const variables: Input = { input: { estateId: `${scheduledEstate.value}` } };

    return new Promise((resolve, reject) => {
      call({ variables })
        .then(() => resolve(void 0))
        .catch((cause) => handleError(cause, resolve, reject));
    });
  };
};
