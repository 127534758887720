import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import { env } from '../environment/environment';

export const sendToGoogleAnalytics = ({ name, delta, id }) => {
  const data = {
    dataLayer: {
      event: 'event',
      GAevent: {
        category: 'Web Vitals',
        action: name,
        label: id,
        value: Math.round(name === 'CLS' ? delta * 1000 : delta),
        nonInteraction: true,
      },
    },
  };
  TagManager.dataLayer(data);
};

export function useFacebookPixel () {
  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(env.fbPixelKey);
        ReactPixel.pageView();
      });
  });
  return null;
};
