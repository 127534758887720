import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalBase from '../../../components/Modals/ModalBase/ModalBase';
import { TermsContent, TermsList, TermsParagraph, TermsTittle1, TermsTittle2 } from './ExclusivitySelectPage.styled';

const TermsAndConditionsModal = props => {
  const { t: translate } = useTranslation('TCExclusivity');
  return (
    <ModalBase modalState={props.modalState} title="Términos y Condiciones">
      <TermsContent>
        <TermsTittle1>{'Términos & Condiciones:'}</TermsTittle1>
        <TermsTittle1 color="#6C757D">Consignación de inmueble de forma exclusiva</TermsTittle1>
        <TermsParagraph>
          Estos Términos y condiciones de exclusividad ("Términos de Exclusividad") están destinados a regular el acceso
          a los Servicios de exclusividad sobre su Inmueble a través de la Plataforma Aptuno (en adelante, la
          "Plataforma"), de la cual es propietaria {translate('company')}
        </TermsParagraph>
        <TermsParagraph>
          El Propietario entiende que Aptuno es una empresa especializada en la intermediación de negocios relacionados
          con el alquiler de bienes inmuebles y su disponibilidad en la Plataforma, así como la prestación de servicios
          de gestión de alquiler de propiedades, previo pago de la remuneración. Estos Términos están disponibles para
          leer en cualquier momento en la Plataforma en la sección “Detalle de Inmuebles” del Dashboard de Propietarios.
          Estos Términos están sujetos a mejoras continuas y pueden modificarse en cualquier momento, siempre que no
          exista una prohibición legal a tal efecto. En consecuencia, se recomienda que el Propietario acceda a estos
          Términos de vez en cuando y se asegure de haber verificado su versión más actual, en función de la fecha
          indicada al final del documento. Si el Usuario finalmente no está de acuerdo con algún cambio, debe abstenerse
          de aceptar el Servicio de Exclusividad. Cualquier pregunta, queja, sugerencia o comentario que el Usuario
          tenga con respecto a estos Términos puede enviarse por correo electrónico: informacion@aptuno.com.
        </TermsParagraph>
        <TermsParagraph>
          La aceptación de estos Términos de Exclusividad se da seleccionando la casilla correspondiente a la opción
          "Acepto los Términos de Exclusividad" que aparece en el proceso de autopublicación de su Inmueble, en cuyo
          momento el Usuario expresa su consentimiento libre, expreso e informado sobre el contenido de este documento.
          Si no está de acuerdo con alguna de las disposiciones de estos Términos de Exclusividad, no deberá marcar la
          casilla donde acepta los términos. Bajo estos Términos de Exclusividad Aptuno declara haber recibido de manos
          del (Los) Propietario(s) la entrega real y material del siguiente inmueble para que aquél lo administre de
          manera exclusiva por cuenta y riesgo del Propietario el(los) inmueble(s), cuya descripción general se
          describen en la publicación.
        </TermsParagraph>
        <TermsTittle2>Obligaciones de Aptuno.</TermsTittle2>

        <TermsParagraph>Obligaciones de Aptuno. El Administrador se obliga para con el Propietario a:</TermsParagraph>
        <ul>
          <TermsList>{translate('publishEstateItem')}</TermsList>
          <TermsList>
            Previa instrucción expresa y por escrito del Propietario, pagar los servicios públicos del Inmueble, así
            como cualquier impuesto y seguro que recaiga sobre el mismo.
          </TermsList>
          <TermsList>
            Pagar los impuestos prediales o Distritales, que graven el Inmueble por cuenta del Propietario cuando este
            así lo solicite por escrito y mediante entrega que aquel haga del formulario correspondiente al último pago.
          </TermsList>
          <TermsList>Rendir cuentas mensualmente sobre la administración del inmueble.</TermsList>
          <TermsList>
            Participar en las asambleas de copropietarios de la propiedad horizontal a la cual haga parte el Inmueble,
            de ser aplicable.
          </TermsList>
          <TermsList>
            Cualquier cambio de dirección o instrucción con relación a la administración debe hacerse por escrito.{' '}
          </TermsList>
          <TermsList>
            Darle al propietario un 10% de descuento sobre la Comisión de Cierre y Comisión de Manejo (definidas en
            contrato de mandato)
          </TermsList>
          <TermsList>Las demás indicadas en estos Términos y Condiciones.</TermsList>
        </ul>
      </TermsContent>
    </ModalBase>
  );
};

export { TermsAndConditionsModal };
