import { AptunoButton, useResponsive } from '@aptuno/aptuno-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { env } from '../../../environment/environment';
import { capitalize } from '../../../helpers/coolFunctions';
import history from '../../../history';
import { BoxImage, Container, HomeButton, Image, MessageText, TitleText } from './NotResidentialRentPage.styled';
import { useAnalyticsTracker } from '../../../services/analytics';
import { OnlyResidentialEstatesRedirect } from '../events/only-residential-estates-redirect.event';
import { standardRawUrlDataFromLocation, standardRawUrlDataFromPath } from '../../../hooks/standardRowUrlData';

const constants = {
  HOME_PATH: '/',
};

const NotResidentialRentPage = () => {
  const Responsive = useResponsive();
  const { t } = useTranslation('glossary');
  const eventTracker = useAnalyticsTracker();

  const goToHome = () => {
    const event = new OnlyResidentialEstatesRedirect(
      standardRawUrlDataFromLocation(),
      standardRawUrlDataFromPath(constants.HOME_PATH)
    );

    eventTracker.trackEvent(event).then(() => history.push('/'));
  };

  return (
    <Container responsive={Responsive}>
      <BoxImage responsive={Responsive}>
        <Image
          src="/images/autopublish/not_residential_rent_page.svg"
          alt="Inmueble No Residencial o Fuera de Cobertura"
          title="En Aptuno solo publicamos inmuebles residenciales"
        />
      </BoxImage>

      <TitleText>En esta ocasión, no podemos ayudarte.</TitleText>

      <MessageText>
        En Aptuno solo publicamos inmuebles residenciales (para vivienda) que se encuentren en {t('lease')}, pero
        esperamos ampliar nuestra oferta de servicios en el futuro.
      </MessageText>

      <HomeButton responsive={Responsive}>
        <AptunoButton onClick={goToHome} appearance={'primary'} size={'big'}>
          Ir a {capitalize(env.urlAptuno)}
        </AptunoButton>
      </HomeButton>
    </Container>
  );
};

export default NotResidentialRentPage;
