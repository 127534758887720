import styled from 'styled-components';

const Disclaimer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-start')};
  margin: 30px 16px;
`;

const DisclaimerText = styled.p`
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.008em;
  color: #000000;
  margin: 8px 0px;

  @media screen and (min-width: 600px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const DateSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const DateBox = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  max-width: calc(100% + 32px);
  min-height: 88px;
  padding: 0px 16px;
`;

const DateSlot = styled.div`
  min-width: 56px;
  height: 69px;
  background: ${(props) => (props.active ? '#0055FB' : '#FFFFFF')};
  box-shadow: 0px 10px 25px ${(props) => (props.active ? '#5F676E66' : '#6C757D26')};
  border-radius: 16px;
  margin: 3px;
  padding: 5px;
  cursor: pointer;

  :hover {
    box-shadow: 0px 10px 25px #5f676e66;
  }

  h3,
  h2 {
    color: ${(props) => (props.active ? '#FFFFFF' : '#4A4A4A')};
  }
`;
const DayOfWeek = styled.h3`
  text-align: center;
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 500;
  line-height: 24px;
  font-size: 18px;
`;
const DayNum = styled.h2`
  text-align: center;
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 500;
  line-height: 26px;
  font-size: 24px;
`;

const TimeBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  overflow-y: auto;
  width: 100%;
  flex-wrap: wrap;
  /* max-height: calc(100vh - 400px); */
  justify-content: center;
  min-width: calc(100% + 32px);

  @media screen and (min-width: 600px) {
    max-height: calc(100vh - 449px);
  }

  /* ----------- iPhone 6, 6S, 7 and 8 ----------- */
  /* Portrait and Landscape */
  @media screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    max-height: calc(100vh - 32rem);
  }
  /* ----------- iPhone 6+, 7+ and 8+ ----------- */
  /* Portrait and Landscape */
  @media screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
    max-height: calc(100vh - 33.5rem);
  }
  /* ----------- iPhone X ----------- */
  /* Portrait and Landscape */
  @media screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    max-height: calc(100vh - 29.5rem);
  }
`;

const ConfirmedTimeBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  overflow-y: auto;
  width: 100%;
  flex-wrap: wrap;
  /* max-height: calc(100vh - 510px); */
  justify-content: center;
  min-width: calc(100% + 32px);
`;

const TimeSlot = styled.div`
  height: 40px;
  background: ${(props) => (props.active ? '#FD8D5D' : props.isConfirmed ? '#FFF9EB' : '#FFFFFF')};
  box-shadow: 0px 10px 25px ${(props) => (props.active ? '#6C757D26' : '#6C757D26')};
  border-radius: 5px 8px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 4px;
  cursor: pointer;
  width: 42%;
  align-items: center;
  svg {
    color: ${(props) => (props.active ? '#FFFFFF' : props.isConfirmed ? '#FFBD11' : '#4A4A4A')};
  }

  p {
    color: ${(props) => (props.active ? '#FFFFFF' : '#4A4A4A')};
    margin-bottom: 0;
    margin-left: 16px;
    margin-right: 0px;
    margin-top: 0;
  }

  :hover {
    box-shadow: 0px 10px 25px #5f676e66;
  }

  @media screen and (min-width: 600px) {
    width: 134px;
  }
`;
const Text = styled.p`
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  color: #4a4a4a;
  margin: 8px 16px;
`;

const DateTimeLabel = styled.h2`
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  letter-spacing: -0.008em;
  margin: 12px 0px;

  @media screen and (min-width: 600px) {
    font-size: 28px;
    line-height: 32px;
  }
`;

const ConfirmedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Divider = styled.hr`
  width: 164px;
  border-top: 1px solid #dee2e6;
  margin: 8px;

  @media screen and (min-width: 600px) {
    margin: 16px;
  }
`;

const AddressLabel = styled.h2`
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #4a4a4a;
  margin-top: 8px;
  margin-bottom: 32px;

  @media screen and (min-width: 600px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
`;

const TextDescription = styled.p`
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0 24px 36px !important;
  color: #4a4a4a;
  margin: 0px;
`;

const ImageHeader = styled.img`
  max-width: 80%;
  max-height: 120px;
  margin: 16px;

  @media (min-width: 600px) {
    max-height: 150px;
    margin: 30px;
  }
`;

const Label = styled.p`
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #4a4a4a;
  margin-top: 0px;
  width: 100%;
  text-align: center;
`;

const AppointmentUserFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  & label {
    line-height: 16px !important;
  }
`;

const TypeSelectorForm = styled.form`
  margin: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > * {
    margin: 10px 0 0 10px;
  }

  margin-bottom: 40px;

  @media (min-width: 600px) {
    margin-bottom: 75px;
  }
`;

const AppointmentForm = styled.form`
  margin: 0 auto;
  width: 100%;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  background: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px !important;

  & div {
    width: 100%;
    justify-content: center;
  }
  & > * {
    margin-bottom: 10px;
  }
  @media (min-width: 600px) {
    padding: 0 1.5rem;
    margin-bottom: 54px !important;
    padding: 0 1rem;
  }
`;

const LinkToStep = styled.span`
  color: #007bff;
  cursor: pointer;
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  @media screen and (min-width: 600px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const GroupedFields = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionsWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: 9;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
  left: 0;
  background: transparent;
  padding: 4px 0px;
  margin-top: 6px;
  background-color: #f8f8f8;

  @media (min-width: 600px) {
    border-radius: 0px 0 24px 24px;
    box-shadow: none;
  }

  button {
    max-width: 426px;
    text-align: center;
    margin: 0 8px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 15px;

  & > svg {
    cursor: pointer;
  }
`;
const ModalTitle = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const AlertContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  @media (max-width: 600px) {
    margin-bottom: 75px;
  }
`;

const LabelInformative = styled.label`
  background: #fff9eb;
  font-size: 12px;
  padding: 4px;
  border-radius: 10px;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  svg {
    color: #ffbd11;
    margin: 4px;
  }
`;

const LabelInformativeLeft = styled.div``;
const LabelInformativeRight = styled.div``;

const TimeBoxTitle = styled.h3`
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.008em;
  color: #000000;
  text-align: left;
`;

const TimeBoxDescription = styled.p`
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #555a64;
  text-align: left;
`;

const TimeBoxTextWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: start;
  width: 100%;
`;

const ShowAnotherOptions = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: #555a64;
  font-size: 14px;

  :hover {
    color: #555a64;
    text-decoration: underline;
  }
`;

export const TermsAndConditionsSection = styled.div`
  display: flex;
  align-items: start;
  padding: 16px 0;
  color: #6c757d;
  font-size: 16px;
  line-height: 24px;
  & > div {
    height: max-content;
    width: auto;
  }
  & p {
    margin: 0;
  }
  & a {
    text-decoration: underline;
    color: inherit;
  }
`;

export {
  DateSelectorWrapper,
  Disclaimer,
  DisclaimerText,
  DateBox,
  DateSlot,
  DayOfWeek,
  DayNum,
  TimeBox,
  ConfirmedTimeBox,
  TimeSlot,
  DateTimeLabel,
  Text,
  ConfirmedWrapper,
  Divider,
  AddressLabel,
  TextDescription,
  ImageHeader,
  AppointmentUserFormWrapper,
  Label,
  LinkToStep,
  AppointmentForm,
  GroupedFields,
  ActionsWrapper,
  TypeSelectorForm,
  ModalHeader,
  ModalTitle,
  AlertContainer,
  LabelInformative,
  LabelInformativeLeft,
  LabelInformativeRight,
  TimeBoxTitle,
  TimeBoxDescription,
  TimeBoxTextWrapper,
  ShowAnotherOptions,
};
