import React from 'react';
import { userTypes } from '../data/miscellaneous';
import { OtpInput as OtpInputValidator } from './OtpInput';

export default function OTPValidator(props) {
  const {
    country,
    phoneNumber,
    onValidate,
    onRetry,
    className,
    authChannel,
    type = userTypes.tenant,
    nameSource = 'organic',
    nameOrigin = 'product',
    isLoading = false,
    address,
    area,
    roomsNr,
    bathRoomsNr,
    stratum,
    parkingLotOptions,
    furnished,
    city,
    suggestedPrice,
    leadOrigin,
    latitude,
    longitude
  } = props;
  return (
    <OtpInputValidator
      otpLength={6}
      country={country}
      phoneNumber={phoneNumber}
      userType={type}
      tracking={{
        authChannel,
        origin: nameOrigin,
        source: nameSource
      }}
      className={className}
      onRetry={onRetry}
      onValidate={onValidate}
      externalLoadingInProgress={isLoading}
      address={address}
      area={area}
      roomsNr={roomsNr}
      bathRoomsNr={bathRoomsNr}
      stratum={stratum}
      parkingLotOptions={parkingLotOptions}
      furnished={furnished}
      city={city}
      suggestedPrice={suggestedPrice}
      leadOrigin={leadOrigin}
      latitude={latitude}
      longitude={longitude}
    />
  );
}
