import styled from 'styled-components';

const ProcessContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 25px #6c757d0f;
  border-radius: 0px 0px 24px 24px;
  height: 76px;
  padding: 16px;
  margin:auto;
  max-width: 688px;
  transition: 0.3s all ease;

  @media screen and (min-width: 800px){
    height: 72px;
  }
  @media screen and (min-width: 1000px) {
    max-width: 1031px;
  }
`;

const Title = styled.h4`
  font-family: 'Satoshi',-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.008em;
  color: #000000;
  text-align: left;
  width: 100%;
  margin-top: 16px;
  margin-left: 72px;
  
`;

const Icon = styled.span`
  margin: 0px 1%;
  font-size: 1.8rem;
  color: #fead29;
`;

const Text = styled.p`
  text-align: left;
  font: normal normal medium 14px "Satoshi";
  font-size: 12px;
  letter-spacing: 0px;
  color: #6c757d;
  opacity: 1;
  margin: 0px;
`;

const IconContainer = styled.div`
  background: #fff7e9 0% 0% no-repeat padding-box;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color || '#FEAD29'};
  background-color: ${({ backgroundColor }) => backgroundColor || '#FFF7E9'};

  & > svg {
    width: 20px;
    height: auto;
  }
`;

const BarInfoWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
`;

const TextInfoWrapper = styled.div``;

export {
  ProcessContainer,
  Title,
  Icon,
  Text,
  IconContainer,
  BarInfoWrapper,
  TextInfoWrapper,
};
