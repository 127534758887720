import styled from 'styled-components';

const StickyWrapper = styled.div`
  z-index: 99 !important;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;
const StickyBox = styled.div`
  width:100%;
  padding: 16px;
  position: fixed;
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  h2, p {
    color: ${(props) => props.color ? props.color : 'white'};
  }
  background-color: ${(props) => props.bgColor ? props.bgColor : '#0055FB'};
  box-shadow: 0px -18px 25px 0px #6c757d47;

  .close {
    color: white;
    opacity: 1;
    cursor: pointer;
  }
`;
const Content = styled.div`
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% - 42px);
`;

const StickyTitle = styled.h2`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  font-family: 'Satoshi',-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  width: 100%;
`;
const Backdrop = styled.div`
  position: fixed;
  background: lightgray;
  width:100vw;
  height: 100vh;
  z-index: 98;
  top:0;
  left:0;
  opacity: .5;
  backdrop-filter: blur(2px);
`;
const CallToActionEl = styled.p`
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
`;

export {
  StickyWrapper,
  StickyBox,
  StickyTitle,
  Content,
  Backdrop,
  CallToActionEl
}
