import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import config from "../config";
import { env } from "../environment/environment";
import history from "../history";

const InitSentry = () => {
  if(config.isProdEnv) {
    Sentry.init({
      dsn: env.sentryDns,
      autoSessionTracking: true,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        }),
      ],
      tracesSampleRate: 0.1,
      release: "aptuno-web@" + process.env.npm_package_version,
    });
  }
}

export default InitSentry;
