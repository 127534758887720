import gql from 'graphql-tag';
import { useQuery } from '@apollo/client/react/hooks';

export const GET_PROPERTY = gql`
  query Property($propertyId: String!) {
    property(propertyId: $propertyId) {
      id
      userFav
      userDiscard
      title
      priceWater
      priceElectricity
      priceGas
      priceTIC
      address
      propertyLegalId
      latitude
      longitude
      status
      isVerified
      isExclusive
      propertyType
      currencyIso
      administrationPrice
      price
      about
      isPriceDropped
      media {
        id
        uri
        main
      }
      characteristics {
        value
        withValue
        name
        slug
        category
      }
      location {
        status
        name
        code
      }
      recommendations {
        kind
        calculationMethod {
          method
          version
        }
        recommendedEstates {
          legalId
          ranking {
            calculatedRank
            obtainedScoring
          }
          title
          type
          currencyISO
          administrationPrice
          price
          rooms
          bathrooms
          area
          image
          isAnewProperty
          isInHighDemand
          isFurnished
          status
          isPriceDropped
          propertyType
        }
      }
    }
  }
`;

export const GET_PROPERTIES = gql`
  query Posts {
    properties {
      id
      userFav
      title
      address
      propertyLegalId
      latitude
      longitude
      status
      isVerified
      propertyType
      propertyAge
      currencyIso
      administrationPrice
      price
      about
      media {
        id
        uri
        media
      }
      characteristics {
        value
        withValue
        name
        slug
      }
    }
  }
`;

//$propertyAge: String
// propertyAge: $propertyAge,

export const GET_PROPERTIES_FILTERED = gql`
  query Properties(
    $locationSlug: String
    $offset: Int
    $limit: Int
    $orderBy: PropertiesOrderByInput
    $minPrice: Int
    $maxPrice: Int
    $rooms: Int
    $locationId: Int
    $propertyType: String
    $propertyAge: String
    $minGarajes: Int
    $minBaths: Int
    $minArea: Int
    $maxArea: Int
    $stratum: [Int]
    $characteristics: [CharacteristicSearchFilter]
  ) {
    properties(
      filters: {
        minPrice: $minPrice
        maxPrice: $maxPrice
        minRooms: $rooms
        locationSlug: $locationSlug
        locationId: $locationId
        propertyType: $propertyType
        propertyAge: $propertyAge
        minBathrooms: $minBaths
        minGarajes: $minGarajes
        minArea: $minArea
        maxArea: $maxArea
        stratum: $stratum
        characteristics: $characteristics
      }
      from: $offset
      size: $limit
      orderBy: $orderBy
    ) {
      values {
        id
        userFav
        title
        latitude
        longitude
        propertyType
        propertyLegalId
        currencyIso
        administrationPrice
        price
        rooms
        bathrooms
        area
        images {
          id
          uri
          main
        }
        highDemand
        newProperty
        isFurnished
        status
        isVerified
        isExclusive
        isPriceDropped
        hasTerrace
        hasBalcony
      }
      count
      avgPrice
    }
  }
`;

export const GET_PROPERTIES_SITEMAP = gql`
  query SitemapProperties($offset: Int, $limit: Int) {
    properties(filters: {}, from: $offset, size: $limit) {
      values {
        id
        propertyType
        propertyLegalId
        administrationPrice
        price
        rooms
        bathrooms
        area
        __typename
      }
      count
      __typename
    }
  }
`;

export const UNPUBLISH_ESTATE = gql`
  mutation UnpublishEstate($estateId: Int!, $unpublishReason: String!) {
    unpublishEstate(estateId: $estateId, unpublishReason: $unpublishReason)
  }
`;

export default () => useQuery(GET_PROPERTIES);
