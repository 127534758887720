import React from 'react';
import {
  Container,
  Item,
  Image,
  SubContainer,
  TitleText,
  MessageText,
  Content,
  HeaderBox,
} from './ModalLeave.styled';

import { AptunoButton } from '@aptuno/aptuno-ui';
import history from '../../../history';

function ModalLeave({ isPublish = true, onClose }) {
  return (
    <Container>
      <HeaderBox />
      <SubContainer>
        <Image src="/images/PublishLeave.svg" alt="leave" title="Imagen de Cancelar Proceso | Aptuno" />
        <Content>
          <TitleText>Vas a abandonar tu { isPublish ? 'publicación.' : 'agendamiento'}</TitleText>
          {isPublish && <MessageText>Guardaremos la información que has ingresado hasta el momento. ¿Pero estás seguro?</MessageText>}
          <Item>
            <AptunoButton onClick={() => history.push('/')} appearance={'danger'} size={'big'}>
              Sí, abandonar.
            </AptunoButton>
          </Item>
          <Item>
            <AptunoButton onClick={onClose} appearance={'secondary'} size={'big'}>
              No, volver  { isPublish ? 'a la publicación.' : 'al agendamiento.'}
            </AptunoButton>
          </Item>
        </Content>
      </SubContainer>
    </Container>
  );
}

export default ModalLeave;
