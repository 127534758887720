import { TrackingEvent } from '../model';

export type GTMEvent = {
  category: string;
  action: string;
  label?: string;
  value: string;
};

type GTMEventDto = {
  dataLayer: {
    event: string;
    GAevent: GTMEvent;
  };
};

export const GTMEventDto = {
  fromGTMEvent: (event: GTMEvent): GTMEventDto => ({
    dataLayer: {
      event: 'GAevent',
      GAevent: event,
    },
  }),

  fromTrackingEvent: (event: TrackingEvent): GTMEventDto => {
    const gtmEvent: GTMEvent = {
      action: event.action,
      category: event.category,
      label: event.label,
      value: JSON.stringify(event),
    };

    return GTMEventDto.fromGTMEvent(gtmEvent);
  },
};
