export const environmentProdMX = {
  dev: false,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_APPLICATION_CREDENTIALS,
  segmentAnalyticsApiKey: 'IDmh1ZXoUCHG9e5KRzMfJ2ub5iT3UrFG',
  sentryDns: 'https://2dbc69622a8b42a09cf30c8e5c5ea63e@o491496.ingest.sentry.io/5818750',
  countryCode: 'MX',
  countryName: 'Mexico',
  countryPhoneCode: '+52',
  showStickyBar: true,
  gcpStorage: {
    baseUrl: 'https://storage.googleapis.com/aptuno-assets-mx',
    cdnBaseUrl: 'https://cdn.aptuno.mx',
  },
  riskStudyUrl: 'https://qa-risk-study.aptuno.com/graphql',
  defaultSlugLocation: 'ciudad-de-mexico-mexico',
  principalCity: 'Ciudad de México',
  whatsAppNumber: '573112955504',
  urlAptuno: 'aptuno.mx',
  tagManagerKey: 'GTM-N95DZ28',
  defaultUrlLocation: 'ciudad-de-mexico',
  fbPixelKey: '319632946178069',
  mapsDefaultCoors: {
    lat: 19.4064793,
    lng: -99.1511748,
  },
  estateInfoForm: {
    price: {
      required: true,
      min: 5_000,
      max: 100000,
    },
    adminPrice: {
      required: false,
      min: 200,
      max: 20000,
    },
  },
  characteristics: {
    complementary: [
      { name: 'Balcón', slug: 'hasBalcony' },
      { name: 'Bodega', slug: 'deposit' },
      { name: 'Patio', slug: 'hasYard' },
      { name: 'Terraza', slug: 'hasTerrace' },
    ],
    security: [
      { name: 'Alarma', slug: 'hasAlarm' },
      { name: 'Circuito Cerrado de TV', slug: 'hasTvSurveillance' },
      { name: 'Puerta de Seguridad', slug: 'hasSafetyDoor' },
    ],
    interior: [
      { name: 'Chimenea', slug: 'hasFirePlace' },
      { name: 'Cuarto de Servicio', slug: 'hasServiceRoom' },
      { name: 'Estudio-Biblioteca', slug: 'hasStudy' },
      { name: 'Instalación de Gas', slug: 'hasNaturalGas' },
      { name: 'Jacuzzi', slug: 'hasJacuzzi' },
    ],
    exterior: [
      { name: 'Elevador', slug: 'hasElevator' },
      { name: 'Cancha de Basketball', slug: 'hasBasketField' },
      { name: 'Cancha de Fútbol', slug: 'hasSoccerField' },
      { name: 'Cancha de Squash', slug: 'hasSquashField' },
      { name: 'Cancha de Tenis', slug: 'hasTennisField' },
      { name: 'Gimnasio', slug: 'hasGym' },
      { name: 'Alberca', slug: 'hasSwimmingPool' },
      { name: 'Salón de fiestas', slug: 'hasComunalLiving' },
      { name: 'Sauna', slug: 'hasSauna' },
      { name: 'Roof Garden', slug: 'hasBbqZones' },
      { name: 'Zona de Niños', slug: 'hasChildrenZones' },
      { name: 'Zonas Verdes', slug: 'hasGreenZones' },
    ],
  },
  relevantLinks: [
    {
      urlLink: 'https://www.aptuno.mx/inmuebles-arriendo/ciudad-de-mexico-mexico',
      description: 'Departamentos en renta CDMX',
    },
    {
      urlLink: 'https://www.aptuno.mx/inmuebles-arriendo/ciudad-de-mexico-mexico?priceMax=7000&rooms=2',
      description: 'Departamentos en renta CDMX 2 recámaras y hasta 7,000',
    },
    {
      urlLink: 'https://www.aptuno.mx/inmuebles-arriendo/ciudad-de-mexico-mexico?priceMax=7000',
      description: 'Departamentos en renta CDMX hasta 7,000',
    },
    {
      urlLink: 'https://www.aptuno.mx/inmuebles-arriendo/ciudad-de-mexico-mexico?priceMax=9000&rooms=2',
      description: 'Departamentos en renta CDMX 2 recámaras y hasta 9,000 ',
    },
    {
      urlLink: 'https://www.aptuno.mx/inmuebles-arriendo/ciudad-de-mexico-mexico?rooms=2',
      description: 'Departamentos en renta CDMX 2 recámaras',
    },
    {
      urlLink: 'https://www.aptuno.mx/inmuebles-arriendo/ciudad-de-mexico-mexico?priceMax=7000&rooms=3',
      description: 'Departamentos en renta CDMX 3 recámaras y hasta 7,000',
    },
    {
      urlLink: 'https://www.aptuno.mx/inmuebles-arriendo/ciudad-de-mexico-mexico?priceMax=9000&rooms=3',
      description: 'Departamentos en renta CDMX 3 recámaras y hasta 9,000',
    },
    {
      urlLink: 'https://www.aptuno.mx/inmuebles-arriendo/ciudad-de-mexico-mexico?priceMax=11000&rooms=3',
      description: 'Departamentos en renta CDMX 3 recámaras y hasta 11,000',
    },
  ],
  documentTypes: [
    { name: 'INE', value: 'CC' },
    { name: 'PAS', value: 'PA' },
  ],
  logos: [
    { name: 'icasas', alt: 'icasas logo', path: '/images/portal-logos/icasas.png' },
    { name: 'excelsior', alt: 'excelsior logo', path: '/images/portal-logos/excelsior.png' },
    {
      name: 'lagraninmobiliaria',
      alt: 'la gran inmobiliaria logo',
      path: '/images/portal-logos/la-gran-inmobiliaria.png',
    },
    { name: 'nura', alt: 'nura logo', path: '/images/portal-logos/nura.png' },
    { name: 'inmuebles24', alt: 'inmuebles24 logo', path: '/images/portal-logos/inmuebles-24.png' },
    { name: 'goplaceit', alt: 'goplaceit logo', path: '/images/portal-logos/goplaceit.png' },
    { name: 'vivanuncios', alt: 'vivanuncios logo', path: '/images/portal-logos/vivanuncios.png' },
    { name: 'mitula', alt: 'logo', path: '/images/portal-logos/mitula.png' },
    { name: 'propiedades.com', alt: 'propiedades.com logo', path: '/images/portal-logos/propiedades-com.png' },
  ],
  contentful: {
    legal: {
      contentType: 'legalMx',
      privacyId: '3bj2Go26TDT7q5Ve1tu0Kc',
      termsAndConditionsId: '4GCk3rnQIG8cbOSEaY59Ul',
    },
    stickybar: 'stickybarMx',
    localGuides: 'localGuidesMx',
    faq: 'faqMx',
    blog: 'blogMx',
  },
  leaseRequirements: [
    'Tus estados financieros (últimos 3 meses)',
    'Identificación (INE o pasaporte)',
    'Comprobante de ingresos',
  ],
  urlRealStateCompanies: 'https://inmobiliaria.aptuno.mx/',
  urlReferAndWin: 'https://renta.aptuno.mx/propietarios/refiere-gana/index.html',
  urlReferForAgents: 'https://renta.aptuno.mx/asesores-04/asesores.html',
  decimalSeparator: '.',
  groupSeparator: ',',
  estateTypesArray: ['Casa', 'Departamento'],
  landlordVideoUrl: 'https://www.youtube.com/watch?v=IUJl2DaPGKE',
  linkRelevantsError404: [
    {
      title: 'Descubre inmuebles para alquilar',
      href: 'https://www.aptuno.mx/inmuebles-arriendo',
    },
    {
      title: 'Publica tu inmueble',
      href: 'https://www.aptuno.com.mx/anunciar-casa-o-departamento-en-renta/',
    },
    {
      title: 'Refiere inmuebles y gana dinero',
      href: 'https://www.refiereygana.aptuno.mx/',
    },
    {
      title: 'Conoce Aptuno, su equipo y misión',
      href: 'https://www.aptuno.mx/quienes-somos',
    },
  ],
  defaultGeneralEstatePublicationQualityIndex: {
    amountOfPictures: {
      value: 14,
      allowedDeviation: 2,
    },
    descriptionLength: {
      value: 400,
      allowedDeviation: 20,
    },
  },
  plansForConsignedEstate: {
    aptunoBrokerage: {
      friendlyName: 'Corretaje Aptuno',
      includes: [
        {
          text: '<strong>Publicación</strong> en Aptuno y en portales aliados',
          icon: 'plus',
        },
        {
          text: '<strong>Búsqueda del inquilino ideal</strong> para tu inmueble en tiempo récord',
          icon: 'plus',
        },
        {
          text: '<strong>Acompañamiento en visitas</strong> con agentes certificados',
          icon: 'plus',
        },
        {
          text: '<strong>Elaboración de contrato digital</strong> 100% seguro y fácil de llenar',
          icon: 'plus',
        },
      ],
      price: [
        {
          text: '<strong>Primer renta</strong> (incluyendo mantenimiento) al momento de la firma de contrato',
          icon: 'currency',
        },
      ],
    },
    aptunoWarranty: {
      friendlyName: 'Garantía Aptuno',
      includes: [
        {
          text: 'Todo lo que incluye el plan',
          referencePlanText: 'Corretaje Aptuno',
          referencePlanColor: '#F0AE00',
          icon: 'check',
        },
        {
          text: '<strong>Garantía de pago puntual</strong> de renta y mantenimiento hasta 12 meses',
          icon: 'plus',
        },
        {
          text: '<strong>Verificación de perfiles</strong> y estudio de crédito para inquilinos',
          icon: 'plus',
        },
        {
          text: '<strong>Asesoría jurídica</strong> en todo lo relacionado al contrato de arrendamiento',
          icon: 'plus',
        },
        {
          text: '<strong>Póliza jurídica</strong> básica hasta por $50.000 MXN',
          icon: 'plus',
        },
      ],
      price: [
        {
          text: '<strong>4% del valor de renta mensual + mantenimiento</strong> a partir del segundo mes de contrato',
          icon: 'currency',
        },
      ],
    },
    blueAptuno: {
      friendlyName: 'Aptuno Azul',
      includes: [
        {
          text: 'Todo lo que incluye el plan',
          referencePlanText: 'Garantía Aptuno',
          referencePlanColor: '#D250E6',
          icon: 'check',
        },
        {
          text: '<strong>Gestión de reparaciones</strong> y mantenimiento del inmueble',
          icon: 'plus',
        },
        {
          text: '<strong>Seguro de todo riesgo</strong> en alianza con Zurich',
          icon: 'plus',
        },
      ],
      price: [
        {
          text: '<strong>8% del valor de renta mensual + mantenimiento</strong> a partir del segundo mes de contrato',
          icon: 'currency',
        },
      ],
    },
    aptunoPlus: {
      friendlyName: 'Aptuno Plus',
      includes: [
        {
          text: 'Todo lo que incluye el plan',
          referencePlanText: 'Aptuno Azul',
          referencePlanColor: '#0055FB',
          icon: 'check',
        },
        {
          text: '<strong>Garantía de pago puntual</strong> de renta y mantenimiento hasta 24 meses',
          icon: 'plus',
        },
        {
          text: '<strong>Póliza jurídica</strong> con exinción de dominio hasta por $50.000 MXN',
          icon: 'plus',
        },
        {
          text: '<strong>Seguro con Zurich</strong> Hogar plus + Cristales +  Terremoto',
          icon: 'plus',
        },
      ],
      price: [
        {
          text: '<strong>10% del valor de renta mensual + mantenimiento</strong> a partir del segundo mes de contrato',
          icon: 'currency',
        },
      ],
    },
  },
  blackListEndpoint: 'https://core.aptuno.mx/blacklist',
  price: {
    min: 4000,
    max: 250000,
    step: 500,
  },
  area: {
    min: 20,
    max: 500,
    step: 1,
  },
  landlordsSupportPhone: '55 4770 7392',
};
