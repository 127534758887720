import { useLazyQuery } from '@apollo/client/react/hooks';
import { Theme } from '@aptuno/aptuno-ui';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useReducer, useState } from 'react';
import { ContentfulClient, ContentfulProvider } from 'react-contentful';
import { HelmetProvider } from 'react-helmet-async';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-phone-number-input/style.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import AnalyticsContextProvider from './containers/SegmentAnalyticsProvider';
import Stickybar from './containers/Stikybar/Stickybar';
import { env } from './environment/environment';
import GlobalStyle from './GlobalStyles';
import { useFacebookPixel } from './helpers/analiticsUtils';
import { Responsive } from './helpers/coolFunctions';
import InitSentry from './helpers/sentry';
import { useEstatePublicationQualityIndex } from './hooks/useEstatePublicationQualityIndex';
import { GET_FILTERS } from './hooks/useFilterQuery';
import Providers from './Providers';
import { appointmentReducer, initialState } from './reducers/appointmentReducer';
import { Routes } from './router';
import './sass/index.scss';

InitSentry();
const contentfulClient = new ContentfulClient({
  accessToken: 'JhRgQNVFGRBZ99oCk0qNZvTVBczgtWfRZEPT1xbDjaw',
  space: 'bj5ota02hmze',
});

const filterContext = React.createContext(null);
const filterDispatch = React.createContext(null);
const responsiveContext = React.createContext(null);
const rentReportContext = React.createContext();
const appointmentContext = React.createContext(null);
const appointmentDispatch = React.createContext(null);
const generalPublicationQualityIndexContext = React.createContext();

export const App = (props) => {
  const [getFilters, { loading: loadingGetFilters, error: errorGetFilters, data: dataGetFilters }] = useLazyQuery(
    GET_FILTERS,
    { errorPolicy: 'all' }
  );
  const { qualityIndexValidator, generalPublicationQualityIndex } = useEstatePublicationQualityIndex();

  const [openSticky, setOpenSticky] = useState(false);
  const [responsive, setResponsive] = React.useState(null);
  const [rentPredictionReport, setRentPrediction] = useState({});

  const [appointmentState, appointmentStateDispatch] = useReducer(appointmentReducer, initialState);

  useFacebookPixel();

  useEffect(() => {
    new Responsive(setResponsive);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setOpenSticky(true), 5000);

    return () => clearTimeout(timeout);
  }, []);

  const helmetContext = {};

  return (
    <Providers>
      <HelmetProvider context={helmetContext}>
        <AnalyticsContextProvider>
          <generalPublicationQualityIndexContext.Provider
            value={{ qualityIndexValidator, generalPublicationQualityIndex }}
          >
            <filterContext.Provider value={{ errorGetFilters, dataGetFilters, loadingGetFilters }}>
              <filterDispatch.Provider value={{ getFilters }}>
                <responsiveContext.Provider value={{ responsive: responsive || new Responsive().checkSizes() }}>
                  <rentReportContext.Provider value={{ rentPredictionReport, setRentPrediction }}>
                    <ContentfulProvider client={contentfulClient}>
                      <appointmentContext.Provider value={[appointmentState, appointmentStateDispatch]}>
                        <Theme>
                          <Routes />
                        </Theme>
                      </appointmentContext.Provider>
                      {env.showStickyBar && openSticky && <Stickybar color={'white'} bgColor={'#323232'} />}
                    </ContentfulProvider>
                    <GlobalStyle />
                  </rentReportContext.Provider>
                </responsiveContext.Provider>
              </filterDispatch.Provider>
            </filterContext.Provider>
          </generalPublicationQualityIndexContext.Provider>
        </AnalyticsContextProvider>
      </HelmetProvider>
    </Providers>
  );
};

export {
  App as default,
  filterContext,
  filterDispatch,
  responsiveContext,
  rentReportContext,
  appointmentContext,
  appointmentDispatch,
  generalPublicationQualityIndexContext,
};
