import styled, { createGlobalStyle, css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 0px 16px;
  max-width: 629px;
  margin: auto;
  margin-top: 32px;

  ${props =>
    props.Responsive.isDesktop &&
    `
    display: block;
  `};
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #F8F8F8;
    padding-bottom: 80px;
  }
`;

const Item = styled.div`
  width: 100%;
`;

const TextDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  margin-top: 8px;

  color: ${props => (props.color ? props.color : '#6C757D')};
`;

const TextTitle = styled.div`
  margin-bottom: 8px;
  color: #000000;
  width: fit-content;
  height: 24px;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.008em;
  color: #555a64;
  margin: 8px 0px;
`;

const gradient = css`
  background: radial-gradient(
      88.02% 352.08% at 7.4% 10.62%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(235, 239, 203, 0.3) 24.59%,
      rgba(255, 244, 215, 0.3) 46.28%,
      rgba(230, 237, 255, 0.3) 68.86%,
      rgba(248, 218, 232, 0.3) 100%
    )
    #ffffff;

  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.06);
`;

const RadioContainer = styled.div`
  margin-top: 32px;
  width: 100%;
  max-height: 126px;
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.06);
  border-radius: 16px;
  padding: 24px 24px 24px 24px;
  position: relative;
  ${props => (props.selected ? gradient : null)}
`;

const MainRadioContainer = styled(RadioContainer)`
  ${props => (props.selected ? gradient : null)}
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const RadioImage = styled.img`
  position: absolute;
  right: 0;
  top: 0;
`;

const RadioTitle = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.024em;
  color: ${props => (props.isExclusive ? '#000000' : '#555A64')};
`;

const RadioDescription = styled.div`
  margin-top: 13px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${props => (props.isExclusive ? '#6c757d' : '#6C757D')};
`;

const BenefitsContainer = styled.div`
  margin-top: 32px;
`;

const PlanItemStyle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 12px;
  margin-bottom: 11px;
  padding: 0px 0px;
  ${props => props.backgroundColor && `background: ${props.backgroundColor}; border-radius: 16px;`}
  ${props => props.backgroundColor && `padding: 8px 20px;`}

  & > svg {
    height: 16px;
    width: auto;
    flex-shrink: 0;
    color: ${props => props.iconColor || '#3cd278'};
  }
`;

const PlanItemText = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #555a64;
  margin: 0px;
`;

const BenefitsTitle = styled.h2`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.008em;
  color: #000000;
  margin-bottom: 12px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

const TermsButton = styled.button`
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #6c757d;
  padding: 8px 12px;
`;
const TermsContent = styled.div`
  margin: 16px;
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
`;

const TermsTittle1 = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-align: left;

  letter-spacing: -0.024em;

  color: ${props => props.color || '#000000'};
`;

const TermsTittle2 = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  letter-spacing: -0.008em;

  color: #000000;
`;

const TermsParagraph = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #6c757d;
`;
const TermsList = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 1px;

  color: #6c757d;
`;

export {
  Container,
  GlobalStyle,
  Item,
  TextDescription,
  TextTitle,
  RadioImage,
  RadioTitle,
  RadioDescription,
  RadioContainer,
  MainRadioContainer,
  RadioWrapper,
  BenefitsContainer,
  PlanItemText,
  PlanItemStyle,
  BenefitsTitle,
  ButtonContainer,
  TermsButton,
  TermsTittle1,
  TermsTittle2,
  TermsParagraph,
  TermsContent,
  TermsList,
};
