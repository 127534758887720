import { gaEvent } from '../../helpers/coolFunctions';
import { userTypes } from '../../data/miscellaneous';
import { AnalyticsProps } from './types';
import { Tracker } from '../../services/analytics/model';
import { LandlordSignedUp } from './events/landlord-signed-up.event';
import { TenantSignedUp } from './events/tenant-signed-up.event';

function reportOtpTrackingData(analytics: AnalyticsProps, tracker: Tracker) {
  const { user, tracking } = analytics;
  const { data: userData, type: userType, phoneNumber } = user;
  const {
    parent: { source: nameSource, origin: nameOrigin, authChannel },
    item: { source: utmSource, campaign: utmCampaign, medium: utmMedium },
  } = tracking;

  const identifyProps = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    phoneNumber: userData.phoneNumber,
    role: userType,
    nameSource,
    utmSource,
    utmCampaign,
    utmMedium,
  };

  const userId = userData.id.toString();

  tracker.trackIdentity({ userId, ...identifyProps });

  if (nameSource === 'RentCalculator') {
    if (userData && !userData.email) {
      gaEvent('Registro-Propietario-Calculadora', `UserId = ${userData.id}  VIA = ${authChannel} Source=${nameSource}`);
    } else {
      gaEvent('Ingreso-Propietario-Calculadora', `UserId = ${userData.id}  VIA = ${authChannel} Source=${nameSource}`);
    }
  } else {
    if (userData && !userData.email) {
      const signedUpEvent =
        userType == userTypes.landlord
          ? new LandlordSignedUp(phoneNumber, nameSource || undefined, nameOrigin || undefined)
          : new TenantSignedUp(phoneNumber, nameSource || undefined, nameOrigin || undefined);

      tracker.trackEvent(signedUpEvent);
    } else {
      gaEvent(
        userType === userTypes.landlord ? 'Ingreso-Propietario' : 'Ingreso-Inquilino',
        `UserId = ${userData.id}  VIA = ${authChannel} Source=${nameSource}`
      );
    }
  }
}

export { reportOtpTrackingData };
