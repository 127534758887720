import styled from 'styled-components';

const Container = styled.div`
  background-color: white;
  z-index: 1001;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  min-width: 100%;
  text-align: center;
  overflow: auto;
`;

const Item = styled.div`
  text-align: center;
`;

const Image = styled.img`
  position: absolute;
  z-index: 2;
  width: 382px;
  height: 332px;
  top: 106px;
  align-self: center;

  @media screen and (max-width: 800px), (max-height: 800px) {
    top: 31px;
    width: 282px;
    height: 245px;
  }

  @media screen and (min-width: 600px) {
    top: 147px;
  }

  @media screen and (min-width: 800px) {
    top: 147px;
    width: 382px;
    height: 332px;
    top: 106px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff1ed;
  padding: 16px;
  padding-top: 157px;
  height: 100%;

  @media screen and (min-width: 600px) {
    height: calc(100% - 263px);
  }
`;

const TitleText = styled.span`
  height: auto;
  font-family: Satoshi;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: -0.024em;
  color: #000000;
  max-width: 390px;
  margin-bottom: 16px;
  text-align: left;
  margin-top: 38px;

  @media screen and (min-width: 400px) {
    max-width: 288px;
  }

  @media screen and (max-height: 800px) {
    margin-top: 0;
  }
`;

const MessageText = styled.span`
  height: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6c757d;
  text-align: left;
  margin-bottom: 24px;

  @media screen and (min-width: 500px) {
    width: 390px;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 48px;
  background: ${({ background }) => (background ? background : '#ffffff 0% 0% no-repeat padding-box')};
  border-radius: 20px;
  border-width: 0px;
  border-style: none;
  color: ${({ color }) => (color ? color : '#ffffff')};
  line-height: normal;
  cursor: pointer;
  opacity: ${props => (props.disabled ? 0.25 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed !important' : 'pointer')};
  border: ${({ border }) => (border ? border : 'none')};
  margin-bottom: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 390px;
  margin: auto;
  margin-top: 0;
  gap: 16px;
`;

const HeaderBox = styled.div`
  height: 147px;
  background: white;

  @media screen and (min-width: 600px) {
    height: 263px;
  }
`;

export { Container, Item, Image, SubContainer, TitleText, MessageText, Button, Content, HeaderBox };
