import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 8px;
  max-width: 592px;
  margin: auto;
  transition: all 0.3s ease;
`;
