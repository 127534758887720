import gql from 'graphql-tag';

export const GET_USER_DATA = gql`
    query {
      getAuthenticatedUser {
        id
        firstName
        lastName
        email
        phoneNumber
        status
        role
        identityDocumentType
        identityDocumentCode
      }
  }
`

export const DO_PHONE_SIGNUP = gql`
    mutation signUpPhone($number: String!, $authChannel: AuthChannel!) {
      signUpPhone(number: $number, authChannel: $authChannel)
  }
`
export const DO_PHONE_CODE_SIGNUP = gql`
    mutation signUpPhoneCode($number: String!, $code: String!, $userType: UserType!, $anonymousSessionId: String!) {
      signUpPhoneCode(number: $number, code: $code, userType: $userType, anonymousSessionId: $anonymousSessionId) {
        token,
        userData {
          id,
          firstName,
          lastName,
          email,
          phoneNumber,
          status
        }
      }
  }
`

export const DO_CREATE_DEAL = gql`
    mutation createDealSupply($dealProperties: CreateDealType!) {
      createDealSupply(dealProperties: $dealProperties){
        crmDealId
      }
    }
`
