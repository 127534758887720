import React, { lazy, Suspense } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { useUtmInfo } from '../../hooks/useUtmInfo';

const EstateInfo = lazy(() => import('./EstateInfo/EstateInfo'));
const GeneralInfo = lazy(() => import('./GeneralInfo/GeneralInfo'));
const EstateInfoEdit = lazy(() => import('./EstateInfoEdit/EstateInfoEdit'));
const LeaseReportPage = lazy(() => import('./LeaseReportPage/LeaseReportPage'));
const EstatePhotosUpdate = lazy(() => import('./EstatePhotosUpdate/EstatePhotosUpdate'));
const PendingAppointments = lazy(() => import('../../components/Profile/PendingAppointments'));

const LandLordsModule = () => {
  const { path } = useRouteMatch();

  useUtmInfo();

  return (
    <>
      <Route exact path={`${path}/`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <GeneralInfo />
        </Suspense>
      </Route>
      <Route exact path={`${path}/inmueble/:estateId`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <EstateInfo />
        </Suspense>
      </Route>
      <Route path={`${path}/inmueble/:estateId/modificar`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <EstateInfoEdit />
        </Suspense>
      </Route>
      <Route path={`${path}/inmueble/:estateId/fotos`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <EstatePhotosUpdate />
        </Suspense>
      </Route>
      <Route path={`${path}/inmueble/:estateId/reporte`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <LeaseReportPage />
        </Suspense>
      </Route>
      <Route path={`${path}/inmueble/:estateId/visitas-pendientes`} render={(props) => 
        <Suspense fallback={<div> Cargando ... </div>}>
          <PendingAppointments {...props} />
        </Suspense>}
      />
    </>
  );
};

export default LandLordsModule;
