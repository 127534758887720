import React from 'react';
import { Helmet } from 'react-helmet-async';

const hostNameMain = window.location.hostname !== 'web.aptuno.com' && window.location.hostname !== 'web.aptuno.mx';

const Seo = ({
  title = 'Casas y Apartamentos en Arriendo - Aptuno',
  description,
  image,
  url = window.location.origin,
  indexed = hostNameMain,
}) => {
  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      {/* <!-- Google / Search Engine Tags --> */}
      <meta name="robots" content={indexed ? 'index' : 'none'} />
      <meta itemprop="name" content={title} />
      <meta itemprop="description" content={description} />
      <meta itemprop="image" content={image} />
      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="es_ES" />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="theme-color" content="#0055fb" />
    </Helmet>
  );
};

export default Seo;
