import { useMutation } from '@apollo/client/react/hooks';
import { AptunoButton, Icon, BoxInformative } from '@aptuno/aptuno-ui';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { responsiveContext } from '../../../App';
import { CustomInputTel, errorMessage, FlagSelector } from '../../../components/FormComponents';
import OTPValidator from '../../../components/OTPValidator';
import { authChannels, userTypes } from '../../../data/miscellaneous';
import { env } from '../../../environment/environment';
import { DO_PHONE_SIGNUP } from '../../../hooks/useAuthQuery';
import { getUrlForPage, PAGE_CATEGORY } from '../../../services/url';
import { ProfileForm } from '../ProfileForm/ProfileForm';
import {
  FlagSelectorCol,
  InputRow,
  LoginFooter,
  LoginWrapper,
  SmallText,
  ItemPhone,
} from './LoginBucket.styled';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { useAnalyticsTracker } from '../../../services/analytics';
import { OTPRequestedEvent } from '../../../Context/Auth/events/otp-requested.event';
import { MainTitle, SubTitle } from '../Login.styles';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoginBucketNew = ({ onValidated, type = userTypes.tenant }) => {
  const { responsive } = React.useContext(responsiveContext);
  const query = useQuery();
  const [country, setCountry] = useState(env.countryCode);
  const [authChannel, setAuthChannel] = useState(authChannels.whatsapp);
  const [phoneNumber, setNumber] = useState(0);
  const [doSavePhone, { data: dataSavePhone, error: errorSavePhone, loading: loadingSavePhone }] =
    useMutation(DO_PHONE_SIGNUP);
  const [retryNr, setRetry] = useState(0);
  const [loginStage, setLoginState] = useState(0);
  const descriptionTxt = `Recibirás un mensaje por ${authChannel}, con el código de 6 dígitos, para verificar tu número de teléfono`;

  const [typeUserLandlord, setTypeUserLandlord] = useState();
  const OriginLandingLandlord = document.referrer.includes('publicar-inmueble');

  const eventTracker = useAnalyticsTracker();

  useEffect(() => {
    if (OriginLandingLandlord) setTypeUserLandlord('LANDLORD');
  }, []);

  const requestOtpCode = (phone) => {
    doSavePhone({ variables: { number: phone ? phone : phoneNumber, authChannel: authChannel.toLocaleUpperCase() } });
    setLoginState(1);
  };

  const withParams = {
    redirect_phone: query.get('redirect_phone'),
    source: query.get('source') || 'organic',
    origin: query.get('origin') || 'product',
    referralLandlord: query.get('propietario'),
    referralAgent: query.get('agente'),
  };

  const registerFBevent = () => {
    if (window) {
      ReactPixel.track('CompleteRegistration', {
        value: 1,
        currency: 'COP',
        status: true,
      });
    }
  };

  const handlePublishClick = (phone) => {
    setNumber(phone);
    requestOtpCode(phone);
    registerFBevent();
  };

  useEffect(() => {
    if (withParams && withParams.redirect_phone) {
      setTypeUserLandlord('LANDLORD');
      const phoneNr = env.countryPhoneCode + withParams.redirect_phone;
      handlePublishClick(phoneNr);
    }
  }, []);

  useEffect(() => {
    if (retryNr >= 3) {
      setAuthChannel('SMS');
    }
  }, [retryNr]);

  const handleOtpRetry = (retry) => {
    setRetry(retryNr + 1);
    setLoginState(0);
  };

  const handleOtpValidated = (userData) => {
    const editProfileRequired = userData !== undefined && !userData.email;
    if (editProfileRequired) {
      setLoginState(4);
    } else {
      onValidated(true);
    }
  };

  const isTelInputDirty = () => {
    return phoneNumber && phoneNumber.length > 0;
  };

  const handleProfileUpdated = () => {
    onValidated(true);
  };
  const inputRef = useRef(null);

  const [isTouched, setIsTouched] = useState(false);

  const showErrorField = (ref) => {
    ref.current.focus();
    setIsTouched(true);
  };

  const handleLeadOrigin = () => {
    if (withParams?.referralLandlord) return 'BY_LANDLORD';
    if (withParams?.referralAgent) return 'BY_CAPTURING_AGENT';
    return 'AUTOPUBLISH';
  };

  const loginForm = (
    <>
      <MainTitle>{`${
        type === 'TENANT' || type === 'LANDLORD' ? 'Ingresa tu número móvil.' : 'Bienvenido agente'
      }`}</MainTitle>
      <SubTitle>
        Te enviaremos un código de seguridad para confirmar que te pertenece y así acceder a tu cuenta.
      </SubTitle>
      <ItemPhone>
        <FlagSelectorCol>
          <FlagSelector onChange={(c) => setCountry(c)} />
        </FlagSelectorCol>
        <div className="w-100 flex-column wrap align-left pl-2">
          <CustomInputTel
            className="ml-0"
            ref={inputRef}
            country={country}
            icon="icon-wa_icon"
            disabled={false}
            placeHolder={' Ingresa tu teléfono'}
            changeFn={setNumber}
          />
          <div className="w-100 align-left my-0 ml-3">
            {isTouched && !isTelInputDirty() && errorMessage('Teléfono es obligatorio')}
          </div>
        </div>
      </ItemPhone>
      <InputRow>
        <AptunoButton
          isDisabled={!phoneNumber}
          size={'big'}
          onClick={() => {
            if (isTelInputDirty()) {
              RequestOtpSegmentEvent();
              requestOtpCode();
            } else {
              showErrorField(inputRef);
            }
          }}
        >
          <Icon icon="whatsapp" />
          Recibir código por WhatsApp
        </AptunoButton>
      </InputRow>
      <InputRow>
        <SmallText className="otpText">{descriptionTxt}</SmallText>
      </InputRow>
    </>
  );

  const otpInput = (
    <Container>
      <Row>
        <Col xs="12" className="m-auto">
          <OTPValidator
            type={typeUserLandlord ? typeUserLandlord : type}
            authChannel={authChannel}
            country={country}
            phoneNumber={phoneNumber}
            onValidate={handleOtpValidated}
            onRetry={handleOtpRetry}
            nameSource={withParams && withParams.source ? withParams.source.toUpperCase() : null}
            nameOrigin={withParams && withParams.origin}
            leadOrigin={handleLeadOrigin()}
          />
        </Col>
      </Row>
    </Container>
  );

  const RequestOtpSegmentEvent = () => {
    eventTracker.trackEvent(new OTPRequestedEvent(withParams.source, country, 1, phoneNumber));
  };

  return (
    <LoginWrapper>
      {loginStage === 0 && loginForm}
      {loginStage === 1 && otpInput}
      {loginStage === 0 && (
        <LoginFooter>
          {type === userTypes.tenant && (
            <BoxInformative refIcon="info" appearance="default">
              <div>
                Al continuar aceptas nuestros <a href="/terminos-y-condiciones">términos y condiciones</a> y nuestra{' '}
                <a href="/politica-de-privacidad">política de privacidad</a>
              </div>
            </BoxInformative>
          )}
          {type === userTypes.landlord && (
            <p className="mb-2 lh-21 f-14">
              <b>
                Si desea arrendar, <a href={getUrlForPage(PAGE_CATEGORY.RESULT_PAGE)}>busque su inmueble aquí</a>
              </b>
            </p>
          )}
        </LoginFooter>
      )}
      {loginStage === 4 && (
        <ProfileForm
          onProfileUpdated={handleProfileUpdated}
          phoneNbr={phoneNumber}
          responsive={responsive}
          nameSource={withParams && withParams.source ? withParams.source.toUpperCase() : null}
        />
      )}
    </LoginWrapper>
  );
};

export default LoginBucketNew;
