import React from "react";

export const MagicDots = ({ type = 'default'}) => {

  const dots = [];
  /*
  React.useEffect(() => {
    const maxDif = 30
    document.getElementsByTagName("body")[0].addEventListener('mousemove', e => {
      const x = Math.floor(e.clientX / 100);
      const y = Math.floor(e.clientY / 100);
      dots.forEach(element => {
        if(element) {
          const rect = element.getBoundingClientRect();
          element.style.marginLeft = ((rect.x > e.clientX) ? 1 : -1) * (((x > maxDif) ? maxDif : x)) + "px";
          element.style.marginTop = ((rect.y > e.clientY) ? 1 : -1) * (((x > maxDif) ? maxDif : y)) + "px";
        }
      });
    });
  },[]);
  */
  
  return (
    <div className={`magic-dots ${type}`}>
      <div className="dot" ref={(ref => dots.push(ref))} />
      <div className="dot" ref={(ref => dots.push(ref))} />
      <div className="dot" ref={(ref => dots.push(ref))} />
      <div className="dot" ref={(ref => dots.push(ref))} />
    </div>
  );
}