import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    top:80px;
    bottom: 80px;
    background-color: #F8F8F8;
    padding-bottom: 80px;
  }

  .rfs-control-container {
    border-radius: 8px;
    height: 48px;
    border: none;
    background-color: #FFFFFF;
  }

  .eotZtt{
    font-family: "Satoshi";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #CDCDCD !important;
  }

  .rfs-menu-container { 
    background: white;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 0px 4px #d2d2d2;
  }
`;

export { GlobalStyle };
