import React, { FC, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { analyticsTracker, EventTrackerContext, useAnalyticsTracker } from '../services/analytics';

export const standardRawDataSegment = (path?: string, url?: string) => {
  const urlActual = window.location;
  return {
    page: {
      path: path ? path : urlActual.pathname,
      url: url ? url : urlActual.href,
    },
  };
};

function AnalyticsPageRoutesListener(props) {
  const { pathname } = useLocation();
  const eventTracker = useAnalyticsTracker();

  useEffect(() => {
    eventTracker.trackPage();
  }, [pathname]);

  return props.children;
}

export const AnalyticsPageCallEmitter = withRouter(AnalyticsPageRoutesListener);

const AnalyticsContextProvider: FC = ({ children }) => {
  return <EventTrackerContext.Provider value={analyticsTracker}>{children}</EventTrackerContext.Provider>;
};

export default AnalyticsContextProvider;
