import queryString from 'query-string';
import { env } from '../environment/environment';

import { PROPERTY_ATTRIBUTES, CHARACTERISTICS_TO_EXTRACT, extractCharacteristics } from '../helpers/property';

const PAGE_CATEGORY = Object.freeze({
  HOME: 'HOME',
  RESULT_PAGE: 'RESULT_PAGE',
  DETAIL_PAGE: 'DETAIL_PAGE',
});

/**
 * Commons
 */

function propertyTypeToUrlSlug(propertyType) {
  switch (propertyType) {
    case 'APARTMENT':
      return 'apartamento';
    case 'HOUSE':
      return 'casa';
    default:
      return 'inmueble';
  }
}

function urlSlugToPropertyType(propertyTypeSlug) {
  switch (propertyTypeSlug) {
    case 'apartamento':
    case 'apartamentos':
      return 'APARTMENT';
    case 'casa':
    case 'casas':
      return 'HOUSE';
    default:
      return null;
  }
}

function slugify(string = '') {
  const from = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const to = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const regex = new RegExp(from.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .trim()
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(regex, (char) => to.charAt(from.indexOf(char))); // Replace special characters
}

function getUrlParametersForRegex(regex, defaultGroupsValues) {
  const pathname = window.location.pathname;
  const regexMatches = pathname.match(regex) || defaultGroupsValues;
  const urlPathAttributes = regexMatches.groups;
  const queryStringParameters = queryString.parse(window.location.search);
  return Object.assign({}, queryStringParameters, urlPathAttributes);
}

/**
 * Result Page
 */

const RP_URL_LOCATION_PATH_REGEX =
  /^\/(?<propertyTypeSlug>(inmuebles|apartamentos|casas))-(arriendo|renta)((?=\/)\/(?<locationSlug>[a-z0-9-]+)|)\/?((?=\?)\?.*|)$/;
const DEFAULT_RP_URL_REGEX_GROUPS = { groups: { propertyTypeSlug: 'inmuebles', locationSlug: null } };

const getResultPageParametersFromUrl = () => {
  const urlParameters = getUrlParametersForRegex(RP_URL_LOCATION_PATH_REGEX, DEFAULT_RP_URL_REGEX_GROUPS);

  return Object.assign({}, urlParameters, {
    propertyType: urlSlugToPropertyType(urlParameters.propertyTypeSlug) || null,
    location: urlParameters.locationSlug || null,
  });
};

const FILTER_KEYS_TO_QUERY_PARAMS = {
  propertyType: 'type',
  priceMin: 'priceMin',
  priceMax: 'priceMax',
  roomsNr: 'rooms',
  bathsNr: 'baths',
  garageNr: 'garages',
  propertyAge: 'propertyAge',
  stratum: 'stratum',
  pets: 'pets',
  remodeled: 'remodeled',
  generalChars: 'gcharts',
  innerChars: 'icharts',
  outterChars: 'ocharts',
  area: 'area',
  sortKey: 'sort',
  sortType: 'sortType',
  furnished: 'furnished',
};

function urlForResultPage(rawFilter) {
  const { key: sortKey, topOrBot: sortType } = rawFilter.sort || {};

  const filter = Object.assign({}, rawFilter, { sortKey, sortType });
  const hasValidFilterValue = (param) => {
    const value = filter[param];
    const notNumberOrPositiveNumber = !Number.isInteger(+value) || +value > 0;
    const notArrayOrValidArray = !Array.isArray(value) || value.length > 0;

    return value && notNumberOrPositiveNumber && notArrayOrValidArray;
  };

  const propertyTypeSlug = `/${propertyTypeToUrlSlug(filter.propertyType)}s-${
    env.countryCode === 'MX' ? 'renta' : 'arriendo'
  }`;
  const locationSlug = filter.location ? `/${filter.location}` : '';
  const queryString = Object.keys(FILTER_KEYS_TO_QUERY_PARAMS)
    .filter((param) => hasValidFilterValue(param))
    .reduce((queryParameters, param) => {
      const key = FILTER_KEYS_TO_QUERY_PARAMS[param];
      const value = Array.isArray(filter[param]) ? filter[param].join(',') : filter[param];

      queryParameters.push(`${key}=${value}`);
      return queryParameters;
    }, [])
    .join('&');
  const hasQueryString = queryString ? '?' : '';

  return `${propertyTypeSlug}${locationSlug.toLowerCase()}${hasQueryString}${queryString}`;
}

/**
 * Detail Page
 */
const DP_URL_LOCATION_PATH_REGEX = /^\/(detalles|inmueble)\/.*?(?<propertyId>[0-9]+)\/?((?=\?)\?.*|)$/;
const DEFAULT_DP_URL_REGEX_GROUPS = { groups: { propertyId: null } };

function getDetailPageParametersFromUrl() {
  return getUrlParametersForRegex(DP_URL_LOCATION_PATH_REGEX, DEFAULT_DP_URL_REGEX_GROUPS);
}

// TODO - Once validated, move this logic to core in order to share url for detail page
function urlForDetailPage({
  propertyType = null,
  characteristics: rawCharacteristics,
  rooms: parsedRooms = null,
  area: parsedArea = null,
  location = { code: env.defaultUrlLocation },
  price = 0,
  propertyLegalId = 0,
}) {
  const characteristics = extractCharacteristics(rawCharacteristics || [], CHARACTERISTICS_TO_EXTRACT);
  const rooms = parsedRooms || characteristics[PROPERTY_ATTRIBUTES.BEDROOMS];
  const area = parsedArea || characteristics[PROPERTY_ATTRIBUTES.AREA];

  const tokens = [
    propertyTypeToUrlSlug(propertyType),
    rooms,
    getBedroomText(rooms),
    `sin-fiador`,
    location.code.toLowerCase(),
    `por-${price}-mensual`,
    `${area}m2`,
    `id-${propertyLegalId}`,
  ];
  return `/inmueble/${slugify(tokens.join('-'))}`;
}

function getBedroomText(bedrooms = 1) {
  return bedrooms === 1 ? 'habitacion' : 'habitaciones';
}

function getUrlForPage(page, args = {}) {
  switch (page) {
    case PAGE_CATEGORY.RESULT_PAGE:
      return urlForResultPage(args || {});
    case PAGE_CATEGORY.DETAIL_PAGE:
      return urlForDetailPage(args || {});
    default:
      return '/';
  }
}

function getParametersFromUrl(page) {
  switch (page) {
    case PAGE_CATEGORY.RESULT_PAGE:
      return getResultPageParametersFromUrl();
    case PAGE_CATEGORY.DETAIL_PAGE:
      return getDetailPageParametersFromUrl();
    default:
      return {};
  }
}

export { PAGE_CATEGORY, getUrlForPage, getParametersFromUrl };
