export class EstateId {
  public readonly _tag = 'EstateId';

  constructor(public readonly value: number) {}

  static unsafe = (value: number | null | undefined): EstateId | undefined => (value ? new EstateId(value) : undefined);

  static of = (value: number): EstateId => new EstateId(value);
}

export class EstateLegalId {
  public readonly _tag = 'EstateLegalId';

  constructor(public readonly value: string) {}

  static unsafe = (value: string | null | undefined): EstateLegalId | undefined =>
    value ? new EstateLegalId(value) : undefined;

  static of = (value: string): EstateLegalId => new EstateLegalId(value);
}
