import { TraceableProcess, TrackingEvent } from '../../../services/analytics/model';

export class TenantAppointmentRescheduledEvent implements TrackingEvent {
  public readonly action: string = 'Tenant Appt Rescheduled';
  public readonly category: TraceableProcess = TraceableProcess.APPOINTMENT_MANAGEMENT;
  public readonly label: string;
  constructor(public readonly property_legal_id: string) {
    this.label = `property_legal_id = ${property_legal_id}`;
  }
}
