import styled from 'styled-components';

const Container = styled.div`
    margin-top: 40px;
    display: flex;
    place-content: center;
    padding: 0 16px 16px;
`;

const Item = styled.div`
  max-width: 25rem;
`;

export { Container, Item };
