const PROPERTY_ATTRIBUTES = {
    BEDROOMS: 'nro-cuartos',
    AREA: 'area-construida'
}

const CHARACTERISTICS_TO_EXTRACT = {}
CHARACTERISTICS_TO_EXTRACT[PROPERTY_ATTRIBUTES['BEDROOMS']] = 1
CHARACTERISTICS_TO_EXTRACT[PROPERTY_ATTRIBUTES['AREA']] = 0


function extractCharacteristics(characteristics = [], keys = {}) {
    const keyNames = Object.keys(keys)
    return characteristics
        .filter(characteristic => keyNames.includes(characteristic.slug))
        .reduce((object, entry) => {
            object[entry.slug] = parseInt(entry.value)
            return object
        }, Object.assign({}, keys))
}

export {
    PROPERTY_ATTRIBUTES,
    CHARACTERISTICS_TO_EXTRACT,
    extractCharacteristics
}