import { FetchResult } from '@apollo/client/link/core';
import { UserData, ValidateOtpResponse } from './types';
import { ExecutionResult } from 'graphql/execution/execute';
import queryString from 'query-string';
import { ErrorsTypes } from '../../data/errors';
import { getGraphQLErrorCode } from '../../helpers/network-helpers';
import { GraphQLError } from 'graphql/error/GraphQLError';

interface FormatErrorMessageArgs {
  location: string;
  errorResponse: {
    errors: ReadonlyArray<GraphQLError>
  };
}

function formatErrorMessage({ location, errorResponse }: FormatErrorMessageArgs) {
  const queryStringParameters = queryString.parse(location);

  if (queryStringParameters) {
    const rol = queryStringParameters.rol;
    if (rol && rol === 'VISIT_COMPANION_AGENT') {
      return ErrorsTypes[getGraphQLErrorCode(errorResponse)].text;
    } else {
      return 'El codigo es incorrecto';
    }
  }

  return 'Error inesperado';
}

type Result = FetchResult<any> & ExecutionResult;

type ExecutionError = {
  errors: ReadonlyArray<GraphQLError>
}

type ExecutionSuccess = {
  data: Record<string, any>
}

interface FormatOtpValidationArgs<T = any> {
  result: Result,
  formatError: (error: ExecutionError) => string
  formatResult: (success: ExecutionSuccess) => {
    userData: UserData,
    token: string;
  },
  defaultErrorMessage: string
}

function formatOtpValidationResult<T = any>(args: FormatOtpValidationArgs<T>): ValidateOtpResponse {
  const {
    result,
    formatResult,
    formatError,
    defaultErrorMessage
  } = args;

  const dataIsNull = result.data === null;
  if (result.errors !== undefined && dataIsNull) {
    return {
      _tag: 'ValidateOtpFailure',
      errorMessage: formatError({ errors: result.errors })
    };
  }

  const noErrors = result.errors === undefined || result.errors.length === 0;
  if (result.data && noErrors) {
    return {
      _tag: 'ValidateOtpSuccess',
      ...formatResult({
        data: result.data
      })
    };
  }

  return {
    _tag: 'ValidateOtpFailure',
    errorMessage: defaultErrorMessage
  };

}

export {
  formatOtpValidationResult,
  formatErrorMessage
};
