import { v4 as uuid } from 'uuid';

export const getLocalStorageItem = (key = '') => {
  if(key && key !== '') {
    try {
      const data = localStorage.getItem(key);
      return data;
    } catch (e) {
      console.log(`[LocalStorage] Error getting item '${key}'`, e);
      return;
    }
  } else {
    console.log(`[LocalStorage] 'key' attribute is mandatory`);
  }
}

export const setLocalStorageItem = ( key, data = '' ) => {
  try {
    localStorage.setItem(key, data);
  } catch (e) {
    console.log('[LocalStorage] Error setting item', e);
  }
}

export const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log(`[LocalStorage] Error removing item '${key}'`, e)
  }
}

export const getOrCreateUUID = () => {
  const data = getLocalStorageItem('userid');
  
  if(data) {
    return data;
  } else {
    const userId = uuid();
    setLocalStorageItem('userid', userId);

    return userId
  }
}