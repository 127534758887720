import { gql } from "@apollo/client";

export const GET_IDENTITY_VALIDATION = gql`
  query GetIdentityValidation {
    getIdentityValidation {
      value {
        id
        documentCode
        documentType
        stage
      }
    }
  } 
`;
