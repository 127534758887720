import { TrackingEvent, TrackingIdentity, Tracker, TraceableProcess, TrackingPage } from '../model';
import TagManager from 'react-gtm-module';
import { GTMEventDto } from './gtm.dto';

export class GTMEventTrackerService implements Tracker {
  trackEvent = (event: TrackingEvent): Promise<void> =>
    new Promise((resolve, reject) => {
      try {
        console.log(`Tracking action [${event.action}]`);
        const data = GTMEventDto.fromTrackingEvent(event);

        TagManager.dataLayer(data);
        console.log(`Action tracked [${event.action}]`);

        resolve(void 0);
      } catch (error) {
        console.error(
          `Fail tracking event: action: ${event.action}, category: ${event.category} label: ${event.label}`,
          error
        );
        resolve(void 0);
      }
    });

  trackIdentity = (event: TrackingIdentity): Promise<void> =>
    this.trackEvent({
      category: TraceableProcess.USER_PROFILE,
      action: event.userId,
    });

  trackPage = (page?: TrackingPage | undefined): Promise<void> =>
    new Promise((resolve, reject) => {
      const data = {
        dataLayer: {
          event: 'PageView',
          page: page?.name,
          category: page?.category,
        },
      };

      try {
        resolve(TagManager.dataLayer(data));
      } catch (error) {
        console.error(`Fail tracking page: category: ${page?.category} name: ${page?.name}`, error);
        resolve(void 0);
      }
    });
}
