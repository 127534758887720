import styled from 'styled-components';

const Container = styled.div`
  ul {
    margin: 0px !important;
  }
`;

const NavbarSimpleContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(120, 120, 120, 0.06);
`;

const NavbarSimpleStyle = styled.div`
  max-width: 25rem;
  width: 100%;
  padding: 16px;
`;

const Image = styled.img``;

export { Container, NavbarSimpleContainer, NavbarSimpleStyle, Image };
