import React, { lazy, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Router, Switch, useLocation, withRouter } from 'react-router-dom';
import ErrorPage from './components/ErrorPage/ErrorPage';
import AgentsModule from './containers/AgentsModule/AgentsModule';
import Appointment from './containers/Appointment/Appointment';
// contentfull
import ContentFullProvider from './containers/ContentFullProvider';
import EstatePublish from './containers/EstatePublish/EstatePublish';
import LandLordsModule from './containers/LandLordsModule/LandLordsModule';
import Login from './containers/Login/Login';
import { AnalyticsPageCallEmitter } from './containers/SegmentAnalyticsProvider';
import { userTypes } from './data/miscellaneous';
import history from './history';
import { env } from './environment/environment';
import { WithSucceededAuth } from './Context/Auth/WithSucceededAuth';
import { EmailLoginScreen } from './containers/Login/email/EmailLoginScreen';

const Intro = lazy(() => import('./containers/Intro'));
const EstateRegisterForm = lazy(() => import('./components/EstateRegister/EstateRegisterForm'));
const NeighborhoodPage = lazy(() => import('./containers/NeighborhoodGuides/NeighborhoodPage'));
const NeighborhoodGuidesPage = lazy(() => import('./containers/NeighbordhoodGuidesPage/NeighborhoodGuidesPage'));
const BlogPage = lazy(() => import('./components/BlogPage'));
const BlogHome = lazy(() => import('./components/BlogHome'));
const BlogAllHome = lazy(() => import('./components/BlogAllHome'));
const Favorites = lazy(() => import('./containers/Favorites'));
const Hides = lazy(() => import('./containers/Hides'));
const Rentcalculator = lazy(() => import('./containers/RentCalculator/RentCalculator'));
const RentReport = lazy(() => import('./containers/RentReport/RentReport'));
const WhoWeAre = lazy(() => import('./containers/WhoWeAre'));
const TermsConditions = lazy(() => import('./containers/TermsConditions/TermsConditions'));
const Contact = lazy(() => import('./containers/Contact'));

const SearchResults = lazy(() => import('./containers/SearchResults/SearchResults'));
const Profile = lazy(() => import('./containers/Profile/Profile'));
const PropertyDetails = lazy(() => import('./containers/PropertyDetails'));
const PendingAppointments = lazy(() => import('./components/Profile/PendingAppointments'));

const AppointmentConfirmed = lazy(() => import('./components/Appointment/AppointmentConfirmed/AppointmentConfirmed'));
const KnowHow = lazy(() => import('./components/Appointment/AppointmentPromotion/KnowHowSimple/KnowHowSimple'));
const TermsConditionsPromotion = lazy(() =>
  import('./components/Appointment/AppointmentPromotion/TermsConditionsPromotion/TermsConditionsPromotion')
);
const BlackList = lazy(() => import('./containers/BlackList/BlackList'));

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

export const Routes = () => {
  const { t } = useTranslation();

  return (
    <Router history={history}>
      {/* <ScrollIntoView> */}
      <ScrollToTop>
        <AnalyticsPageCallEmitter>
          <Switch>
            <Route exact path="/">
              <Suspense fallback={<div> Cargando ... </div>}>
                <Intro />
              </Suspense>
            </Route>
            <Route exact path="/inmuebles-favoritos">
              <WithSucceededAuth
                view={
                  <Suspense fallback={<div> Cargando ... </div>}>
                    <Favorites />
                  </Suspense>
                }
              />
            </Route>
            <Route exact path="/inmuebles-ocultos">
              <WithSucceededAuth
                view={
                  <Suspense fallback={<div> Cargando ... </div>}>
                    <Hides />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path={`/(resultados|inmuebles-arriendo|inmuebles-renta|apartamentos-arriendo|casas-arriendo|apartamentos-renta|casas-renta)(.*)`}
              render={(props) => (
                <Suspense fallback={<div> Cargando ... </div>}>
                  <SearchResults {...props} />
                </Suspense>
              )}
            />
            <Route
              path="/(detalles|inmueble)/:estateId/agendar-visita"
              render={(props) => (
                <Suspense fallback={<div> Cargando ... </div>}>
                  <Appointment {...props} />
                </Suspense>
              )}
            />
            <Route
              path="/(detalles|inmueble)(.*)"
              render={(props) => (
                <Suspense fallback={<div> Cargando ... </div>}>
                  <PropertyDetails {...props} />
                </Suspense>
              )}
            />

            <Route path="/ingreso" render={(props) => <Login {...props} />} />
            <Route path="/email-login" render={(props) => <EmailLoginScreen {...props} />} />

            <Route path="/registro-inmueble">
              <WithSucceededAuth
                view={
                  <Suspense fallback={<div> Cargando ... </div>}>
                    <EstateRegisterForm />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path={`/${t('rentCalculatorRoute')}`}
              render={(props) => (
                <Suspense fallback={<div> Cargando ... </div>}>
                  <Rentcalculator {...props} />
                </Suspense>
              )}
            />
            <Route path="/ayuda">
              <Suspense fallback={<div> Cargando ... </div>}>
                <ContentFullProvider view={Contact} />
              </Suspense>
            </Route>
            <Route path="/blog/all">
              <Suspense fallback={<div> Cargando ... </div>}>
                <ContentFullProvider view={BlogAllHome} />
              </Suspense>
            </Route>
            <Route
              path="/blog/post/:id"
              render={(props) => (
                <Suspense fallback={<div> Cargando ... </div>}>
                  <ContentFullProvider {...props} view={BlogPage} />
                </Suspense>
              )}
            />
            <Route path="/blog">
              <Suspense fallback={<div> Cargando ... </div>}>
                <ContentFullProvider view={BlogHome} />
              </Suspense>
            </Route>
            <Route
              path="/guia-de-barrios/:id"
              render={(props) => (
                <Suspense fallback={<div> Cargando ... </div>}>
                  <ContentFullProvider {...props} view={NeighborhoodPage} />
                </Suspense>
              )}
            />
            <Route path="/guia-de-barrios">
              <Suspense fallback={<div> Cargando ... </div>}>
                <NeighborhoodGuidesPage />
              </Suspense>
            </Route>
            <Route exact path="/perfil">
              <WithSucceededAuth
                view={
                  <Suspense fallback={<div> Cargando ... </div>}>
                    <Profile />
                  </Suspense>
                }
              />
            </Route>
            <Route exact path="/perfil/(visitas-pendientes|visitas-realizadas)">
              <WithSucceededAuth
                view={
                  <Suspense fallback={<div> Cargando ... </div>}>
                    <PendingAppointments />
                  </Suspense>
                }
              />
            </Route>
            <Route path="/perfil/reporte-canon-arriendo">
              <WithSucceededAuth
                view={
                  <Suspense fallback={<div> Cargando ... </div>}>
                    <RentReport />
                  </Suspense>
                }
              />
            </Route>
            <Route path="/error">
              <Suspense fallback={<div> Cargando ... </div>}>
                <ErrorPage />
              </Suspense>
            </Route>
            <Route path="/opt-out">
              <Suspense fallback={<div> Cargando ... </div>}>
                <BlackList />
              </Suspense>
            </Route>
            <Route path="/quienes-somos">
              <Suspense fallback={<div> Cargando ... </div>}>
                <WhoWeAre />
              </Suspense>
            </Route>
            <Route path="/cita-confirmada">
              <Suspense fallback={<div> Cargando ... </div>}>
                <AppointmentConfirmed />
              </Suspense>
            </Route>
            <Route path="/como-encontrar-hogar-ideal">
              <Suspense fallback={<div> Cargando ... </div>}>
                <KnowHow />
              </Suspense>
            </Route>
            <Route path="/terminos-y-condiciones-promocion-agendamiento">
              <Suspense fallback={<div> Cargando ... </div>}>
                <TermsConditionsPromotion />
              </Suspense>
            </Route>
            <Route path="/terminos-y-condiciones">
              <Suspense fallback={<div> Cargando ... </div>}>
                <ContentFullProvider withoutDots={true} view={TermsConditions} />
              </Suspense>
            </Route>
            <Route path="/politica-de-privacidad">
              <Suspense fallback={<div> Cargando ... </div>}>
                <ContentFullProvider withoutDots={true} isPrivacyPolicy={true} view={TermsConditions} />
              </Suspense>
            </Route>
            <Route path="/publicar-inmueble">
              <WithSucceededAuth
                urlRedirect={!env.dev ? `/${t('autopublishRoute')}` : null}
                view={
                  <Suspense fallback={<div> Cargando ... </div>}>
                    <EstatePublish />
                  </Suspense>
                }
              />
            </Route>
            <Route exact path={`/${t('autopublishRouteOLDforRedirect')}`}>
              <Redirect to={`/${t('autopublishRoute')}`} />
            </Route>
            <Route path="/propietarios">
              <WithSucceededAuth
                view={
                  <Suspense fallback={<div> Cargando ... </div>}>
                    <LandLordsModule />
                  </Suspense>
                }
              />
            </Route>
            <Route path="/agentes">
              <WithSucceededAuth
                userRole={userTypes.visitCompanionAgent}
                view={
                  <Suspense fallback={<div> Cargando ... </div>}>
                    <AgentsModule />
                  </Suspense>
                }
              />
            </Route>
            <Route path="/captador">
              <WithSucceededAuth
                userRole={userTypes.capturingAgent}
                view={
                  <Suspense fallback={<div> Cargando ... </div>}>
                    <Profile />
                  </Suspense>
                }
              />
            </Route>
            <Route exact path="/ingreso-propietario">
              <Redirect to={`/${t('autopublishRoute')}`} />
            </Route>
            <Route exact path="/como-calcular-el-canon-de-arrendamiento">
              <Redirect to={`/${t('rentCalculatorRoute')}`} />
            </Route>
            <Redirect to="/error" />
          </Switch>
        </AnalyticsPageCallEmitter>
      </ScrollToTop>
    </Router>
  );
};
