import { TraceableProcess, TrackingEvent } from '../../../services/analytics/model';
import { EstateLegalId } from '../../../share/estate/estate';
import { UserId } from '../../../share/user/user';

export class AppointmentScheduledEvent implements TrackingEvent {
  public readonly action: string = 'cita-agendada';
  public readonly category: TraceableProcess = TraceableProcess.APPOINTMENT_MANAGEMENT;
  public readonly label: string;
  constructor(public readonly tenantId: UserId, public readonly estateLegalId: EstateLegalId) {
    this.label = `UserId = ${tenantId.value}, PropertyId = ${estateLegalId.value}`;
  }
}
