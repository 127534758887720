import React, { useState, useEffect } from 'react';
import {
  AppointmentUserFormWrapper,
  AppointmentForm,
  Label,
  Text,
  GroupedFields,
  ActionsWrapper,
  TermsAndConditionsSection,
} from './Appointment.styled';
import { Input, CheckBox, AptunoButton, Icon } from '@aptuno/aptuno-ui';
import { Controller, useForm } from 'react-hook-form';
import { CustomInputTel, FlagSelector, errorMessage, errorMessages, CustomInputSelect } from '../FormComponents';
import { v4 as uuid } from 'uuid';
import { documentTypes, estimatedMoveDates, userTypes } from '../../data/miscellaneous';
import { env } from '../../environment/environment';
import { useTranslation } from 'react-i18next';
import { useAnalyticsTracker } from '../../services/analytics/useAnalyticsTracker';
import { TermsAndConditionsInAppointment } from '../../containers/Appointment/events/terms-and-conditions-in-appointment.event';

const registerOptions = {
  firstnameTenant: { required: true },
  lastnameTenant: { required: true },
  emailTenant: { required: true, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ },
  dniTypeTenant: { required: true, pattern: /^[0-9]+/ },
  dniNumberTenant: { required: true, pattern: /^[0-9]+/ },
  phoneNumberTenant: { required: true },
  firstnameAssistant: { required: true },
  lastnameAssistant: { required: true },
  emailAssistant: { required: true, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ },
  dniTypeAssistant: { required: true },
  dniNumberAssistant: { required: true, pattern: /^[0-9]+/ },
  phoneNumberAssistant: { required: true },
  termsAndConditions: { required: true },
};

const toFrontModel = (tenantData, assistantData) => {
  const assistant = assistantData
    ? {
        firstnameAssistant: assistantData?.firstName,
        lastnameAssistant: assistantData?.lastName,
        emailAssistant: assistantData?.email,
        dniTypeAssistant: assistantData?.identityDocumentType,
        dniNumberAssistant: assistantData?.identityDocumentCode,
        phoneNumberAssistant: assistantData?.phoneNumber,
      }
    : {};

  return {
    firstnameTenant: tenantData?.firstName,
    lastnameTenant: tenantData?.lastName,
    emailTenant: tenantData?.email,
    dniTypeTenant: tenantData?.identityDocumentType,
    dniNumberTenant: tenantData?.identityDocumentCode,
    phoneNumberTenant: tenantData?.phoneNumber,
    ...assistant,
  };
};

const AppointmentUserDataForm = ({
  userData,
  tenantAssistantData,
  tenantIsAssistant,
  isLoading,
  onPrevius,
  onSubmit,
  editDisabled,
}) => {
  const [country, setCountry] = useState(env.countryCode);
  const { control, register, getValues, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    defaultValues: toFrontModel(userData, tenantAssistantData),
    reValidateMode: 'onChange',
  });
  const [withVisitAssistant, setWithVisitAssintant] = useState(false);
  const { t } = useTranslation('documentsLegal');
  const eventTracker = useAnalyticsTracker();

  const onSubmitForm = (data) => {
    eventTracker.trackEvent(new TermsAndConditionsInAppointment(data.phoneNumberTenant));
    onSubmit(data);
  };

  useEffect(() => {
    if (tenantAssistantData) setWithVisitAssintant(true);
  }, [tenantAssistantData]);

  const getDefaultDocumentType = (type) => {
    const z = documentTypes.findIndex(
      type === userTypes.tenant
        ? (el) => el.name === userData?.identityDocumentType
        : (el) => el.name === tenantAssistantData?.identityDocumentType
    );
    return z;
  };

  const getDefaultMoveDate = (moveDate) => {
    return estimatedMoveDates.findIndex((item) => item.value === moveDate);
  };

  const handleSameUserCheckbox = (value) => {
    setWithVisitAssintant(value);
  };

  useEffect(() => {
    tenantIsAssistant(!withVisitAssistant);
  }, [withVisitAssistant, tenantIsAssistant]);

  const userForm = () => (
    <>
      <Input
        disabled={editDisabled}
        name={`firstnameTenant`}
        type="text"
        label="Tu nombre completo"
        ref={register(registerOptions.firstnameTenant)}
      />
      {errors[`firstnameTenant`] &&
        errors[`firstnameTenant`].type === 'required' &&
        errorMessage(`Nombre ${errorMessages.required}`)}
      <Input
        disabled={editDisabled}
        name={`lastnameTenant`}
        type="text"
        label="Tus apellidos"
        ref={register(registerOptions.lastnameTenant)}
      />

      {errors[`lastnameTenant`] &&
        errors[`lastnameTenant`].type === 'required' &&
        errorMessage(`Apellidos ${errorMessages.requiredPlural}`)}
      <Input
        disabled={editDisabled}
        name={`emailTenant`}
        type="email"
        label="Tu correo electrónico"
        ref={register(registerOptions.emailTenant)}
      />
      {errors[`emailTenant`] &&
        errors[`emailTenant`].type === 'required' &&
        errorMessage(`Correo electrónico ${errorMessages.required}`)}
      {errors[`emailTenant`] &&
        errors[`emailTenant`].type === 'pattern' &&
        errorMessage(`Email ${errorMessages.pattern}`)}

      <GroupedFields>
        <Controller
          disabled={editDisabled}
          name={`dniTypeTenant`}
          control={control}
          defaultValue=""
          render={({ onChange, ref }) => (
            <CustomInputSelect
              ref={ref}
              id={`dniTypeTenant`}
              keyVal={uuid()}
              name={`dniTypeTenant`}
              className="smallSelect ml-0 font-16 lh-22 size-48 mr-2"
              labelClassName="left-6"
              defaultIndexVal={
                getDefaultDocumentType(userTypes.tenant) > 0 ? getDefaultDocumentType(userTypes.tenant) : 0
              }
              items={documentTypes}
              disabled={editDisabled}
              label="Tipo"
              clickFn={(optionSelected) => {
                if (optionSelected.value) {
                  onChange(optionSelected.value);
                }
              }}
              subItemDraw={(item) => (
                <div className="d-flex justify-content-between p-1 itemClassic">
                  <p className="mb-0 font-16 lh-18 c-primary">{item.name}</p>
                </div>
              )}
            />
          )}
        />
        <Input
          id={`dniNumberTenant`}
          name={`dniNumberTenant`}
          type="string"
          label={t('label')}
          disabled={editDisabled}
          ref={register(registerOptions.dniNumberTenant)}
        />
      </GroupedFields>
      {errors[`dniNumberTenant`] &&
        errors[`dniNumberTenant`].type === 'required' &&
        errorMessage(`Número de documento ${errorMessages.required}`)}
      {errors[`dniNumberTenant`] &&
        errors[`dniNumberTenant`].type === 'pattern' &&
        errorMessage(`Número de documento ${errorMessages.patternDni}`)}

      <GroupedFields>
        <FlagSelector className="smallSelect" disabled={!!userData || editDisabled} onChange={(c) => setCountry(c)} />
        <Controller
          name={`phoneNumberTenant`}
          control={control}
          defaultValue={''}
          render={({ onChange, defaultValue }) => (
            <CustomInputTel
              disabled={!!userData || editDisabled}
              name={`phoneNumberTenant`}
              className="ml-2 w-100"
              country={country}
              icon="icon-wa_icon"
              placeHolder={' Ingresa tu teléfono'}
              label={'Teléfono'}
              defaultVal={userData?.phoneNumber || ''}
              changeFn={onChange}
            />
          )}
          rules={registerOptions.phoneNumberTenant}
        />
      </GroupedFields>
      {errors[`phoneNumberTenant`] &&
        errors[`phoneNumberTenant`].type === 'required' &&
        errorMessage(`Nro de Teléfono ${errorMessages.required}`)}
      <GroupedFields>
        <Controller
          name="estimatedMoveDate"
          control={control}
          defaultValue=""
          render={({ onChange, ref }) => (
            <CustomInputSelect
              ref={ref}
              id="estimatedMoveDate"
              keyVal={uuid()}
              name="estimatedMoveDate"
              className="ml-0 font-16 size-48"
              labelClassName="left-6"
              defaultIndexVal={getDefaultMoveDate(userData?.estimatedMoveDate)}
              items={estimatedMoveDates}
              disabled={false}
              label="¿En cuánto tiempo planeas mudarte?"
              clickFn={(optionSelected) => {
                if (optionSelected.value) {
                  onChange(optionSelected.value);
                }
              }}
              subItemDraw={(item) => (
                <div className="d-flex justify-content-between p-1 itemClassic">
                  <p className="mb-0 font-16 lh-18 c-primary">{item.name}</p>
                </div>
              )}
            />
          )}
        />
      </GroupedFields>
    </>
  );

  const userAssistantForm = () => (
    <>
      <Input
        id={`firstnameAssistant`}
        name={`firstnameAssistant`}
        type="text"
        label="Nombre completo del visitante"
        ref={register(registerOptions.firstnameAssistant)}
      />
      {errors[`firstnameAssistant`] &&
        errors[`firstnameAssistant`].type === 'required' &&
        errorMessage(`Nombre ${errorMessages.required}`)}
      <Input
        id={`lastnameAssistant`}
        name={`lastnameAssistant`}
        type="text"
        label="Apellidos del visitante"
        ref={register(registerOptions.lastnameAssistant)}
      />
      {errors[`lastnameAssistant`] &&
        errors[`lastnameAssistant`].type === 'required' &&
        errorMessage(`Apellidos ${errorMessages.requiredPlural}`)}

      <Input
        id={`emailAssistant`}
        name={`emailAssistant`}
        type="email"
        label="Correo electrónico del visitante"
        ref={register(registerOptions.emailAssistant)}
      />
      {errors[`emailAssistant`] &&
        errors[`emailAssistant`].type === 'required' &&
        errorMessage(`Correo electrónico ${errorMessages.required}`)}
      {errors[`emailAssistant`] &&
        errors[`emailAssistant`].type === 'pattern' &&
        errorMessage(`Email ${errorMessages.pattern}`)}

      <GroupedFields>
        <Controller
          name={`dniTypeAssistant`}
          control={control}
          defaultValue=""
          render={({ onChange, ref }) => (
            <CustomInputSelect
              keyVal={uuid()}
              id={`dniTypeAssistant`}
              name={`dniTypeAssistant`}
              className="smallSelect ml-0 font-16 lh-22 size-48 mr-2"
              labelClassName="left-6"
              defaultIndexVal={getDefaultDocumentType('ASSISTANT') > 0 ? getDefaultDocumentType('ASSISTANT') : 0}
              items={documentTypes}
              disabled={false}
              label="Tipo"
              clickFn={(optionSelected) => {
                if (optionSelected.value) {
                  onChange(optionSelected.value);
                }
              }}
              subItemDraw={(item) => (
                <div className="d-flex justify-content-between p-1 itemClassic">
                  <p className="mb-0 font-16 lh-18 c-primary">{item.name}</p>
                </div>
              )}
            />
          )}
        />
        <Input
          id={`dniNumberAssistant`}
          name={`dniNumberAssistant`}
          type="string"
          label="Número de documento del visitante"
          ref={register(registerOptions.dniNumberAssistant)}
        />
      </GroupedFields>
      {errors[`dniNumberAssistant`] &&
        errors[`dniNumberAssistant`].type === 'required' &&
        errorMessage(`Número de documento ${errorMessages.required}`)}
      {errors[`dniNumberAssistant`] &&
        errors[`dniNumberAssistant`].type === 'pattern' &&
        errorMessage(`Número de documento ${errorMessages.patternDni}`)}

      <GroupedFields>
        <FlagSelector className="smallSelect" disabled={true} onChange={(c) => setCountry(c)} />
        <Controller
          name="phoneNumberAssistant"
          control={control}
          defaultValue={''}
          render={({ onChange, defaultValue }) => (
            <CustomInputTel
              name={`phoneNumberAssistant`}
              className="ml-2 w-100"
              country={country}
              icon="icon-wa_icon"
              disabled={false}
              placeHolder={' Ingresa tu teléfono'}
              label={'Teléfono'}
              defaultVal={tenantAssistantData?.phoneNumber || ''}
              changeFn={onChange}
            />
          )}
          rules={{
            ...registerOptions.phoneNumberAssistant,
            validate: (value) => {
              return getValues('phoneNumberTenant') !== value;
            },
          }}
        />
      </GroupedFields>
      {errors[`phoneNumberAssistant`] &&
        errors[`phoneNumberAssistant`].type === 'required' &&
        errorMessage(`Nro de Teléfono ${errorMessages.required}`)}
      {errors[`phoneNumberAssistant`] &&
        errors[`phoneNumberAssistant`].type === 'validate' &&
        errorMessage(`Nro de Teléfono del visitante ${errorMessages.validate}`)}
    </>
  );

  return (
    <>
      <AppointmentUserFormWrapper>
        <AppointmentForm id={'appointmentForm'} onSubmit={handleSubmit(onSubmitForm)}>
          <Label>
            No olvides completar tu nombre, correo electrónico y número de documento.
            <br />
            Estos datos son necesarios para autorizar tu ingreso al inmueble y programar tu visita
          </Label>
          {userForm()}
          <div className="my-2" />
          <CheckBox
            checked={withVisitAssistant}
            onChange={(event) => handleSameUserCheckbox(event.target.checked)}
            className="switch"
            label={'Alguien más visitará el inmueble por mi'}
            id={'isSameUser'}
            name={'isSameUser'}
          />
          {withVisitAssistant && (
            <>
              <Text id={'assistantDataTittle'}>Datos del visitante</Text>
              <Label>
                No olvides completar el nombre, correo electrónico y número de documento del visitante. Estos datos son
                necesarios para autorizar su ingreso al inmueble y programar la visita
              </Label>
            </>
          )}
          {withVisitAssistant && userAssistantForm()}

          {errors?.termsAndConditions?.type === 'required' &&
            errorMessage(`Debes aceptar los términos y condiciones. ↓`)}

          {!userData && (
            <TermsAndConditionsSection>
              <CheckBox
                name="termsAndConditions"
                key="termsAndConditions"
                id="termsAndConditions"
                ref={register(registerOptions.termsAndConditions)}
              />
              <p>
                Acepto los <a href="/terminos-y-condiciones">términos de uso</a> y la política de{' '}
                <a href="/politica-de-privacidad">privacidad de datos</a> de Aptuno. Además, autorizo a Aptuno y sus
                terceros habilitados a recolectar y utilizar mis datos personales según la ley y su política de
                privacidad de datos para los fines dispuestos.
              </p>
            </TermsAndConditionsSection>
          )}
        </AppointmentForm>
        <ActionsWrapper>
          <AptunoButton appearance={'secondary'} onClick={() => onPrevius()}>
            <Icon icon={'arrowleftalt'} />
            Atrás
          </AptunoButton>
          <AptunoButton disabled={isLoading} type="submit" loadingText="Agendando..." form="appointmentForm">
            Agendar
          </AptunoButton>
        </ActionsWrapper>
      </AppointmentUserFormWrapper>
    </>
  );
};

export default AppointmentUserDataForm;
