import { gql } from '@apollo/client';

export const schema = gql`
  mutation RescheduleAppointment(
    $newVisitAssistant: AppointmentUserInputType
    $newVisitDate: Float!
    $appointmentType: AppointmentType!
    $appointmentId: Int!
  ) {
    rescheduleAppointment(
      newVisitAssistant: $newVisitAssistant
      newVisitDate: $newVisitDate
      appointmentType: $appointmentType
      appointmentId: $appointmentId
    ) {
      id
      estate {
        id
      }
      visitDate
    }
  }
`;