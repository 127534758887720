import { StandardRawUrlData } from '../../../hooks/standardRowUrlData';
import { TraceableProcess, TrackingEvent } from '../../../services/analytics/model';

export class OnlyResidentialEstatesRedirect implements TrackingEvent {
  public readonly action = 'Landlord Redirected';
  public readonly category = TraceableProcess.AUTOPUBLISH;
  public readonly label = 'En Aptuno solo publicamos inmuebles residenciales';

  constructor(public readonly from: StandardRawUrlData, public readonly to: StandardRawUrlData) {}
}
