import { getLocalStorageItem, setLocalStorageItem } from "../helpers/localStorage";

const appointmentData = JSON.parse(getLocalStorageItem('appointmentData'));

export const initialState = {
  isModalOpen: false,
  ...appointmentData
};

export const appointmentActions = {
  SHOW_APPOINTMENT_MODAL: 'SHOW_APPOINTMENT_MODAL',
  HIDE_APPOINTMENT_MODAL: 'HIDE_APPOINTMENT_MODAL',
};

export const appointmentReducer = (state, action) => {
  switch (action.type) {
    case appointmentActions.SHOW_APPOINTMENT_MODAL:
      setLocalStorageItem('appointmentData', JSON.stringify({...state, ...action.payload}));
      return {
        ...state,
        isModalOpen: true,
        estateId: action.payload.estateId,
        estateLegalId: action.payload.estateLegalId,
        estateAddress: action.payload.address,
        appointmentId: action.payload.appointmentId,
        visitAssistant: action.payload.visitAssistant,
        estateCurrency: action.payload.estateCurrency,
        estateType: action.payload.estateType,
        neighborhood: action.payload.neighborhood,
        amountOpenAndEffectiveAppointments: action.payload.amountOpenAndEffectiveAppointments,
        amountEffectiveAppointments: action.payload.amountEffectiveAppointments,
      };
    case appointmentActions.HIDE_APPOINTMENT_MODAL:
      return {
        ...state,
        isModalOpen: false,
        estateId: null,
        estateLegalId: null,
        estateAddress: action.payload?.address,
        appointmentId: null,
        visitAssistant: null,
        estateCurrency: null,
        estateType: null,
        neighborhood: null,
        amountOpenAndEffectiveAppointments: action.payload?.amountOpenAndEffectiveAppointments,
        amountEffectiveAppointments: action.payload?.amountEffectiveAppointments,
        addressLastAppointment: action.payload?.addressLastAppointment,
        dateLastAppointment: action.payload?.dateLastAppointment,
        appointmentType: action.payload?.appointmentType,
      };
    default:
      return state;
  }
};
