import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PhoneInput from 'react-phone-number-input/input';
import { useDebouncedCallback } from 'use-debounce';
import { env } from '../environment/environment';
import { useOutsideClick } from '../hooks/useOutsideClick';
import { SearchSelectContainer } from './FormComponents.styled';
import { Icon } from '@aptuno/aptuno-ui';
import { responsiveContext } from '../App';

export class SearchableLocation {
  constructor(name, value, isCustomSearchableLocation, locationType, parents) {
    this.name = name;
    this.locationType = locationType;
    this.value = value;
    this.desc = 'xxx inmuebles';
    this.isCustomSearchableLocation = isCustomSearchableLocation;
    this.parents = parents;
  }
}

export const CustomListSelect = (props) => {
  const { childClass, items, active } = props;
  let { changeItemSelectedMethod } = props;
  changeItemSelectedMethod = changeItemSelectedMethod ? changeItemSelectedMethod : () => {};

  const [selectedItem, setSelected] = useState(null);

  const handleSelected = (index) => {
    const selected = selectedItem === index ? null : index;
    setSelected(selected);
    changeItemSelectedMethod(selected);
  };

  useEffect(() => {
    setSelected(active);
  }, [active]);

  return (
    <Form.Group className={`customListSelect d-flex`}>
      {items.map((i, index) => (
        <CustomButton
          className={`${childClass || ''}`}
          key={`CustomButton-${index}`}
          onClick={() => handleSelected(index)}
          text={i}
          active={selectedItem === index}
        />
      ))}
    </Form.Group>
  );
};

export const CustomListMultiSelect = (props) => {
  const { items, className, childClass } = props;
  let { changeItemSelectedMethod, activeArr } = props;
  changeItemSelectedMethod = changeItemSelectedMethod ? changeItemSelectedMethod : () => {};
  activeArr = activeArr ? activeArr : [];

  const appendOrRemove = (i) => {
    const arr = [...activeArr];
    const isInArray = arr.indexOf(i);
    if (isInArray >= 0) arr.splice(isInArray, 1);
    else arr.push(i);
    changeItemSelectedMethod(arr);
  };

  return (
    <Form.Group className={`customListSelect d-flex ${className || ''}`}>
      {items.map((i, index) => (
        <CustomButton
          key={`CustomListMultiSelect-${i}`}
          className={childClass}
          onClick={() => appendOrRemove(index)}
          text={i}
          active={activeArr.indexOf(index) >= 0}
        />
      ))}
    </Form.Group>
  );
};

export const CustomInputIcon = (props) => {
  const { className, icon } = props;

  return (
    <Form.Group className={`customInput ${className ? className : ''}`}>
      <InputGroup className="customInputIcon">
        <InputGroup.Prepend>
          <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control type="text" placeholder="Username" aria-describedby="inputGroupPrepend" required />
        <Form.Control.Feedback type="invalid">Please choose a username.</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};

export const CustomInputSelect = forwardRef((props, ref) => {
  const {
    keyVal,
    items = [],
    name,
    className,
    icon,
    subItemDraw,
    selectedDraw,
    clickFn,
    defaultIndexVal,
    disabled,
    label,
    labelClassName,
    useCase = 'inputSelectOptions',
  } = props;
  const [activeItem, setActiveItem] = useState(null);
  const labelState = activeItem ? 'label label-top' : 'label';

  useEffect(() => {
    if (activeItem && clickFn) clickFn(activeItem);
  }, [activeItem]);

  useEffect(() => {
    if (defaultIndexVal >= 0 && defaultIndexVal < items.length) {
      setActiveItem(items[defaultIndexVal]);
    }
  }, [defaultIndexVal]);

  const wrapperRef = useRef(null);
  const { isListOpen, setIsListOpen } = useOutsideClick(wrapperRef);

  const handleClick = () => {
    setIsListOpen(!isListOpen);
  };

  return (
    <>
      {label && (
        <CustomInputLabel
          onClickFn={() => {}}
          ctrlName={name}
          className={`${labelClassName ? labelClassName : ''} ${labelState}`}
          value={label}
        />
      )}
      <div
        ref={wrapperRef}
        key={keyVal}
        className={`customInputSelect ${disabled ? 'disabled' : ''} ${isListOpen ? 'active' : ''}`}
      >
        <div className="content" onClick={handleClick}>
          <div className={`input-group ${label ? 'inputWithLabel' : ''}`}>
            {icon && <span className={`form-icon ${icon}`} />}
            <div
              name={name}
              className={`inputValue ${
                !activeItem &&
                defaultIndexVal >= 0 &&
                items[Number(defaultIndexVal)] &&
                items[Number(defaultIndexVal)].noList
                  ? 'defaultValue'
                  : ''
              }  ${icon ? 'form-width-icon' : ''} ${label && !activeItem ? 'defaultValueHide' : ''}`}
            >
              {activeItem
                ? selectedDraw
                  ? selectedDraw(activeItem)
                  : activeItem.name
                : defaultIndexVal >= 0 && items.length > 0
                ? selectedDraw
                  ? selectedDraw(items[Number(defaultIndexVal)])
                  : items[Number(defaultIndexVal)].name
                : ''}
            </div>
            {!disabled && <span className="form-select-icon icon-triangle_icon" />}
          </div>
        </div>
        {isListOpen && (
          <div className={useCase}>
            <PerfectScrollbar className="scrollContainer ps--active-y ps--scrolling-y">
              <div className="scrollContainer ps--active-y ps--scrolling-y">
                {items
                  .filter((i) => !(i.noList === true))
                  .map((i, index) => {
                    return (
                      <div
                        key={`CustomInputSelect-${index}`}
                        onClick={() => {
                          setActiveItem(i);
                          handleClick();
                        }}
                        className={`w-100 customInputSelectItem ${
                          i === activeItem || (!activeItem && i === items[Number(defaultIndexVal)]) ? 'active' : ''
                        }`}
                      >
                        {subItemDraw ? subItemDraw(i) : <div className="itemClassic">{i.name}</div>}
                      </div>
                    );
                  })}
              </div>
            </PerfectScrollbar>
          </div>
        )}
      </div>
    </>
  );
});

export const CustomButton = (props) => {
  const { form, disabled, className, childClassName, buttonClassName, icon, text, active, onClick, id, type } = props;
  return (
    <Form.Group className={`customButton m-0 text-center ${className ? className : ''}`}>
      <Button
        id={id}
        form={form}
        type={type}
        disabled={disabled}
        onClick={onClick}
        className={`w-100 clasic ${buttonClassName ? buttonClassName : ''} ${active ? 'active' : ''} `}
      >
        <p
          className={` ${childClassName ? childClassName : ''} ${icon ? 'form-width-icon' : ''} ${
            icon && !text ? 'only-icon' : ''
          } mb-0`}
        >
          {icon && <span className={`form-icon ${icon}`} />}
          {text}
        </p>
      </Button>
    </Form.Group>
  );
};

//TODO: Remove when typeahead feat becomes tested and stabled
export const CustomInputSearchSelect = forwardRef((props, ref) => {
  const { className, icon, subItemDraw, filterFn, clickFn, defaultIndexVal, disabled } = props;
  const { t } = useTranslation('glossary');
  let { items } = props;
  items = items ? items : [];

  const [__items, __setItems] = useState(items);

  const search = (e) => {
    __setItems(
      items.filter((i) => {
        return filterFn ? filterFn(i, e.target.value) : i.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0;
      })
    );
  };

  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    if (activeItem && clickFn) clickFn(activeItem);
  }, [activeItem]);

  useEffect(() => {
    if (items) __setItems(items);
  }, [items]);

  const wrapperRef = useRef(null);
  const { isListOpen, setIsListOpen } = useOutsideClick(wrapperRef);
  const handleClick = () => {
    setIsListOpen(!isListOpen);
  };

  return (
    <React.Fragment>
      <div
        ref={wrapperRef}
        className={`customInputSearchSelect ${disabled ? 'disabled' : ''} ${className ? className : ''} ${
          isListOpen ? 'active' : ''
        }`}
      >
        <div className="content" onClick={handleClick}>
          <div className="input-group">
            {icon && <span className={`form-icon ${icon}`} />}
            <input
              ref={ref}
              disabled={disabled}
              className={`${!isListOpen ? 'hidden' : ''} ${icon ? 'form-width-icon' : ''}`}
              type="text"
              placeholder={`Ciudad, Zona o ${t('neighborhood')}`}
              aria-describedby="inputGroupPrepend"
              required
              onChange={search}
            />
            <div
              className={`inputValue ${isListOpen ? 'hidden' : ''} ${icon ? 'form-width-icon' : ''} `}
              onClick={() => {
                clickFn(activeItem);
              }}
            >
              {activeItem
                ? activeItem.name
                : Number(defaultIndexVal) >= 0 && __items[Number(defaultIndexVal)]
                ? __items[Number(defaultIndexVal)].name
                : ''}
            </div>
          </div>
        </div>
        {isListOpen && (
          <div className="inputSelectOptions">
            <PerfectScrollbar className="scrollContainer">
              {__items.map((i, index) => {
                return (
                  <div
                    key={`customInputSearchSelect-${index}`}
                    onClick={() => {
                      setActiveItem(i);
                      handleClick();
                    }}
                    className={`w-100 customInputSearchSelectItem ${
                      i === activeItem || (!activeItem && i === __items[Number(defaultIndexVal)]) ? 'active' : ''
                    }`}
                  >
                    {subItemDraw ? subItemDraw(i) : <div className="itemClassic">{i.name}</div>}
                  </div>
                );
              })}
            </PerfectScrollbar>
          </div>
        )}
      </div>
    </React.Fragment>
  );
});

export const CustomInputSearchSelect2 = forwardRef((props, ref) => {
  const { t } = useTranslation('glossary');
  const {
    items = [],
    updateDynamicItems,
    isLoading = false,
    placeHolder,
    className,
    icon,
    subItemDraw,
    filterFn,
    clickFn,
    mobileViewClickFn,
    defaultIndexVal,
    disabled,
    isLatestSearchOptions = false,
    currentItem,
    isFullScreen,
  } = props;

  const [_items, _setItems] = useState(items || []);
  const [typedText, setTypedText] = useState('');
  const debouncedSearch = useDebouncedCallback((value) => {
    updateDynamicItems(value.trim());
    setTypedText(value.trim());
  }, 500);

  const [activeItem, setActiveItem] = useState(currentItem);
  const [searchTerm, setSearchTerm] = useState('');
  const [isMobileViewOpen, setIsMobileViewOpen] = useState(undefined);

  useEffect(() => {
    if (items)
      _setItems(
        items.filter((i) => (filterFn ? filterFn(i, typedText) : i.toUpperCase().indexOf(typedText.toUpperCase()) >= 0))
      );
  }, [items, defaultIndexVal]);

  const wrapperRef = useRef(null);
  const { isListOpen, setIsListOpen } = useOutsideClick(wrapperRef);

  const handleClick = (item) => {
    if (item) {
      setActiveItem(item);
      if (isMobileViewOpen) mobileViewClickFn(item);
      else clickFn(item);
      setSearchTerm(item.name || '');
    }

    setIsListOpen(!isListOpen);
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
    debouncedSearch(event.target.value);
    setIsListOpen(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleInputChange(event);
    }
  };

  const handleFocus = () => isFullScreen && setIsMobileViewOpen(isFullScreen);

  const closeFullScreen = () => {
    setIsListOpen(false);
    setIsMobileViewOpen(false);
  };

  return (
    <SearchSelectContainer
      isMobile={isMobileViewOpen}
      className={`searchSelectContainer ${className ? className : ''}`}
    >
      <div className={`customInputSearchSelect`} ref={wrapperRef}>
        <div className={`selectInputContent`}>
          {isMobileViewOpen && (
            <Icon icon="leftarrow" aria-hidden width="15px" height="15px" onClick={() => closeFullScreen()}></Icon>
          )}
          <div className={`selectInputBox ${disabled ? 'disabled' : ''}  ${isListOpen ? 'active' : ''}`}>
            <div className="content" onClick={() => handleClick()}>
              <div className="input-group">
                {icon && <span className={`form-icon ${icon}`} />}
                <input
                  onFocus={() => handleFocus()}
                  ref={ref}
                  disabled={disabled}
                  className={`${!isListOpen ? '' : ''} ${icon ? 'form-width-icon' : ''}`}
                  type="text"
                  value={searchTerm}
                  placeholder={placeHolder ? placeHolder : `Ciudad, Zona o ${t('neighborhood')}`}
                  aria-describedby="inputGroupPrepend"
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
          </div>
        </div>
        {(isMobileViewOpen || isListOpen) && (
          <div className={isMobileViewOpen ? 'inputSelectOptionsMobile' : 'inputSelectOptions'}>
            <PerfectScrollbar className="scrollContainer">
              {isLoading && (
                <div className="w-100 customInputSearchSelectItem">
                  <div className="itemClassic">Cargando coincidencias...</div>
                </div>
              )}
              {_items.length === 0 && (
                <div className="w-100 customInputSearchSelectItem">
                  <div className="itemClassic">No se encontraron coincidencias para "{typedText}"</div>
                </div>
              )}
              <>
                {isLatestSearchOptions && <span className="list-subtitle">Búsquedas recientes</span>}
                {_items.map((i, index) => {
                  return (
                    <div
                      key={`customInputSearchSelect-${index}`}
                      onClick={() => handleClick(i)}
                      className={`w-100 customInputSearchSelectItem ${
                        i === activeItem || (!activeItem && i === _items[Number(defaultIndexVal)]) ? 'active' : ''
                      }`}
                    >
                      {subItemDraw ? (
                        subItemDraw(i)
                      ) : (
                        <>
                          <div className="itemClassic">{i.name}</div>
                          <div>{i.parents}</div>
                        </>
                      )}
                    </div>
                  );
                })}
              </>
            </PerfectScrollbar>
          </div>
        )}
      </div>
    </SearchSelectContainer>
  );
});

export const CustomInputLabel = ({ className, value, ctrlName, onClickFn }) => (
  <label onClick={onClickFn} htmlFor={ctrlName} className={`customInputLabel ${className ? className : ''}`}>
    {value}
  </label>
);

export const CustomInput = forwardRef((props, ref) => {
  const {
    labelClassName,
    label,
    disabled = false,
    className,
    childClassName,
    type,
    placeHolder,
    changeFn,
    pattern,
    value,
    defaultVal,
    stepOverValue = false,
    name,
    onKeyDown,
    min = 0,
    max,
  } = props;
  const [_value, setValue] = React.useState(defaultVal || value || '');
  const [active, setActive] = React.useState(false);
  const inputRef = ref || useRef(null);

  const labelState = active || _value !== '' ? 'label label-top' : 'label';

  React.useEffect(() => {
    setValue(value || '');
  }, [value, _value]);

  const setFocus = () => {
    inputRef.current && inputRef.current.focus();
  };

  return (
    <React.Fragment>
      {label && (
        <CustomInputLabel
          onClickFn={() => {
            setActive(true);
            setFocus();
          }}
          ctrlName={name}
          className={`${labelClassName ? labelClassName : ''} ${labelState}`}
          value={label}
        />
      )}
      <div className={`customInput ${className ? className : ''}`}>
        {stepOverValue ? (
          <input
            min={min}
            max={max}
            ref={inputRef}
            name={name}
            disabled={disabled}
            value={_value}
            type={type}
            pattern={pattern}
            className={`w-100 ${childClassName}`}
            placeholder={placeHolder}
            onChange={(e) => {
              if (changeFn) changeFn(e.target.value);
            }}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            onKeyDown={onKeyDown}
          />
        ) : (
          <input
            min={min}
            max={max}
            ref={inputRef}
            name={name}
            disabled={disabled}
            defaultValue={_value}
            type={type}
            pattern={pattern}
            className={`w-100 ${childClassName}`}
            placeholder={placeHolder}
            onChange={(e) => {
              if (changeFn) changeFn(e.target.value);
            }}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            onKeyDown={props.onKeyDown}
          />
        )}
      </div>
    </React.Fragment>
  );
});

export const CustomInputWithLabelInside = (props) => {
  const {
    labelClassName,
    label,
    disabled = false,
    className,
    childClassName,
    type,
    placeHolder,
    changeFn,
    pattern,
    value,
    defaultVal,
    stepOverValue = false,
  } = props;
  const [_value, setValue] = React.useState(defaultVal || value || '');

  React.useEffect(() => {
    setValue(value || '');
  }, [value, _value]);

  return (
    <React.Fragment>
      <div className={`customInput with-label ${className ? className : ''}`}>
        {label && <CustomInputLabel className={`${labelClassName ? labelClassName : ''}`} value={label} />}
        {stepOverValue ? (
          <input
            disabled={disabled}
            value={_value}
            type={type}
            pattern={pattern}
            className={`w-100 ${childClassName}`}
            placeholder={placeHolder}
            onChange={(e) => {
              if (changeFn) changeFn(e.target.value);
            }}
          />
        ) : (
          <input
            disabled={disabled}
            defaultValue={_value}
            type={type}
            pattern={pattern}
            className={`w-100 ${childClassName}`}
            placeholder={placeHolder}
            onChange={(e) => {
              if (changeFn) changeFn(e.target.value);
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export const CustomTextArea = (props) => {
  const {
    labelClassName,
    label,
    disabled = false,
    className,
    childClassName,
    type,
    placeHolder,
    changeFn,
    pattern,
    value,
    defaultVal,
    stepOverValue = false,
  } = props;
  const [_value, setValue] = React.useState(defaultVal || value || '');

  React.useEffect(() => {
    setValue(value || '');
  }, [value, _value]);

  return (
    <React.Fragment>
      {label && <CustomInputLabel className={`${labelClassName ? labelClassName : ''}`} value={label} />}
      <div className={`customTextArea ${className ? className : ''}`}>
        {stepOverValue ? (
          <textarea
            disabled={disabled}
            value={_value}
            type={type}
            pattern={pattern}
            className={`w-100 ${childClassName}`}
            placeholder={placeHolder}
            onChange={(e) => {
              if (changeFn) changeFn(e.target.value);
            }}
          />
        ) : (
          <textarea
            disabled={disabled}
            defaultValue={_value}
            type={type}
            pattern={pattern}
            className={`w-100 ${childClassName}`}
            placeholder={placeHolder}
            onChange={(e) => {
              if (changeFn) changeFn(e.target.value);
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export const CustomInputTel = React.forwardRef((props, ref) => {
  const { className, placeHolder, changeFn, disabled, defaultVal, icon, country, key } = props;
  const [value, setValue] = useState('');

  useEffect(() => {
    if (defaultVal != null) setValue(defaultVal);
  }, [defaultVal]);

  return (
    <div className={`customInput CustomInputTel ${icon ? 'with-icon' : ''} ${className ? className : ''}`}>
      {icon && <span className={`form-icon ${icon}`} />}
      <PhoneInput
        key={key}
        ref={ref}
        disabled={disabled}
        country={country || 'US'}
        className="w-100"
        value={value}
        placeholder={placeHolder}
        onChange={changeFn}
      />
    </div>
  );
});

export const CustomInputTelWithLabelInside = (props) => {
  const { label, labelClassName, className, placeHolder, changeFn, disabled, defaultVal, icon, country } = props;
  const [value, setValue] = React.useState(defaultVal);

  React.useEffect(() => {
    if (changeFn) changeFn(value);
  }, [value]);

  React.useEffect(() => {
    if (country != null) setValue(null);
  }, [country]);

  return (
    <div
      className={`customInput CustomInputTel with-label ${disabled ? 'disabled' : ''} ${icon ? 'with-icon' : ''} ${
        className ? className : ''
      }`}
    >
      {label && <CustomInputLabel className={`${labelClassName ? labelClassName : ''}`} value={label} />}
      {icon && <span className={`form-icon ${icon}`} />}
      <PhoneInput
        disabled={disabled}
        country={country || 'CO'}
        className="w-100"
        value={value}
        placeholder={placeHolder}
        onChange={setValue}
      />
    </div>
  );
};

export const FlagSelector = ({ className, onChange, defaultCountryCode = env.countryCode, disabled }) => {
  const countryFlagIconsExternalURL = 'https://cdn.aptuno.com/country_flags/4x3';

  const flags = [
    { img: `${countryFlagIconsExternalURL}/co.svg`, name: 'Colombia', id: 0, country: 'CO' },
    { img: `${countryFlagIconsExternalURL}/mx.svg`, name: 'Mexico', id: 1, country: 'MX' },
    { img: `${countryFlagIconsExternalURL}/ar.svg`, name: 'Argentina', id: 5, country: 'AR' },
    { img: `${countryFlagIconsExternalURL}/br.svg`, name: 'Brasil', id: 10, country: 'BR' },
    { img: `${countryFlagIconsExternalURL}/ca.svg`, name: 'Canada', id: 3, country: 'CA' },
    { img: `${countryFlagIconsExternalURL}/cl.svg`, name: 'Chile', id: 6, country: 'CL' },
    { img: `${countryFlagIconsExternalURL}/ec.svg`, name: 'Ecuador', id: 9, country: 'EC' },
    { img: `${countryFlagIconsExternalURL}/es.svg`, name: 'España', id: 4, country: 'ES' },
    { img: `${countryFlagIconsExternalURL}/it.svg`, name: 'Italia', id: 11, country: 'IT' },
    { img: `${countryFlagIconsExternalURL}/pa.svg`, name: 'Panama', id: 7, country: 'PA' },
    { img: `${countryFlagIconsExternalURL}/pe.svg`, name: 'Peru', id: 8, country: 'PE' },
    { img: `${countryFlagIconsExternalURL}/us.svg`, name: 'USA', id: 2, country: 'US' },
    { img: `${countryFlagIconsExternalURL}/ch.svg`, name: 'Suiza', id: 13, country: 'CH' },
    { img: `${countryFlagIconsExternalURL}/uy.svg`, name: 'Uruguay', id: 12, country: 'UY' },
    { img: `${countryFlagIconsExternalURL}/gb.svg`, name: 'Reino Unido', id: 14, country: 'UK' },
    { img: `${countryFlagIconsExternalURL}/fr.svg`, name: 'Francia', id: 15, country: 'FR' },
  ];

  const getIndexValueOfCountry = (countryCode) => {
    const position = flags.find((c) => c.country === countryCode)?.id || 0;

    return position.toString();
  };

  return (
    <CustomInputSelect
      disabled={disabled}
      className={`${className} font-14 lh-22 flagSelector`}
      defaultIndexVal={defaultCountryCode ? getIndexValueOfCountry(defaultCountryCode) : '0'}
      items={flags}
      clickFn={(i) => {
        if (i.country && onChange && !disabled) onChange(i.country);
      }}
      selectedDraw={(item) => (
        <div className="d-flex justify-content-between p-1 itemClassic">
          <img
            src={item.img}
            style={{ borderRadius: '5px' }}
            alt={`Icono de la bandera de ${item.name}`}
            title={`Bandera de ${item.name}`}
            width={'33px'}
            height={'22px'}
          />
        </div>
      )}
      subItemDraw={(item) => (
        <div className="d-flex justify-content-between p-1 itemClassic">
          <img
            src={item.img}
            style={{ borderRadius: '5px' }}
            alt={`Icono de la bandera de ${item.name}`}
            width={'33px'}
            height={'22px'}
            title={`Bandera de ${item.name}`}
          />
        </div>
      )}
      useCase="dropDownFlagSelectorPhone"
    />
  );
};

export const Floating = ({ children, status = '' }) => {
  return <div className={`Floating ${status}`}>{children}</div>;
};

// Error Messages
export const errorMessages = {
  required: 'es requerido',
  requiredAlt: 'es requerida',
  requiredPlural: 'son requeridos',
  maxLength: 'excede el máximo permitido',
  minLength: 'El valor no cumple con la cantidad mínima de caracteres',
  pattern: "debe tener el formato 'usuario@dominio.com'",
  patternDni: 'debe contener solo números',
  min: 'Valor mínimo',
  max: 'Valor máximo',
  validate: 'debe ser diferente al del inquilino',
};

// Error Component
export const errorMessage = (errorMsg) => {
  return <span className="invalid-feedback">{errorMsg}</span>;
};
