import React, { lazy, Suspense } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

const AgentConfirmationPromise = import('./AppointmentConfirmation/AgentConfirmation');
const AgentConfirmation = lazy(() => AgentConfirmationPromise );

const AgentsModule = props => {
  const { path } = useRouteMatch();

  return (
    <>
     <Route exact path={`${path}/`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <AgentConfirmation/>
        </Suspense>
      </Route>
    </>
  )
}

export default AgentsModule
