import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import history from '../../history';
import { GlobalStyle } from '../../components/commons/Layout.styled';
import NavbarSimple from '../../components/NavbarAptuno/NavbarSimple';
import Seo from '../../components/Seo';
import { userTypes } from '../../data/miscellaneous';
import { useUtmInfo } from '../../hooks/useUtmInfo';
import { getSeoForPage } from '../../services/seo';
import { PAGE_CATEGORY } from '../../services/url';
import { Container, Item } from './Login.styled';
import LoginBucket from './LoginBucket/LoginBucket';
import { CookiesBox } from '../../components/CookiesBox/CookiesBox';
import { useAnalyticsTracker } from '../../services/analytics';
import { ReferralCodeUsed } from '../../Context/LandlordDashboard/referral-code-used.event';
import { Tab, Tabs } from 'react-bootstrap';
import { EmailLoginForm } from './email/EmailLoginForm';

type TabbedLoginProps = {
  userType: string;
  handleOnValidated: () => void;
};

function TabbedLogin(props: TabbedLoginProps) {
  return (
    <Tabs id="login-tabs" defaultActiveKey="phone-login-tab" className="mb-3">
      <Tab eventKey="phone-login-tab" title="Teléfono">
        <LoginBucket type={props.userType} onValidated={props.handleOnValidated} />
      </Tab>
      <Tab eventKey="email-login-tab" title="Correo">
        <EmailLoginForm />
      </Tab>
    </Tabs>
  );
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

type LoginProps = {};

const Login = (props: LoginProps) => {
  const eventTracker = useAnalyticsTracker();
  const { t } = useTranslation();
  const queryParams = useQuery();
  const [seo] = React.useState(() => getSeoForPage(t, PAGE_CATEGORY.HOME));

  const queryParamsRole = queryParams.get('rol');
  const userType = React.useMemo(() => {
    if (queryParamsRole !== null) {
      return queryParamsRole;
    }
    return userTypes.tenant;
  }, [queryParamsRole]);

  const originWix = queryParams.get('origin') === 'growth';

  const referringUserId = queryParams.get('propietario') || queryParams.get('agente');
  const referringLandlordId = queryParams.has('propietario') && 'propietario';
  const referringAgentId = queryParams.has('agente') && 'agente';

  const handleOnValidated = () => {
    console.log('Session validated');
    const goToAutopublish = document.referrer.includes('publicar-inmueble');

    if (queryParams.has('referrer')) {
      //used for redirect to ssr project
      // history.replace(queryParams.get('referrer'));
      window.location.href = queryParams.get('referrer') as string;
    } else if (goToAutopublish && referringUserId) {
      const referralType = referringLandlordId ? 'BY_LANDLORD' : 'BY_CAPTURING_AGENT';

      eventTracker
        .trackEvent(new ReferralCodeUsed(parseInt(referringUserId), referralType))
        .then(() =>
          history.push(
            `/publicar-inmueble/tipo-publicacion?${referringLandlordId || referringAgentId}=${referringUserId}`
          )
        );
    } else if (goToAutopublish || originWix) {
      history.push('/publicar-inmueble');
    } else {
      history.push('/');
    }
  };

  useUtmInfo();

  return (
    <>
      <GlobalStyle />
      <CookiesBox urlLink={'/politica-de-privacidad'} bgColor="#F8F8F8" textColor="#000000" />

      <div className="Login">
        <Seo
          title={seo.title}
          description={seo.description}
          image={seo.image}
          url={seo.canonical || window.location.href}
        />
        <header>
          <NavbarSimple />
          <Container className="push-top">
            <Item>
              <TabbedLogin userType={userType} handleOnValidated={handleOnValidated} />
            </Item>
          </Container>
        </header>
      </div>
    </>
  );
};

export default Login;
