import styled from 'styled-components';

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .footer p {
    text-align: center;
    margin-top: 30px;
    border-top: 1px solid #dee2e6;
    padding-top: 30px;
  }
`;

const LoginFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & p {
    text-align: center;
  }
`;

const FlagSelectorCol = styled.div`
  max-width: 80px;
  display: flex;
`;

const SmallText = styled.div`
  font-size: 0.75rem;
  margin-bottom: 32px;
`;

const InputRow = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 16px;

  .customInputSelect,
  .flagSelector,
  .customInput,
  .customInput input {
    height: 48px !important;
  }

  & .CustomInputTel {
    width: 100%;
    margin-left: 16px;
  }

  & .customButton {
    width: 100%;

    button {
      border-radius: 8px;
    }
  }

  .inputValue,
  .itemClassic {
    top: 0 !important;
    padding: 0 !important;
  }
`;

const ItemPhone = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-bottom: 32px;
  justify-content: space-between;
`;

export { InputRow, LoginFooter, FlagSelectorCol, LoginWrapper, SmallText, ItemPhone };
