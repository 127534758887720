import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 56px;
  margin: auto;
  max-width: 688px;
  position: relative;
  margin-bottom: -1px !important;

  @media screen and (min-width: 800px) {
    margin: auto;
  }
  @media screen and (min-width: 1000px) {
    max-width: 1031px;
  }
`;

const BackButton = styled.img`
  margin: 22px 10px 22px 18px;
  height: 12px;
  width: 12px;
  transition: 0.3s all ease;
  cursor: pointer;

  @media screen and (min-width: 800px) {
    margin: 19px 11px 19px 19px;
    height: 18px;
    width: 18px;
  }
`;

const Tittle = styled.div`
  height: 20px;
  font-family: Satoshi;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  left: 40px;
  transition: 0.3s all ease;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 46px;

  @media screen and (min-width: 800px) {
    font-size: 16px;
    width: auto;
  }
`;

const CloseButton = styled.img`
  height: 18px;
  width: 18px;
  float: right;
  position: absolute;
  right: 24px;
  cursor: pointer;
`;

export { Container, BackButton, Tittle, CloseButton };
