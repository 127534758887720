export const ESTATE_STATUS = {
  REGISTERED: 'REGISTERED',
  CONSIGNED: 'CONSIGNED',
  PUBLISHED: 'PUBLISHED',
  RESERVED: 'RESERVED',
  LEASED: 'LEASED',
  PAUSED: 'PAUSED',
  BANNED: 'BANNED',
};

export const COUNTRY_CODE = {
  COLOMBIA: 'CO',
  MEXICO: 'MX',
};
