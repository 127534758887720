import { env } from '../environment/environment';

export const documentTypes = env.documentTypes;

export const userTypes = {
  landlord: 'LANDLORD',
  tenant: 'TENANT',
  visitCompanionAgent: 'VISIT_COMPANION_AGENT',
  capturingAgent: 'CAPTURING_AGENT',
};

export const authChannels = {
  whatsapp: 'WHATSAPP',
  sms: 'SMS',
};

export const estimatedMoveDates = [
  { name: 'Lo antes posible', value: 'AS_SOON_AS_POSSIBLE' },
  { name: 'En los próximos 7 días', value: 'NEXT_SEVEN_DAYS' },
  { name: 'En el próximo mes', value: 'NEXT_MONTH' },
  { name: 'Todavía no lo sé', value: 'DONT_KNOW' },
];

export const cellPhoneCodeOptions = [
  {
    name: 'Colombia',
    value: '+57',
  },
  {
    name: 'México',
    value: '+52',
  },
];

export const documentTypesOptions = [
  {
    name: 'CC',
    value: 'CC',
  },
  {
    name: 'PA',
    value: 'PA',
  },
  {
    name: 'CE',
    value: 'CE',
  },
  {
    name: 'NIT',
    value: 'NIT',
  },
  {
    name: 'CPEP',
    value: 'CPEP',
  },
];
