import styled from 'styled-components';

const ModalHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 15px;
  margin: 2px;
  z-index: 1;

  & > svg {
    margin: 3px;
    color: #6c757d;
    cursor: pointer;
  }
`;

const ModalTitle = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: auto;
  max-height: calc(100vh - 92px);
  width: calc(100% + 32px);
  margin-left: -16px;
  padding: 10px;
  padding-bottom: 32px;
`;

export { ModalTitle, ModalHeader, ModalContent };
