import * as React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useFirebase } from '../../FirebaseProvider';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { env } from '../../../environment/environment';
import { MainTitle, SubTitle } from '../Login.styles';
import { AptunoButton, Icon, SIZES, VARIANTS } from '@aptuno/aptuno-ui';
import { Form, Container, Row } from 'react-bootstrap';

enum AuthError {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  UNKNOWN = 'UNKNOWN',
}

function useFirebaseAuthentication() {
  const { auth } = useFirebase();
  const [isLoading, setLoading] = React.useState(false);
  const [executed, setExecuted] = React.useState(false);
  const [error, setError] = React.useState<AuthError | null>(null);

  function executeOperation(emailAddress: string) {
    const protocol = env.dev ? 'http' : 'https';
    const url = `${protocol}://${env.urlAptuno}/email-login`;

    setExecuted(true);
    setLoading(true);

    sendSignInLinkToEmail(auth, emailAddress, {
      url,
      handleCodeInApp: true,
    })
      .then(() => {
        window.localStorage.setItem('emailForSignIn', emailAddress);
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        if (error.code === 'auth/admin-restricted-operation') {
          setError(AuthError.USER_NOT_FOUND);
        } else {
          setError(AuthError.UNKNOWN);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return { isLoading, executeOperation, error, executed };
}

type Inputs = {
  email: string;
};

export function EmailLoginForm() {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<Inputs>({});
  const { executed, isLoading, error, executeOperation } = useFirebaseAuthentication();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    return executeOperation(data.email);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <MainTitle>Acceso para inquilinos y propietarios</MainTitle>
          <SubTitle>
            ¿Tienes un contrato con nosotros? Ingresa tu correo electrónico y te enviaremos un enlace seguro para
            acceder a tu cuenta.
          </SubTitle>
        </Row>

        <Row
          style={{
            marginBottom: '1rem',
          }}
        >
          <Form.Control
            type="email"
            id="email"
            name="email"
            placeholder="Correo electrónico"
            isInvalid={!!errors.email}
            ref={register({
              required: 'Por favor, ingresa tu correo electrónico',
            })}
          />
          {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
        </Row>

        <Row
          style={{
            marginBottom: '1rem',
          }}
        >
          <AptunoButton type="submit" appearance={VARIANTS.PRIMARY} size={SIZES.BIG} disabled={isLoading}>
            <Icon icon="email" />
            Enviar enlace
          </AptunoButton>
        </Row>

        {error === AuthError.USER_NOT_FOUND && (
          <Row>
            <Form.Text className="text-danger">
              No encontramos una cuenta asociada a este correo electrónico. Por favor, verifica que el correo sea
              correcto o ingresa con tu número de teléfono y crea un caso a soporte.
            </Form.Text>
          </Row>
        )}
        {error === AuthError.UNKNOWN && (
          <Row>
            <Form.Text className="text-danger">Ocurrió un error inesperado. Por favor, intenta de nuevo.</Form.Text>
          </Row>
        )}

        {!isLoading && executed && error === null && (
          <Row>
            <Form.Text>Revisa tu correo electrónico para acceder a tu cuenta.</Form.Text>
          </Row>
        )}
      </form>
    </Container>
  );
}
