import * as React from 'react';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
});
const auth = getAuth(app);

type FirebaseContextType = {
  app: FirebaseApp;
  auth: Auth;
};

export const FirebaseContext = React.createContext<FirebaseContextType>({
  app,
  auth,
});

const FirebaseProvider: React.FC = ({ children }) => {
  return <FirebaseContext.Provider value={{ app, auth }}>{children}</FirebaseContext.Provider>;
};

const useFirebase = () => {
  return React.useContext(FirebaseContext);
};

export { FirebaseProvider, useFirebase };
