import { TrackingEvent, TraceableProcess } from '../../../services/analytics/model';

export class ExclusivitySelected implements TrackingEvent {
  public readonly action = 'Exclusivity Selected';
  public readonly category = TraceableProcess.AUTOPUBLISH;
  public readonly label = `exclusivity_selected = ${this.exclusivity_selected}`;

  constructor(
    public readonly exclusivity_selected: boolean,
    public readonly byAgent: boolean) {}
}
