import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMachine } from '@xstate/react';
import { useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client/react/hooks';

import { useOtpInput } from './input';
import { useUtmTrackingData } from '../../hooks/useUtmInfo';
import { createOtpMachine, OtpData } from './machine';
import { TrackingParent, UserData, ValidateOtpSuccess } from './types';
import { formatErrorMessage, formatOtpValidationResult } from './utils';

import { getOrCreateUUID } from '../../helpers/localStorage';
import { DO_PHONE_CODE_SIGNUP } from '../../hooks/useAuthQuery';
import { OtpInputDescription, OtpInputTitle } from './otp-input.styled';
import { useAnalyticsTracker } from '../../services/analytics';
import { reportOtpTrackingData } from './analytics';
import { useUpdateSessionToken } from '../../Context/Auth/SessionProvider';

interface OtpInputValidatorProps {
  phoneNumber: string;
  country: string;
  userType: string;
  tracking: TrackingParent;
  externalLoadingInProgress?: boolean;
  otpLength: number;
  onRetry: (count: number) => void;
  onValidate: (p: any) => void;
  className?: string;
  address?: string;
  area?: number;
  roomsNr?: number;
  bathRoomsNr?: number;
  stratum?: number;
  parkingLotOptions?: number;
  furnished?: boolean;
  city?: string;
  suggestedPrice?: string;
  leadOrigin?: string;
  latitude?: string;
  longitude?: string;
}

function OtpInput(props: OtpInputValidatorProps) {
  const location = useLocation();
  const client = useApolloClient();
  const eventTracker = useAnalyticsTracker();
  const trackingData = useUtmTrackingData();
  const updateSessionToken = useUpdateSessionToken();

  const dispatchUserData = ({ token }: Omit<ValidateOtpSuccess, '_tag'>) => {
    if (typeof updateSessionToken === 'function') {
      updateSessionToken({
        kind: 'SET',
        payload: token,
      });
    }
  };

  const anonymousId = React.useMemo(() => getOrCreateUUID(), []);

  const validateOtp = (otpData: OtpData) => {
    const { otpCode, anonymousId, userType } = otpData;

    return client
      .mutate({
        mutation: DO_PHONE_CODE_SIGNUP,
        variables: {
          number: props.phoneNumber,
          code: otpCode,
          userType,
          anonymousSessionId: anonymousId,
        },
      })
      .then((result) =>
        formatOtpValidationResult({
          result,
          formatError: (errorResponse) =>
            formatErrorMessage({
              location: location.search,
              errorResponse,
            }),
          formatResult: (response) => response.data.signUpPhoneCode,
          defaultErrorMessage: 'Error desconocido',
        })
      );
  };

  const machine = React.useMemo(
    () =>
      createOtpMachine({
        otpLength: props.otpLength,
        anonymousId,
        country: props.country,
        phoneNumber: props.phoneNumber,
        userType: props.userType,

        trackingInformation: {
          parent: props.tracking,
          item: trackingData,
        },
        validateOtp,
        resendOtp: (errorCount) => {
          props.onRetry(errorCount);
          return Promise.resolve();
        },
        reportAnalytics: (analyticProps) => reportOtpTrackingData(analyticProps, eventTracker),
      }),
    []
  );

  const [state, send] = useMachine(machine, {
    actions: {
      onValidateSuccess: (ctx, event) => {
        if (event.type !== 'done.invoke.validateOtp') return;

        const { userData, token } = event.data;

        const completeUserData: UserData = {
          ...userData,
          country: props.country,
        };

        dispatchUserData({
          userData,
          token,
        });

        props.onValidate(completeUserData);
      },
    },
  });

  const isLoggedOut = state.matches('loggedOut');
  const isValidating = state.matches('validating');
  const isResending = state.matches('resending');
  const hasError = state.matches('error');
  const isLoggedIn = state.matches('loggedIn');

  const handleOtpChange = (value: string) => {
    const hasValidState = isLoggedOut || hasError;
    if (hasValidState) {
      send('STORE', {
        data: {
          otpCode: value,
        },
      });
    }
  };

  const otpInput = useOtpInput({
    length: props.otpLength,
    onChangeOTP: handleOtpChange,
    className: 'customInput small-input',
    inputClassName: 'w-100 noIncrement',
    disabled: isValidating,
    autoFocus: true,
    isNumberInput: true,
  });

  const resendOtpCode = () => {
    const hasValidState = isLoggedOut;
    if (hasValidState) {
      send('RESEND');
      otpInput.clear();
    }
  };

  const handleRetry = () => {
    otpInput.clear();
  };

  return (
    <Row className={`otpBucket state-1-2 ${props.className}`}>
      <Col xs="12">
        <OtpInputTitle>Te enviamos un código.</OtpInputTitle>
        <OtpInputDescription>Ingresa el código de 6 dígitos que enviamos a tu cuenta de WhatsApp.</OtpInputDescription>
      </Col>
      <Col xs="12" className="codeInputField">
        {otpInput.inputComponent}
      </Col>
      {isLoggedOut && (
        <Col xs="12" className="footer">
          <p className="text-center my-2 lh-21 f-14">
            <b>
              <span onClick={() => resendOtpCode()} className="cursor-pointer c-primary2">
                No recibí ningún código
              </span>
            </b>
          </p>
        </Col>
      )}
      {isResending ||
        (props.externalLoadingInProgress && (
          <Col xs="12" className="footer">
            <p className="text-center my-2lh-21 f-14 c-primary2">
              <b>
                Enviando nuevo código...
                <span className="loader icon-validando_loader">
                  <span className="icon-validando_loader" />
                </span>
              </b>
            </p>
          </Col>
        ))}
      {isValidating && (
        <Col xs="12" className="footer">
          <p className="text-center my-2 lh-21 f-14 c-primary2">
            <b>
              Validando ...
              <span className="loader icon-validando_loader">
                <span className="icon-validando_loader" />
              </span>
            </b>
          </p>
        </Col>
      )}
      {isLoggedIn && (
        <Col xs="12" className="footer">
          <p className="text-center my-2 lh-21 f-14 c-green">
            <b>
              Bienvenido
              <span className="pl-1 success icon-succes_bg_icon ">
                <span className="path1" />
                <span className="path2" />
              </span>
            </b>
          </p>
        </Col>
      )}
      {hasError && (
        <Col xs="12" className="footer">
          <p className="text-center my-2 lh-21 f-14">
            <b>
              {state.context.errorMessage},{' '}
              <span onClick={() => handleRetry()} className="cursor-pointer c-primary2">
                volver a intentar
              </span>
            </b>
          </p>
        </Col>
      )}
    </Row>
  );
}

export { OtpInput as default, OtpInput };
