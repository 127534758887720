import React from 'react';
import { env } from '../../environment/environment';
import history from '../../history';
import Lottie from 'react-lottie';
import error404 from '../../lotties/error404';
import { Container, Title, Description, LinkRecommendation, Button, Animation } from './ErrorPage.styled';

const goToHomePage = () => history.push('/');
const OpenLink = destiny => (window.location.href = destiny);

const LINKS = env.linkRelevantsError404;
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: error404,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
    className: 'lotties'
  },
};

const ErrorPage = () => {
  return (
    <Container>
      <Animation>
        <Lottie options={defaultOptions} height={324} width={324} speed={0.86} />
      </Animation>
      <Title>No encontramos esa página...</Title>
      <Description>Sin embargo, te invitamos a revisar otras opciones que pueden ser útiles:</Description>
      <LinkRecommendation>
        {LINKS.map((element, index) => {
          return <li key={`link_error_page_${index}`} onClick={() => OpenLink(element.href)}>{element.title}</li>;
        })}
      </LinkRecommendation>
      <Button onClick={goToHomePage}>Volver a la página principal</Button>
    </Container>
  );
};

export default ErrorPage;
