export type EstateAddressDetails = EstateAddressDetails.Apartment | EstateAddressDetails.House;

export namespace EstateAddressDetails {
  export enum Kind {
    APARTMENT = 'APARTMENT',
    HOUSE = 'HOUSE',
  }

  type ApartmentArgs = {
    tower: string;
    internal: string;
  };

  export class House {
    public readonly kind = Kind.HOUSE;

    constructor(public readonly desc: string) {}

    toString = (): string => `Casa - ${this.desc}`;

    static of = (desc: string): House => new House(desc);
  }

  export class Apartment {
    public readonly kind = Kind.APARTMENT;

    constructor(public readonly tower: string, public readonly internal: string) {}

    toString = (): string => `TO ${this.tower} - Apto ${this.internal}`;

    static of = ({ tower, internal }: ApartmentArgs): Apartment => new Apartment(tower, internal);
  }

  export const LocalStore = {
    asLocallyStorable: (value: EstateAddressDetails): string =>
      isApartment(value) ? `${value.tower}${token}${value.internal}` : value.desc,

    fromLocallyStored: (value: string): EstateAddressDetails => {
      const [tower, internal] = value.split(token);

      if (tower && internal) {
        return Apartment.of({ tower, internal });
      }

      return House.of(value);
    },
  };

  export const isApartment = (value: EstateAddressDetails): value is Apartment => value.kind === Kind.APARTMENT;

  const token = '#APTO#';
}
