const getGraphQLErrorCode = (error) => {
  if (error?.networkError) return 'NETWORK_ERROR';
  const domainError =
    error?.graphQLErrors[0]?.extensions?.exception?.errors[0]?.code || error[0]?.extensions?.exception?.errors[0]?.code;
  return domainError || 'DEFAULT';
};

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

const mapToUserFront = (user) => {
  return {
    ...user,
    firstName: getName(user.firstName),
    lastName: getName(user.lastName),
  };
};

const getName = (name) => (name === 'Unknown' ? '' : name);

export { getGraphQLErrorCode, parseJwt, mapToUserFront };
