import { TraceableProcess, TrackingEvent } from '../../../services/analytics/model';

export class OTPRequestedEvent implements TrackingEvent {
  public readonly action = 'OTP Requested';
  public readonly category = TraceableProcess.USER_PROFILE;
  public readonly label: string;

  constructor(
    public readonly source: string,
    public readonly country: string,
    public readonly intent: number,
    public readonly redirect_phone: string
  ) {
    this.label = `country = ${country}, source = Scheduling, intent = ${intent}, redirect_phone = ${redirect_phone}`;
  }
}
