import React from 'react';
import {
  ProcessContainer,
  IconContainer,
  BarInfoWrapper,
  TextInfoWrapper,
  Title,
  Text,
} from './ProcessInfoBar.styled';
import { Icon, Stepper4040 } from '@aptuno/aptuno-ui';

const BarInfo = ({ icon, iconColor, backgroundColor, title, description }) => {
  return (
    <BarInfoWrapper>
      <IconContainer color={iconColor} backgroundColor={backgroundColor}>
        <Icon icon={icon} />
      </IconContainer>
      <TextInfoWrapper>
        <Title>{title}</Title>
        <Text>{description}</Text>
      </TextInfoWrapper>
    </BarInfoWrapper>
  );
};

const ProcessInfoBar = ({
  steps,
  currentStep,
  icon,
  iconColor,
  backgroundColor,
  title,
  description,
}) => {
  return (
    <ProcessContainer>
      {steps ? (
        <Stepper4040 steps={steps} activeStep={currentStep} />
      ) : (
        <BarInfo
          icon={icon}
          iconColor={iconColor}
          backgroundColor={backgroundColor}
          title={title}
          description={description}
        />
      )}
    </ProcessContainer>
  );
};

export default ProcessInfoBar;
