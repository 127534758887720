import styled, { css, keyframes } from 'styled-components';

const animationStart = keyframes`
 0% { top: 50%; padding-left: 3%; padding-right: 3%; opacity: 0; }
 100% { top: 0; width: 100%; opacity: 1;}
 `;

const hoverStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden !important;
  z-index: 102;
  background-color: #fff;
  padding: 16px;
`;

const hoverBehavior = css`
  ${hoverStyle}
  animation-name: ${animationStart};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
`;

const animationStop = keyframes`
0% { ${hoverStyle} top: 0; width: 100%; }
100% { top: 100%; width: 100%; }
`;
const hoverOutBehavior = css`
  animation-name: ${animationStop};
  animation-duration: 1s;
  animation-iteration-count: 1;
`;

const SearchSelectContainer = styled.div`
  ${(props) => (props.isMobile === undefined ? null : props.isMobile ? hoverBehavior : hoverOutBehavior)}
`;

export { SearchSelectContainer };
